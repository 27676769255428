import * as actionTypes from './actionTypes';
import axios from '../../axios-instance';

export const submitData = (newData, token) => {
	return async (dispatch) => {
		console.log(newData);
		await axios
			.post('/costValue.json?auth=' + token, newData)
			.then((response) => {
				dispatch(submitDataSuccess(response.data.name, newData));
			})
			.catch((error) => {
				dispatch(submitDataFail(error));
			});
	};
};
export const updateData = (costId, newData, token) => {
	return async (dispatch) => {
		await axios
			.put(`/costValue/${costId}.json?auth=` + token, newData)
			.then((response) => {
				dispatch(updateDataSuccess(costId, newData));
			})
			.catch((error) => {
				dispatch(updateDataFail(error));
			});
	};
};

export const deleteData = (costId, token) => {
	return async (dispatch) => {
		await axios
			.delete(`/costValue/${costId}.json?auth=` + token)
			.then((response) => {
				dispatch(deleteDataSuccess(costId));
			})
			.catch((error) => {
				dispatch(deleteDataFail(error));
			});
	};
};

export const deleteDataSuccess = (id) => {
	return {
		type: actionTypes.DELETE_DATA_SUCCESS,
		costId: id,
	};
};

export const deleteDataFail = (error) => {
	return {
		type: actionTypes.DELETE_DATA_FAIL,
		error: error,
	};
};

export const updateDataSuccess = (id, costData) => {
	return {
		type: actionTypes.UPDATE_DATA_SUCCESS,
		costId: id,
		costData: costData,
	};
};

export const updateDataFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error,
	};
};

export const submitDataSuccess = (id, costData) => {
	return {
		type: actionTypes.SUBMIT_DATA_SUCCESS,
		costId: id,
		costData: costData,
	};
};

export const submitDataFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error,
	};
};

export const startSubmitData = () => {
	return {
		type: actionTypes.START_SUBMIT_DATA,
	};
};

export const fetchDataSuccess = (data) => {
	return {
		type: actionTypes.FETCH_DATA_SUCCESS,
		data: data,
	};
};

export const fetchData = (token, userId) => {
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/costValue.json' + queryParams);
		const fetchedData = [];

		let variableCost = 0;
		for (let key in response.data) {
			variableCost += Number(response.data[key].dataValues['costPerOne']);
			fetchedData.push({
				...response.data[key],
				variableCost: variableCost * 1,
				id: key,
			});
		}
		console.log(fetchedData);

		dispatch(fetchDataSuccess(fetchedData));
	};
};
