
import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-instance';


export const ADD_PS_INFOMATION = (mainProductSeriveName, description, primarily, newOrComing, newOrComingName, newPrimarily) => {

    return{
        type: 'ADD_PS_INFORMATION',
		mainProductSeriveName: mainProductSeriveName,
        description: description,
        primarily: primarily,
		newOrComing: newOrComing,
        newOrComingName: newOrComingName,
		newPrimarily: newPrimarily,

    };
};
   


export const submitPS = (newData, token) => {
	return async (dispatch) => {
		await axios.post('/proServValue.json?auth=' + token, newData)
			.then(response => {
				dispatch(submitPSSuccess(response.data.name, newData))
			})
			.catch(error => {
				dispatch(submitPSFail(error))
			})
	};
};

export const submitPSSuccess = (id, psData) => {
	return {
		type: actionTypes.SUBMIT_DATA_SUCCESS,
		geoId: id,
		psData: psData
	};
};

export const submitPSFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error
	}
}