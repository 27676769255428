import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../assets/images/logo.png";  // nDexio logo
import "./Header.css";
import { withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

class Header extends Component {
  render() {
    // console.log(this.props);
    return (
      <Navbar bg="light" variant="light" sticky="top"> {/* Navbar sticks to top. Not fully supported in all browsers*/}
        <Link className="navbar-brand" to="/">
          <img alt="logo" className="logo" src={logo} />
        </Link>
        <div className="searching">
          <input
            className="text-input"
            type="search"
            placeholder="Search"
          ></input>
        </div>
        {this.props.token ? (
          <Link to="/logout">Logout</Link>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    token: state.auth.token,
  };
};

export default withRouter(connect(mapStateToProps)(Header));
