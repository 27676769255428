import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import UnmeetNeed from "./unmetNeed";

class PrimaryMarketNeed extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchPrimaryMarket(this.props.token, this.props.userId);
  }
  state = {
    show: true,
    primaryMarketData: null,
    primaryMarketId: "",
    markets: [],
    fetchDone: false,
    showNeedTable: false,
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    this.setState({
      primaryMarketData: nextProps.primaryMarketData,
      primaryMarketId: nextProps.primaryMarketId,
      fetchDone: true,
    });
  }

  handleClose = () => {
    this.props.history.push("/");
  };

  render() {
    // console.log(this.state)
    if (!this.state.fetchDone) {
      return (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      );
    } else {
      if (this.state.show) {
        return (
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header>
              Taking into account all Competitors in this market, but EXCLUDING
              yourself, to what Degree are the following needs met by their
              current offer in the {this.state.primaryMarketData.marketName}{" "}
              market? (enter a % for each Need)
            </Modal.Header>
            <Modal.Body>
              <div>
                - Please enter 100% if there is no unmet need from customers
                (i.e. . Competitors already have offers that fully meet the
                current need
              </div>
              <div>
                - Enter 0% if no product or service currently caters for this
                need
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  this.setState({ show: false, showNeedTable: true })
                }
              >
                Next
              </Button>
            </Modal.Footer>
          </Modal>
        );
      } else if (this.state.showNeedTable) {
        return (
          <UnmeetNeed
            primaryMarketData={this.state.primaryMarketData}
            primaryMarketId={this.state.primaryMarketId}
          />
        );
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    marketData: state.market.marketData,
    isAuthenticated: state.auth.token !== null,
    primaryMarketId: state.market.primaryMarket,
    primaryMarketData: state.market.primaryMarketData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) =>
      dispatch(actions.fetchMarket(token, userId)),
    onFetchPrimaryMarket: (token, userId) => {
      dispatch(actions.fetchPrimaryMarket(token, userId));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrimaryMarketNeed)
);
