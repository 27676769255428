// Created by: Quang Nguyen
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

// REDUX
import { Provider } from 'react-redux';
import store from './store/store';
import { SET_AUTHENTICATED } from './store/actions/actionTypes';
import { logoutUser, getUserData } from './store/actions/userActions';
import { authCheckState } from './store/actions/auth';

// COMPONENTS

import Main from './container/Main/Main';
import Auth from './container/auth/Auth';
import AboutMe from './container/aboutMe/AboutMe';
import Logout from './container/auth/Logout/Logout';
import Priority from './components/Priority/Priority';
import Cost from './components/Cost/Cost';
import Geo from './container/Geographies/Geo';
import productServiceInfo from './container/productServiceInfomation/productServiceInfo';
import customer from './container/Customers/customers';
import Modal from './components/Priority/ConfirmationModal/Modal';
import CompanyProfile from './components/CompanyProfile/Profile';
import Market from './components/Market/Market';
import PrimaryCompetitor from './components/Competition/PrimaryCompetitor';
import baseUnit from './components/baseUnit/baseUnit';
import customerNeeds from './container/Customers/customerNeeds';
import benefits from './container/Customers/benefits';
import UnmetNeed from './components/UnmetNeed/introduction';
import Competition from './components/Competition/Competition';
import features from './container/Customers/Features/features';
import featuresPower from './container/Customers/Features/featuresPricingPower';
import featuresVersion from './container/Customers/Features/featuresVersions';
import priceSensitivity from './components/PriceSensitivity/Introduction';
import needsBasednDex from './components/nDex/needs-basednDex';
import valueBasednDex from './components/nDex/valuenDex/value-basednDex';
import valueCustomerBasednDex from './components/nDex/valuenDex/valueCustomer-basednDex';
import valueVersionBasednDex from './components/nDex/valuenDex/valueVersion-basednDex';

import setupUserInformation from './components/UserInformation/SetupForm';
// Auth components
import AuthRoute from './util/AuthRoute';

// Pages
import login from './pages/login';
import signup from './pages/signup';

import './App.css';
import axios from './axios-instance-api';
import needsBasedPeg from './components/nDex/needs-basednDex';
import featuresVersions from './container/Customers/Features/featuresVersions';
axios.defaults.baseURL =
  'https://us-central1-ndexio-mvp-alpha.cloudfunctions.net/api';

// axios.defaults.baseURL =
// 	'http://localhost:5000/ndexvp-alpha/us-central1/api';io-m
// check for token inside local storage
const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  if (decodedToken.exp * 1000 < Date.now()) {
    store.dispatch(logoutUser());
    window.location.href = '/login';
  } else {
    store.dispatch({ type: SET_AUTHENTICATED });
    axios.defaults.headers.common['Authorization'] = token;
    store.dispatch(getUserData());
  }
}

class App extends Component {
	constructor() {
		super();
		store.dispatch(authCheckState());
	}
	render() {
		let routes = (
			<Switch>
				{/* AUTH ROUTES */}
				<AuthRoute exact path='/login' component={login} />
				<AuthRoute exact path='/signup' component={signup} />
				{/* <Route path='/' exact component={Main} /> */}
				<Route exact path='/newaboutme' component={setupUserInformation}/>

				<Route path='/aboutme' component={AboutMe} />
				<Route path='/modal' component={Modal} />
				<Route path='/auth' component={Auth} />
				<Route path='/priority' component={Priority} />
				<Route path='/Geo' component={Geo} />
				<Route path='/software' component={Cost} />
				<Route path='/cost' component={Cost} />
				<Route path='/companyprofile' component={CompanyProfile} />
				<Route path='/market' component={Market} />
				<Route path='/competition' component={Competition} />
				<Route path='/unmetNeed' component={UnmetNeed} />
				<Route path='/priceSensitivity' component={priceSensitivity} />
				<Route path='/' exact component={AboutMe} />
				<Route path="/needsBasednDex" component={needsBasednDex}/>
				<Route path="/valueBasednDex" component={valueBasednDex}/>
				<Route path="/valueCustomerBasednDex" component={valueCustomerBasednDex}/>
				<Redirect to='/auth' />
			</Switch>
		);
		// console.log("Route",routes);
		if (store.getState().auth.token) {
			routes = (
				<Switch>
					<AuthRoute exact path='/login' component={login} />
					<AuthRoute exact path='/signup' component={signup} />
					<Route exact path='/newaboutme' component={setupUserInformation}/>
					<Route path='/priority' component={Priority} />
					<Route path='/modal' component={Modal} />
					<Route path='/market' component={Market} />
					<Route path='/logout' component={Logout} />
					<Route path='/Geo' component={Geo} />
					<Route path='/productOrService' component={productServiceInfo} />
					<Route path='/customer' component={customer} />
					<Route path='/cost' component={Cost} />
					<Route path='/companyprofile' component={CompanyProfile} />
					<Route path='/baseUnit' component={baseUnit} />
					<Route path='/customerNeeds' component={customerNeeds} />
					<Route path='/benefits' component={benefits} />
					<Route
						path='/competitorInfo'
						component={PrimaryCompetitor}
					/>
					<Route path='/competition' component={Competition} />
					<Route path='/unmetNeed' component={UnmetNeed} />
					<Route path='/priceSensitivity' component={priceSensitivity} />
					<Route path='/features' component={features}/>
					<Route path='/featuresPower' component={featuresPower}/>
					<Route path='/featuresVersion' component={featuresVersion}/>
					<Route path="/needsBasednDex" component={needsBasednDex}/>
					<Route path="/valueBasednDex" component={valueBasednDex}/>
					<Route path="/valueCustomerBasednDex" component={valueCustomerBasednDex}/>
					<Route path="/valueVersionBasednDex" component={valueVersionBasednDex}/>
					<Route path='/' exact component={Main} />
					<Redirect to='/' />
				</Switch>
			);
		}
		// console.log("Route2",routes);
		return (
			<Provider store={store}>
				<div className='App'>{routes}</div>
			</Provider>
		);
	}
}

export default withRouter(App);
