import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Table from "react-bootstrap/Table";
import Container from "../../../hoc/container";
import Button from "react-bootstrap/Button";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
//import "./CompetitionTable.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import ScatterChart from "../../../components/Charts/scatterChart";
import { Tooltip } from '@material-ui/core';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { Form, FormControl,  ButtonGroup, Row, Col , Container as Container2} from 'react-bootstrap';
import 'react-widgets/dist/css/react-widgets.css'; 
import "./toggleCSS.css"
import { Scatter } from 'react-chartjs-2'
import store from '../../../store/store';



class featuresPower extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchCompetitor(this.props.token, this.props.userId);
    
    this.props.onFetchFeature(this.props.token, this.props.userId);
    
    this.props.onFetchCustomer(this.props.token, this.props.userId);
    console.log(store.getState().features);
  }
  state = {
    data: [],
    fetchDone: false,
    showHelpModal: false,
    showAddModal: false,
    showDeleteMessage: false,
    showEditModal: false,
    showEditFirstModal: false,
    showEditTwoModal: false,
    showEditThreeModal: false,
    showExplanationModal: false,
    showVersions: true,
    FeaturesList: [],
    pointList: [],
    customerSegList: [],
    competitorName: "",
    selectingCompetitor: null,
    selectingFeature: null,
    selected: this.props.primaryCompetitor,
    newServesTheBenefit: "",
    newFeatureName: "",
    newShortDescription: "",
    showDeleteFeature: false,
    newPercivedValue: "",
    newUSP: "",
    newVersionOne: "",
    newVersionTwo: "",
    newVersionThree: "",
  };

  renderCompetitors = (competitor, index) => {
    return (
      <tr key={competitor.competitorId}>
        <td>{competitor.competitorName}</td>
        <td>
          <input
            type="radio"
            name="selected"
            value={competitor.competitorId}
            checked={this.state.selected === competitor.competitorId}
            onChange={this.handleOptionChange}
          />
        </td>
        <td>placeholder</td>
        <td>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onEdit(competitor)}
          >
            <Edit />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onDelete(competitor)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };

  renderFeatures = (feature, index) => {
    return (
      <tr key={feature.featureId}>
        <td>{feature.servesTheBenefit}</td>
        <td>
          {feature.featureName}
        </td>
        <td>{feature.shortDescription}</td>
        <td>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onEditFrist(feature)}
          >
            <Edit />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onFeatureDelete(feature)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };
  renderFeaturesChart = (feature, index) => {
    return (
      <tr key={feature.featureId}>
        <td>{feature.servesTheBenefit}</td>
        <td>{feature.percivedValue}</td>
        <td>
          {feature.usp}
        </td>
        <td>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onEditSecond(feature)}
          >
            <Edit />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onFeatureDelete(feature)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };

  renderFeaturesVersion = (feature, index) => {
    return (
      <tr key={feature.featureId}>
        <td>{feature.servesTheBenefit}</td>
        <td>{feature.versionOne}</td>
        <td>{feature.versionTwo}</td>
        <td>{feature.versionThree}</td>
        <td>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onEditThree(feature)}
          >
            <Edit />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onFeatureDelete(feature)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };

  componentWillReceiveProps(nextProps) {
    const data = nextProps.competitors.map((elem) => {
      return { ...elem.competitorInfo, competitorId: elem.id };
    });

    const features = nextProps.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
       });
// console.log("HELLLO");
// console.log(nextProps.featureData);
// console.log(nextProps.customerData);
const dataCustomer = nextProps.customerData.map(elem => {
    return { ...elem.customerData, timestamp: elem.timestamp };
  });

  const points = nextProps.featureData.map((elem) => {
    return {  x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp) };
  });
  // console.log("Points::::::::::::");
  // console.log(points);
  
  // console.log(dataCustomer);
  var customersHolder;
  // the below logic gets the current customr data;
  if (dataCustomer.length !== 0) {
    customersHolder = dataCustomer[0];
  }
  // console.log("Holder:");
  // console.log(customersHolder);
  for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
  //  console.log(index + " " + dataCustomer[index]);
    if (customersHolder.timestamp < dataCustomer[index].timestamp) {
      customersHolder = dataCustomer[index];
    }
  }
  //  console.log("customerHolder: ");
  //  console.log(customersHolder);
  var currentCustomers = [];

//   currentCustomers.push(customersHolder.primaryTargetCustomer);
//   currentCustomers.push(customersHolder.secondaryTargetCustomer);

    this.setState({
      //customerSegList: currentCustomers,
      FeaturesList: features,
      pointList: points,
      data: data,
      fetchDone: true,
      selected: nextProps.primaryCompetitor,
    });
  }

  async componentDidMount() {
    // fetchs all data needed from database

    await this.props.onFetchFeature(this.props.token, this.props.userId);
    var data = this.props.featureData.map(elem => {
      return { ...elem.featureData, featureid: elem.id };
    });

    await this.props.onFetchCustomer(this.props.token, this.props.userId);
    const dataCustomer = this.props.customerData.map(elem => {
      return { ...elem.customerData, timestamp: elem.timestamp };
    });
    

    var customersHolder;
    // the below logic gets the current customr data;
    if (dataCustomer.length !== 0) {
      customersHolder = dataCustomer[0];
    }
    // console.log("Holder:");
    // console.log(customersHolder);
    for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
      console.log(index + " " + dataCustomer[index]);
      if (customersHolder.timestamp < dataCustomer[index].timestamp) {
        customersHolder = dataCustomer[index];
      }
    }
    // console.log("customerHolder: ");
    // console.log(customersHolder);
    var currentCustomers = [];

    currentCustomers.push(customersHolder.primaryTargetCustomer);
    currentCustomers.push(customersHolder.secondaryTargetCustomer);

    this.setState({
      ...this.state,
      customerSegList: currentCustomers,
      //FeaturesList: data,
    });
    // //console.log(this.state.data);
  }


  handleClose = () => {
    this.setState({
      showAddModal: false,
      showAddFeatureModal: false,
      showDeleteMessage: false,
      showEditModal: false,
      showHelpModal:false,
      showExplanationModal: false,
      showEditFirstModal: false,
      showEditTwoModal: false,
      showEditThreeModal: false,
      showDeleteFeature: false,
    });
  };

  handleOptionChange = async (e) => {
    //console.log(e.target.value);
    await this.props.updatePrimaryCompetitor(e.target.value);
    //console.log(this.props);
    this.setState({ selected: this.props.primaryCompetitor });
  };

  onAdd = () => {
    this.setState({ showAddModal: true });
  };
  onAddFeature = () => {
    this.setState({ showAddFeatureModal: true });
  };

  openHelp = () => {
      this.setState({...this.state, showHelpModal: true});
  }

  onExplanation = () => {
    this.setState({...this.state, showExplanationModal: true});    
  }

  onEdit = (competitor) => {
    this.setState({ showEditModal: true, selectingCompetitor: competitor });
  };

  onEditFrist = (feature) => {
    this.setState({ showEditFirstModal: true, selectingFeature: feature });
  }
  onEditSecond = (feature) => {
    this.setState({ showEditTwoModal: true, selectingFeature: feature });
  }
  onEditThree = (feature) => {
    this.setState({ showEditThreeModal: true, selectingFeature: feature });
  }

  onDelete = (competitor) => {
    this.setState({ showDeleteMessage: true, selectingCompetitor: competitor });
  };
  onFeatureDelete= (feature) => {
    this.setState({ showDeleteFeature: true, selectingFeature: feature });
  };
  handleDeleteData = async (id) => {
   // console.log("Delete id ", id);
    await this.props.onDeleteCompetitor(id, this.props.token);
    //console.log(this.props);
    //console.log(this.state.data);
    const dataDelete = this.state.data.filter(
      (competitor) => competitor.competitorId !== id
    );
    this.setState({
      data: [...dataDelete],
      showDeleteMessage: false,
      selectingCompetitor: null,
    });
  };
  handleDeleteFeature = async (id) => {
    //console.log("Delete id ", id);
    await this.props.onDeleteFeature(id, this.props.token);
    //console.log(this.props);
    //console.log(this.state.FeaturesList);
    const dataDelete = this.state.FeaturesList.filter(
      (feature) => feature.featureId !== id
    );
    this.setState({
      FeaturesList: [...dataDelete],
      showDeleteFeature: false,
      selectingCompetitor: null,
    });
  };

  handleVersionChange = (e) =>{
    //console.log(e.target);
    this.setState({ ...this.state, showVersions: !this.state.showVersions });
  }

  handleInputChange = (e) => {
    //e.preventDefault();
    //console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleServesChange = (e) => {
    //e.preventDefault();
    //console.log(e.value);
    this.setState({ newServesTheBenefit: e.value });
  };
  handleSubmitData = async (e) => {
    e.preventDefault();
    const { competitorName } = this.state;
    const newData = {
      competitorInfo: { competitorName },
      userId: this.props.userId,
    };
    await this.props.onSubmitCompetitor(newData, this.props.token);
    this.setState({
      data: this.props.competitors.map((elem) => {
        return { ...elem.competitorInfo, competitorId: elem.id };
      }),
      showAddModal: false,
    });
  };

  handleSubmitFeature = async (e) => {
    e.preventDefault();
    //const { competitorName } = this.state;
    //console.log(this.state);
    const newData = {
      featureData: { 
          servesTheBenefit: this.state.newServesTheBenefit,
          featureName: this.state.newFeatureName,
          shortDescription: this.state.newShortDescription,
          percivedValue: 0,
          usp: 0,
          versionOne: "",
          versionTwo: "",
          versionThree: "",
    },
      userId: this.props.userId,
    };
    await this.props.onSubmitFeature(newData, this.props.token);
    this.setState({
      FeaturesList: this.props.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
      }),
      pointList: this.props.featureData.map((elem) => {
        return {  x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp) };
      }),
      newFeatureName: "",
      newServesTheBenefit: "",
      newShortDescription: "",
      showAddFeatureModal: false,
    });
  };

  handleEditData = async (oldData) => {
    const competitorId = oldData.competitorId;
    const newData = {
      ...oldData.competitorInfo,
      competitorName: this.state.competitorName,
    };
    let toServer = {
      competitorInfo: { ...newData },
      userId: this.props.userId,
    };
    await this.props.onUpdateCompetitor(
      competitorId,
      toServer,
      this.props.token
    );
    this.setState({
      showEditModal: false,
      competitorName: "",
      selectingCompetitor: null,
    });
  };

  handleEditFirst = async (oldData) =>{
   // console.log(oldData);
    const featureId = oldData.featureId;
    delete oldData.featureId;
    //console.log(featureId);
    var featureN;
    if (this.state.newFeatureName === "") {
      featureN = oldData.featureName;
    } else {
      featureN = this.state.newFeatureName;
    }
    var servesTheBene;
    if (this.state.newServesTheBenefit === "") {
      servesTheBene = oldData.servesTheBenefit;
    } else {
      servesTheBene = this.state.newServesTheBenefit;
    }
    var short;
    if (this.state.newShortDescription === "") {
      short = oldData.shortDescription;
    } else {
      short = this.state.newShortDescription;
    }

    var newVal;
    if (this.state.newPercivedValue === "") {
      newVal = oldData.percivedValue;
    } else {
      newVal = this.state.newPercivedValue;
    }
    var newU;
    if (this.state.newUSP === "") {
      newU = oldData.usp;
    } else {
      newU = this.state.newUSP;
    }
    var newVOne;
    if (this.state.newVersionOne === "") {
      newVOne = oldData.versionOne;
    } else {
      newVOne = this.state.newVersionOne;
    }
    var newVTwo;
    if (this.state.newVersionTwo === "") {
      newVTwo = oldData.versionTwo;
    } else {
      newVTwo = this.state.newVersionTwo;
    }
    var newVThree;
    if (this.state.newVersionThree === "") {
      newVThree = oldData.versionThree;
    } else {
      newVThree = this.state.newVersionThree;
    }
    const newData = {
      ...oldData,
      servesTheBenefit: servesTheBene,
      featureName: featureN,
      shortDescription: short,
      percivedValue: newVal,
      usp: newU,
      versionOne: newVOne,
      versionTwo: newVTwo,
      versionThree: newVThree,
    }
    const toServer = {
      featureData: {...newData},
      userId: this.props.userId,
    }
    await this.props.onUpdateFeature(
      featureId,
      toServer,
      this.props.token
    );
    //console.log("FJFJFJ");
    //console.log(this.props.featureData);
    this.setState({
      FeaturesList: this.props.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
      }),
      pointList: this.props.featureData.map((elem) => {
        return { x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp)  };
      }),
      showEditFirstModal: false,
      showEditTwoModal: false,
      showEditThreeModal: false,
      newFeatureName: "",
      newServesTheBenefit: "",
      newShortDescription: "",
      newUSP: "",
      newVersionOne: "",
      newVersionTwo: "",
      newVersionThree: "",
      newPercivedValue: "",
      selectingCompetitor: null,
      selectingFeature: null,
    });

  }

  
  render() {
    //console.log(this.state);

  //   var pointList = [];
  //     // this.state.FeatureList.forEach(function(element) {
  //     //     selectList.push({ x:element.percivedValue, y: element.usp })
  //     // });
  //   if(this.state.FeaturesList !== []){
  //   for(var index = 0; this.state.FeaturesList !== index && this.state.fetchDone === true; index++){
  //     pointList.push({ x: this.state.FeaturesList[index].percivedValue, y: this.state.FeaturesList[index].usp })
  //   }
  // }
    //console.log(this.state.pointList);

    const dataC = {
        labels: ['jan', 'feb', 'jake' , 'time'],
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            //showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            pointStyle: ["hh","jj", "dddd", "ddddddd"],
            data: [...this.state.pointList]
          },
          {
            label: 'Vertical Line',
            fill: false,
            showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            data: [{x: 3, y: 0}, {x: 3, y: 5}]
          },
          {
            label: 'Horizontal Line',
            fill: false,
            showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            data: [{x: 0, y: 3}, {x: 5, y: 3}]
          }
        ]
      };

       const chartOptions =   {
        lineAt: 3,
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 1,
                  max: 5,
                  stepSize: 1,
                  lineAt: 3,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.06)",
                zeroLineColor: "rgba(0,0,0,0.1)",
                offsetGridLines: false
              }
            }],
            xAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 1,
                  max: 5,
                  stepSize: 1,
                  line: 3
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.06)",
                zeroLineColor: "rgba(0,0,0,0.1)",
                offsetGridLines: false
              },
              drawHorizontalLine: {
                lineY: [3, 3],
                lineColor: "rgba(50, 155, 255, 0.85)",
                text: 'Obj 67 & 68',
                textPosition: 720,
                textFont: '18px sans-serif',
                textColor: "rgba(50, 155, 255, 0.85)"
              },
            }],
            // drawHorizontalLine: {
            //   lineY: [3, 3],
            //   lineColor: "rgba(50, 155, 255, 0.85)",
            //   text: 'Obj 67 & 68',
            //   textPosition: 720,
            //   textFont: '18px sans-serif',
            //   textColor: "rgba(50, 155, 255, 0.85)"
            // },
            annotation: {
              annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 3,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 4,
                label: {
                  enabled: false,
                  content: 'Test label'
                }
              }]}
         }
        //  annotation: {
        //   annotations: [{
        //     type: 'line',
        //     mode: 'horizontal',
        //     scaleID: 'y-axis-0',
        //     value: 3,
        //     borderColor: 'rgb(75, 192, 192)',
        //     borderWidth: 4,
        //     label: {
        //       enabled: false,
        //       content: 'Test label'
        //     }
        //   }]
        // },
        // drawHorizontalLine: {
        //   lineY: [3, 3],
        //   lineColor: "rgba(50, 155, 255, 0.85)",
        //   text: 'Obj 67 & 68',
        //   textPosition: 720,
        //   textFont: '18px sans-serif',
        //   textColor: "rgba(50, 155, 255, 0.85)"
        // }
      
  };


      var selectList = [];
      this.state.customerSegList.forEach(function(element) {
          selectList.push({ label:element, value: element })
      });

      if (!this.state.fetchDone) {
        return (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        );
      } else {
      return (
        <React.Fragment>
        <Container>
              <Container2>
                  
            <div>
          <h1>Features Pricing Power</h1>
          </div>

      
          <hr/>
         
            <Row>
                <h3>Each individual feature, has its' own pricing power. 
                    They also have their individual role, when it comes to 
                    building customised offer to your customers.This is why, 
                    the next exercise needs to be repeated for 
                    each individual Customer type / group, each market and 
                    each product or service.
                </h3>
                <h3>For now, pick your customer group or click below for an explanation on
                    Killers, Fillers and Leaders.
                </h3>
            </Row>
            <Row>
            <Button variant="primary" onClick={this.onExplanation} >
             Explain
             </Button>
            </Row>
            <hr/>
          <Row>
          {/* <Col xs>
          <ScatterChart chartData={dataC}/>
          </Col> */}
              <Col xs>
          <Table striped bordered hover size="sm">
          <thead>
              <tr>
                <th>Serves the benefit</th>
                <th>Percived Value</th>
                <th>Strength of Competitive Advantage
                    USP
                </th>
                <th id="action">Action</th>
              </tr>
            </thead>
            <tbody>{this.state.FeaturesList.map(this.renderFeaturesChart)}</tbody>
          </Table>
        
          </Col>
          <Col xs>
          {/* <ScatterChart chartData={dataC}/> */}

          <h2>Percived Value vs. Strenght of competitive Advantage Chart</h2>
          <Scatter data={dataC} options={chartOptions}/>
          </Col>
     
          </Row>
          {/* <hr/>
          <Row>
                    <h3>When offering your benefits, with a specific set of features, you may not 
                        want to offer the full fledge feature to all you customers. Either because 
                        the full feature is too much for them, or because you may want to create some 
                        differentiation when it comes to pricing. This is where you divide your feature 
                        in multiple versions. Each targetting the same need, but at a higher or lower degree."</h3>
                    <h3>Do some of your features warrant being split into individual versions?</h3>
                      <Toggle
                          id='cheese-status'
                          defaultChecked={!this.state.showVersions}
                          onChange={this.handleVersionChange} />
                      {/* <label htmlFor='cheese-status'>Adjacent label tag</label> */}
          {/* </Row>
          <hr/>
          <Row>
          <Table striped bordered hover size="sm" hidden={this.state.showVersions}>
            <thead>
              <tr>
                <th>Serves the benefit</th>
                <th>Version One</th>
                <th>Version Two</th>
                <th>Version Three</th>
                <th id="action">Action</th>
              </tr>
            </thead>
            <tbody>{this.state.FeaturesList.map(this.renderFeaturesVersion)}</tbody>
          </Table>
        
          </Row> */}
          
       
         
          {this.state.showAddModal ? (
            <Modal
              show={this.state.showAddModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Competitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>Add competitor: </label>
                  <input
                    type="text"
                    placeholder="Enter your secondary competitor"
                    name="competitorName"
                    onChange={this.handleInputChange}
                  />
                  <hr/>
                <label>Add competitoreeee: </label>
                  <input
                    type="text"
                    placeholder="Enter your secondary competitor"
                    name="competitorName"
                    onChange={this.handleInputChange}
                  />
                    <label>Add competitoreeeetrtwe: </label>
                  <input
                    type="text"
                    placeholder="Enter your secondary competitor"
                    name="competitorName"
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleSubmitData}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}


{this.state.showAddFeatureModal ? (
            <Modal
              show={this.state.showAddFeatureModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>What benefit does it serve: </label>
                  <Select
                    //type="text"
                    name="newServesTheBenefit"
                    options={selectList}
                    onChange={this.handleServesChange}
                  />
                  <hr/>
                <label>Add Feature Name: </label>
                  <input
                    type="text"
                    id="feature"
                    placeholder="Enter your secondary competitor"
                    name="newFeatureName"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                    <label>Add Short Description: </label>
                  <input
                    type="text"
                    id="description"
                    placeholder=""
                    name="newShortDescription"
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleSubmitFeature}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          {this.state.showEditModal ? (
            <Modal
              show={this.state.showEditModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Competitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>Edit competitor name: </label>
                  <input
                    type="text"
                    placeholder="Enter your secondary competitor"
                    name="competitorName"
                    onChange={this.handleInputChange}
                    defaultValue={this.state.selectingCompetitor.competitorName}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditData(this.state.selectingCompetitor)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}



{this.state.showEditFirstModal ? (
            <Modal
              show={this.state.showEditFirstModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <label>What benefit does it serve: </label>
                  <Select
                    //type="text"
                    name="newServesTheBenefit"
                    options={selectList}
                    onChange={this.handleServesChange}
                    placeholder={this.state.selectingFeature.servesTheBenefit}
                  />
                  <hr/>
                <label>Edit Feature Name: </label>
                  <input
                    type="text"
                    id="feature"
                    placeholder={this.state.selectingFeature.featureName}
                    name="newFeatureName"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                    <label>Add Short Description: </label>
                  <input
                    type="text"
                    id="description"
                    placeholder={this.state.selectingFeature.shortDescription}
                    name="newShortDescription"
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditFirst(this.state.selectingFeature)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.showEditTwoModal ? (
            <Modal
              show={this.state.showEditTwoModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature:Set Percived value and Strength of Competitve Advantage</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <label>What is the percived value of this feature? </label>
                  <input
                    //type="text"
                    name="newPercivedValue"
                    
                    onChange={this.handleInputChange}
                    placeholder={this.state.selectingFeature.percivedValue}
                  />
                  <hr/>
                <label>What is the Strength of Competitve Advantage of this feature?</label>
                  <input
                    type="text"
                    id="feature"
                    placeholder={this.state.selectingFeature.usp}
                    name="newUSP"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditFirst(this.state.selectingFeature)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.showEditThreeModal ? (
            <Modal
              show={this.state.showEditThreeModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature:Set Percived value and Strength of Competitve Advantage</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <label>What is version one? </label>
                  <input
                    //type="text"
                    name="newVersionOne"
                    
                    onChange={this.handleInputChange}
                   // placeholder={this.state.selectingFeature.versionOne}
                  />
                  <hr/>
                <label>What is version two?</label>
                  <input
                    type="text"
                    id="feature"
                   // placeholder={this.state.selectingFeature.versionTwo}
                    name="newVersionTwo"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                <label>What is version three?</label>
                  <input
                    type="text"
                    id="feature"
                    placeholder={this.state.selectingFeature.versionThree}
                    name="newVersionThree"
                    onChange={this.handleInputChange}
                  />
                
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditFirst(this.state.selectingFeature)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}


          {this.state.showDeleteMessage ? (
            <Modal
              show={this.state.showDeleteMessage}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning!!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this competitor?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    this.handleDeleteData(
                      this.state.selectingCompetitor.competitorId
                    )
                  }
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.showDeleteFeature ? (
            <Modal
              show={this.state.showDeleteFeature}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning!!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this feature?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    this.handleDeleteFeature(
                      this.state.selectingFeature.featureId
                    )
                  }
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

            {this.state.showHelpModal ? (
            <Modal
              show={this.state.showHelpModal}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              Features is the actual "What" a Product or Service does. 
              A Product or Service is the sum of the Features it contains.

              It is best to have a relatively detailed Feature list as it 
              is the Features will be the components of any differentiated 
              Pricing and Packaging offers to different Customers, Channels, etc.
              For example, a simple registeration form serves a benefit. The feature responsible for 
              this could be called the Parent Portal, A short description  could be a one click sign 
              in for parents.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          ) : null}
          {this.state.showExplanationModal ? (
            <Modal
              show={this.state.showExplanationModal}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              This exercise results in 3 types
              of Feature types:
              {<hr/>}
              Leaders are key to land the sale; they open a customer's wallet, 
              and aim to catch a large proportion of a customer's WTP. They are the Burger 
              in a fast-food restaurant.
              {<hr/>}
              Fillers, Customers do not buy the product / service or package for it. 
              But they will allow you to increase it's price, capture extra WTP, as 
              long as it includes a Leader too. They are the Fries.
              {<hr/>}
              Killers kill the product/service for the user. Think of restaurant with bad food, 
              so you avoid them from now on.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          ) : null}

           </Container2>
        </Container>
        </React.Fragment>
      );
          }
    }
}


const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    competitors: state.competitor.competitors,
    primaryCompetitor: state.competitor.primaryCompetitor,
    featureData: state.features.featureList,
    customerData: state.customerNeeds.customerData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCustomer: (token, userId) => dispatch(actions.fetchCustomer(token, userId)),
    onFetchCompetitor: (data, token) => {
      dispatch(actions.fetchCompetitor(data, token));
    },
    onSubmitCompetitor: (data, token) => {
      dispatch(actions.submitCompetitorInfo(data, token));
    },
    onDeleteCompetitor: (competitorId, token) => {
      dispatch(actions.deleteCompetitor(competitorId, token));
    },
    onUpdateCompetitor: (competitorId, data, token) =>
      dispatch(actions.updateCompetitor(competitorId, data, token)),
    updatePrimaryCompetitor: (primaryCompetitorId) => {
      dispatch(actions.updatePrimaryCompetitor(primaryCompetitorId));
    },
    onFetchFeature: (data, token) => {
        dispatch(actions.fetchFeature(data, token));
      },
      onSubmitFeature: (data, token) => {
        dispatch(actions.submitFeatureInfo(data, token));
      },
      onDeleteFeature: (featureId, token) => {
        dispatch(actions.deleteFeature(featureId, token));
      },
      onUpdateFeature: (featureId, data, token) =>
        dispatch(actions.updateFeature(featureId, data, token)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(featuresPower)
);
