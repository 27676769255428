// Created by: Quang Nguyen
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import store from "./store/store";
//import { Provider } from 'react-redux';

import "./index.css";
import "@atlaskit/css-reset";
import "bootstrap/dist/css/bootstrap.min.css";

// Bootswatch theme
import "bootswatch/dist/materia/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
const app = (

    <BrowserRouter>
      <App />
    </BrowserRouter>
 
);
ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
