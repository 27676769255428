import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import Modal from "react-bootstrap/Modal";
import Container from "../../hoc/container";
// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file enables the user to enter customer-info, this also gets user skip any section by pressing
// continue. ( this effectly turns of the given section of MVP )
// Related files: store/actions/customer.js, store/reducers/customer.js
// Customer and related files are called in index.js and App.js
// Note: this file assumes the user is logged in to be used.
class customer extends Component {
    /**
     *  opens controls the opening and closing of the modals
     */
    state = {
        segmentedCustomers: "",
        primaryTargetCustomer: "",
        customerPersona: "",
        descriptionOfCustomer: "",
        secondaryTargetCustomer: "",
        opens: [false, false, false, false, false],
    }

    // This function just updates the state to the user input
    onChangedHandler = (event) => {

        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });

    };

    // saves the current state, the inital state means the user skipped that seciton
    submitAndSaveNewData = (event) => {

        this.props.ADD_CUSTOMER_INFOMATION(
            this.state.segmentedCustomers,
            this.state.primaryTargetCustomer,
            this.state.customerPersona,
            this.state.descriptionOfCustomer,
            this.state.secondaryTargetCustomer,

        );
       // console.log("Saved data");

        const newData = {
            customerData: {
                segmentedCustomers: this.state.segmentedCustomers,
                primaryTargetCustomer: this.state.primaryTargetCustomer,
                customerPersona: this.state.customerPersona,
                descriptionOfCustomer: this.state.descriptionOfCustomer,
                secondaryTargetCustomer: this.state.secondaryTargetCustomer,
            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
        this.props.onSubmitCustomer(newData, this.props.token);
    };

    //Opens the next modal, if all modals have been opened then the state will be saved.
    onNextModal = (event) => {
        event.preventDefault();

        if (event.target.id === 'beginModals') {
            let opens = [...this.state.opens];
            opens[0] = true;
            this.setState({
                ...this.state,
                opens: opens,
            });
            return;
        }

        if (event.target.id === 'segmentedCustomerModal') {
            let opens = [...this.state.opens];
            opens[0] = false;
            opens[1] = true;
            this.setState({
                ...this.state,
                opens: opens,
            });
            return;
        }

        if (event.target.id === 'primaryTargetCustomerModal') {
            let opens = [...this.state.opens];
            opens[1] = false;
            opens[2] = true;
            this.setState({
                ...this.state,
                opens: opens,
            });
            return;
        }

        if (event.target.id === 'customerPersonaModal') {
            let opens = [...this.state.opens];
            opens[2] = false;
            opens[3] = true;
            this.setState({
                ...this.state,
                opens: opens,
            });
            return;
        }

        if (event.target.id === 'desctiptionOfCustomerModal') {
            let opens = [...this.state.opens];
            opens[3] = false;
            opens[4] = true;
            this.setState({
                ...this.state,
                opens: opens,
            });
            return;
        }

        if (event.target.id === 'secondaryTargetCustomerModal') {
            let opens = [...this.state.opens];
            opens[4] = false;
            this.setState({
                ...this.state,
                opens: opens,
            });
        }

        this.submitAndSaveNewData();

    }

    skipAll = (event) => {
        this.submitAndSaveNewData();
        //TODO: Just push to the next page
    }



    render() {
        // The modals are below, opened on a click and allows the user to enter all info they want.
        return (
            <React.Fragment>
                <Container>
            <div className={'text-center'}>
                <form className='form-signin' >
                    <div className='text-center mb-4'>
                        <h1 className='h3 mb-3 font-weight-normal'>Customer Market Information</h1>
                    </div>

                    <div className='text-center mb-4'>
                        <p>A Market, as previously defined, regroups Buyers, and Sellers.
                        Let's start with the Buyers. At this time, we will consider YOUR target Customer to be a current or
                        potential Buyer although you may consider the 2 seperately in your strategy at a later stage
                        </p>
                    </div>

                    <Row>
                        <button className='btn btn-lg btn-primary btn-block' id='beginModals' onClick={this.onNextModal}>
                            Enter Customer Info
					    </button>

                        <button className='btn btn-lg btn-primary btn-block' onClick={this.skipAll}>
                            Skip for later
					    </button>
                    </Row>


                    {this.state.opens[0] ? (
            <Modal
              show={this.state.opens[0]}
             // onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Segments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                	
                <div className='form-signin'>
                            <div>
                                <h3 className='h3 mb-3 font-weight-normal'>Have you segmented your (potential) customers?</h3>

                            </div>

                            <div>
                                <p>
                                    What do we mean by segmented and segmentation: Your customers very rarely all behave the
                                    same way. They may have different Needs, they may react differently to different Sales or
                                    Marketing messages, and they may have different Willingness-to-Pay (how much they will pay
                                    for a given product or service). As such, it may be worth conducting an exercise to define
                                    individual segments (i.e. groups) of similar customers
                                </p>
                            </div>

                            <select
                                id='segmentedCustomers'
                                className='form-control'
                                onChange={this.onChangedHandler}
                                defaultValue='default'>
                                <option value='default' disabled>
                                    ...
					    	</option>
                                <option>
                                    No
						    </option>
                                <option>
                                    yes
					    	</option>
                                <option>
                                    Mostly
						    </option>
                                <option>
                                    a little
					    	</option>
                            </select>

                        </div>



                        <ButtonGroup size="sm">
                            <Button id="segmentedCustomerModal" onClick={this.onNextModal}>Next</Button>
                        </ButtonGroup>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          ) : null}


{this.state.opens[1] ? (
            <Modal
              show={this.state.opens[1]}
             // onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Segments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                	
                <div className='form-signin'>
                            <div>
                                <h3 className='h3 mb-3 font-weight-normal'>Who is your primary target Customer (segment)?</h3>

                            </div>



                            <input
                                id='primaryTargetCustomer'
                                className='form-control'
                                onChange={this.onChangedHandler}
                                defaultValue='desctiption'>
                            </input>
                            <div>
                                <p>(give it a unique short name)</p>
                            </div>
                        </div>




                        <ButtonGroup size="sm">
                            <Button id="primaryTargetCustomerModal" onClick={this.onNextModal}>Next</Button>
                        </ButtonGroup>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.opens[2] ? (
            <Modal
              show={this.state.opens[2]}
             // onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Segments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <div className='form-signin'>
                            <div>
                                <h3 className='h3 mb-3 font-weight-normal'>Have you built a persona for this customer?</h3>

                            </div>



                            <select
                                id='customerPersona'
                                className='form-control'
                                onChange={this.onChangedHandler}
                                defaultValue='default'>
                                <option value='default' disabled>
                                    ...
					    	</option>
                                <option>
                                    No
						    </option>
                                <option>
                                    Yes
					    	</option>

                            </select>


                            <div>
                                <p>
                                    A customer (or buyer) persona is a Quantitative portrait
                                    of your average target customer
                                    </p>
                            </div>
                        </div>


                        <ButtonGroup size="sm">
                            <Button id="customerPersonaModal" onClick={this.onNextModal}>Next</Button>
                        </ButtonGroup>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.opens[3] ? (
            <Modal
              show={this.state.opens[3]}
             // onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Segments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <div className='form-signin'>
                            <div>
                                <h3 className='h3 mb-3 font-weight-normal'>Shortly describe this customer (optional)</h3>

                            </div>



                            <input
                                id='descriptionOfCustomer'
                                className='form-control'
                                onChange={this.onChangedHandler}
                                defaultValue='desctiption'>


                            </input>
                        </div>

                        <ButtonGroup size="sm">
                            <Button id="desctiptionOfCustomerModal" onClick={this.onNextModal}>Next</Button>
                        </ButtonGroup>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          ) : null}



{this.state.opens[4] ? (
            <Modal
              show={this.state.opens[4]}
             // onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Customer Segments</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <div className='form-signin'>
                            <div>
                                <h3 className='h3 mb-3 font-weight-normal'>If you have, or believe you may have in
                                the future, a secondary target customer (group), please name it
                                    </h3>

                            </div>



                            <input
                                id='secondaryTargetCustomer'
                                className='form-control'
                                onChange={this.onChangedHandler}
                                defaultValue='Name of group'>


                            </input>
                        </div>

                        <ButtonGroup size="sm">
                            <Button id="secondaryTargetCustomerModal" onClick={this.onNextModal}>Next</Button>
                        </ButtonGroup>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                
              </Modal.Footer>
            </Modal>
          ) : null}

                    


                </form>
            </div>
            </Container>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        data: state.data.data,
        userId: state.auth.userId,
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        finished: state.data.finished,
        costId: state.data.costid,
        open: false,
    };
};


// segmentedCustomers: "",
//         primaryTargetCustomer: "",
//         customerPersona: "",
//         descriptionOfCustomer: "",
//         secondaryTargetCustomer: "",

const mapDispathToProps = (dispatch) => {
    return {
        ADD_CUSTOMER_INFOMATION: (segmentedCustomers, primaryTargetCustomer, customerPersona, descriptionOfCustomer,
            secondaryTargetCustomer) =>
            dispatch(
                actions.ADD_CUSTOMER_INFOMATION(
                    segmentedCustomers,
                    primaryTargetCustomer,
                    customerPersona,
                    descriptionOfCustomer,
                    secondaryTargetCustomer
                )
            ),
        onSubmitCustomer: (data, token) => dispatch(actions.submitCustomer(data, token)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispathToProps)(customer)
);