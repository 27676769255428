import React from 'react';
import styled from 'styled-components';
import initialData from './InitialData';
import Column from './Column/Column';
import { DragDropContext } from 'react-beautiful-dnd';
import ConfirmationModal from './ConfirmationModal/Modal';
import Container from "../../hoc/container";

const Container2 = styled.div`
	display: flex;
`;

class Priority extends React.Component {
	state = {
		...initialData,
		show: false,
	};

	onDragEnd = (result) => {
		//TODO
		document.body.style.color = 'inherit';
		document.body.style.backgroundColor = 'inherit';
		const { destination, source, draggableId } = result;
		if (!destination) {
			return;
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return;
		}
		const start = this.state.columns[source.droppableId];
		const finish = this.state.columns[destination.droppableId];

		if (start === finish) {
			const newTaskIds = Array.from(start.taskIds);
			newTaskIds.splice(source.index, 1);
			newTaskIds.splice(destination.index, 0, draggableId);

			const newColumn = {
				...start,
				taskIds: newTaskIds,
			};
			const newState = {
				...this.state,
				columns: {
					...this.state.columns,
					[newColumn.id]: newColumn,
				},
			};
			this.setState(newState);
			return;
		}
		const startTaskIds = Array.from(start.taskIds);
		startTaskIds.splice(source.index, 1);
		const newStart = {
			...start,
			taskIds: startTaskIds,
		};

		const finishTaskIds = Array.from(finish.taskIds);
		finishTaskIds.splice(destination.index, 0, draggableId);
		const newFinish = {
			...finish,
			taskIds: finishTaskIds,
		};
		const newState = {
			...this.state,
			columns: {
				...this.state.columns,
				[newStart.id]: newStart,
				[newFinish.id]: newFinish,
			},
		};
		this.setState(newState);
		return;
	};

	hideModal = () => {
		this.setState({show: false})
	}
	showModal = () => {
		this.setState({ show: true })
		console.log(this.state.show);
	}
	render() {
		console.log(this.state.show)
		return (
			<React.Fragment>
				<Container>
				{/* {this.state.show ? <ConfirmationModal /> : null} */}
				<div className='container h-100'>
					<ConfirmationModal show={this.state.show} onHide={this.hideModal} columns={this.state.columns} tasks={this.state.tasks}/>
					
					<div className='row h-100 justify-content-center align-items-center'>
						<DragDropContext
							// onDragStart={this.onDragStart}
							// onDragUpdate={this.onDragUpdate}
							onDragEnd={this.onDragEnd}>
							<Container2>
								{this.state.columnOrder.map((columnId) => {
									const column = this.state.columns[columnId];
									const tasks = column.taskIds.map(
										(taskId) => this.state.tasks[taskId]
									);

									return (
										<Column key={column.id} column={column} tasks={tasks} />
									);
								})}
							</Container2>
						</DragDropContext>
					</div>
					<div>
						<div className='m-2 btn btn-warning'>Go back</div>
						<div className='m-2 btn btn-primary' onClick={this.showModal}>
							Continue
						</div>
					</div>
				</div>
				</Container>
			</React.Fragment>
		);
	}
}

export default Priority;
