import React from "react";
import NavigationItems from "../../components/Navbar/NavigationItems";

const Menu = () => {
  return (
    <div className="menu">
      <NavigationItems />
    </div>
  );
};

export default Menu;
