export const quang = {
    project_id: 'advisr-project-727b7',
    auth_key: 'AIzaSyDdbhXwPK3mp6GSPVo1x55DkcTYmyua9Y8'
}
export const mario = {
    project_id: 'my-base-project-81953',
    auth_key: 'AIzaSyD-Q8-vuLr75mbDMLqzQlILDD3j1myQXrk'
}

export const chloe = {
    project_id: 'ndexio',
    auth_key: 'AIzaSyDnopY0db6Z2RX2hEsO6Xj4LPBoOjcIV_o'
}

export const nDexio = {
    project_id: 'ndexio-mvp-alpha',
    auth_key: 'AIzaSyAaQ1oPfJoQutl7HKhsmVI8aVd9NupcSsQ'
}