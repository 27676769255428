import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { currency } from './currencyData';
import Container from "../../hoc/container";
class Profile extends Component {
	state = {
		notFirstSale: false,
		notYearStart: false,
		companyType: '',
		firstSaleDate: '',
		yearStart: '',
		currency: '',
	};

	async componentDidMount() {
		await this.props.onFetchUserData(this.props.token, this.props.userId);
	}

	onChangedHandler = (event) => {
		this.setState({
			[event.target.id]: event.target.value,
		});
	};

	notFirstSaleClickHandler = (event) => {
		this.setState((previousState) => {
			return {
				notFirstSale: !previousState.notFirstSale,
				firstSaleDate: ''
			};
		});
	};

	notYearStartClickHandler = (event) => {
		this.setState((previousState) => {
			return {
				notYearStart: !previousState.notYearStart,
				yearStart: ''
			};
		});
	};

	onSubmitHandler = async event => {
		event.preventDefault();
		let submitData = {
			...this.state
		}
		delete submitData['notFirstSale']
		delete submitData['notYearStart']
		await this.props.onSubmitCompanyData(this.props.token, submitData)
		this.props.history.push('/')
	}

	render() {
		console.log(this.state);
		return (
			<React.Fragment>
				<Container>
			<div className='container h-100'>
				<div className='row'>
					<div className='col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center'>
						<h2>Company Profile</h2>
					</div>
				</div>
				<div className='row'>
					<div className='col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xs-offset-3'>
						<form id='company-profile-form' className='form' onSubmit={this.onSubmitHandler}>
							<div className='form-group'>
								<label className='form-label' htmlFor='companyName'>
									A few basics about your Company
								</label>
								<input
									disabled
									type='text'
									className='form-control'
									id='companyName'
									name='name'
									value={this.props.userData.companyName}
									tabIndex='1'
									required
								/>
							</div>
							<div className='form-label-group w-50 d-inline-block'>
								<select
									id='companyType'
									className='form-control'
									onChange={this.onChangedHandler}
									defaultValue='default'>
									<option value='default' disabled>
										What kind of company are you?
									</option>
									<option>Software and Internet</option>
									<option>Agriculture and Mining</option>
									<option>Business Services</option>
									<option>Computer and Electronics</option>
									<option>Consumer Services</option>
									<option>Education</option>
									<option>Energy and Utilities</option>
									<option>Financial Services</option>
									<option>Government</option>
									<option>Health, Pharmaceuticals, and Biotech</option>
									<option>Manufacturing</option>
									<option>Media and Entertainment</option>
									<option>Real Estate and Construction</option>
									<option>Retail</option>
									<option>Telecommunications</option>
									<option>Transportation and Storage</option>
									<option>Travel Recreation and Leisure</option>
									<option>Wholesale and Distribution</option>
									<option>Other</option>
								</select>
							</div>
							<div className='form-group mt-4'>
								<label className='form-label' htmlFor='firstSaleDate'>
									When did you start trading / made your first sale?
								</label>
								<input
									disabled={this.state.notFirstSale}
									type='text'
									className='form-control'
									id='firstSaleDate'
									name='firstSaleDate'
									placeholder='mm/dd/yyyy'
									tabIndex='2'
									onChange={this.onChangedHandler}
									value={this.state.notFirstSale ? '' : this.state.firstSaleDate}
									required
								/>
							</div>
							<div className='form-check'>
								<input
									type='checkbox'
									checked={this.state.notFirstSale}
									onChange={this.notFirstSaleClickHandler}
									className='form-check-input'
									id='notFirstSale'
								/>
								<label className='form-check-label' htmlFor='notFirstSale'>
									I have not yet made my first sale
								</label>
							</div>
							<div className='form-group mt-5'>
								<label className='form-label' htmlFor='yearStart'>
									When does your Financial / Fiscal Year start, if different?
								</label>
								<input
									type='text'
									disabled={this.state.notYearStart}
									className='form-control'
									id='yearStart'
									name='yearStart'
									placeholder='mm/dd/yyyy'
									tabIndex='2'
									onChange={this.onChangedHandler}
									value={this.state.notYearStart ? '' : this.state.yearStart}

									required
								/>
							</div>
							<div className='form-check'>
								<input
									type='checkbox'
									checked={this.state.notYearStart}
									onChange={this.notYearStartClickHandler}
									className='form-check-input'
									id='notYearStart'

								/>
								<label className='form-check-label' htmlFor='notFirstSale'>
									I just follow the calendar year
								</label>
							</div>
							<div className='form-label-group w-50 d-inline-block mt-3'>
								<select
									id='currency'
									className='form-control'
									onChange={this.onChangedHandler}
									defaultValue='default'>
									<option value='default' disabled>
										What currency do you use?
									</option>
									{Object.keys(currency).map((name, index) => (
										<option key={name}>{name}</option>
									))}
								</select>
							</div>
							<span className='ml-4'>{}</span>
							<div className='text-center'>
								<button type='submit' className='btn btn-start-order'>
									Next step
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
			</Container>
			</React.Fragment>
		);
	}
}
const mapStateToProps = (state) => {
	return {
		token: state.auth.token,
		userId: state.auth.userId,
		userData: state.aboutMe,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onFetchUserData: (token, userId) =>
			dispatch(actions.fetchUserData(token, userId)),
		onSubmitCompanyData: (token, companyData) => dispatch(actions.submitCompanyData(token, companyData))
	};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile));
