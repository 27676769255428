// starter code Created by: Quang Nguyen
import React, { Component } from "react";
import { forwardRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Alert from "@material-ui/lab/Alert";
import { Container as Container2, Row, Col } from 'react-bootstrap';
import { Multiselect } from 'react-widgets';
import { Tooltip } from '@material-ui/core';
import needs_triangle from "../../assets/images/needs_triangle.jpg";
import 'react-widgets/dist/css/react-widgets.css';
import Container from "../../hoc/container"; 


import * as actions from "../../store/actions/index";
import Market from "../../components/Market/Market";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class  benefits extends Component {


  state = {
    data: [],
    dataNeeds: [],
    currentCustomers: [],
    markets: [],
    selectedMarkets: [],
    selectedCustomers: [],
    openModal: false,
    isError: false,
    errorMessages: []
  };

  async componentDidMount() {
    // fetch data from database

    const holdOne = await this.props.onFetchNeed(this.props.token, this.props.userId);
    var data = this.props.data.map(elem => {
      return { dataValues:elem.dataValues, id: elem.id};
    });

    for(var i = 0; i !== data.length; i++){
      data[i].dataValues.id = data[i].id;
    }

    console.log("hehe");
    console.log(data);

    //TODO add the code to get the requested info like above but for customerValues
    // pull out the segmented customers. then do the same for the markets and follow the
    // mock up as possible.
    const holdTwo = await this.props.onFetchCustomer(this.props.token, this.props.userId);
    const dataCustomer = this.props.customerData.map(elem => {
      return { ...elem.customerData, timestamp: elem.timestamp };
    });
    //console.log("dataCustomer:");
    //console.log(dataCustomer);

    var customersHolder;
    // the below logic gets the current customr data;
    if (dataCustomer.length !== 0) {
      customersHolder = dataCustomer[0];
    }
    // console.log("Holder:");
    // console.log(customersHolder);
    for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
      //console.log(index + " " + dataCustomer[index]);
      if (customersHolder.timestamp < dataCustomer[index].timestamp) {
        customersHolder = dataCustomer[index];
      }
    }
    // console.log("customerHolder: ");
    // console.log(customersHolder);
    var currentCustomers = [];

    currentCustomers.push(customersHolder.primaryTargetCustomer);
    currentCustomers.push(customersHolder.secondaryTargetCustomer);

    const holdOness = await this.props.onFetchMarket(this.props.token, this.props.userId);
    const dataMarket = this.props.marketData.map(elem => {
      return { ...elem.marketInfo, id: elem.id };
    });
    console.log("market data");
    console.log(dataMarket);

    var markets = [];
    for (var index = 0; dataMarket.length !== index; index++) {
      markets.push(dataMarket[index].marketName)
    }
    // console.log(markets);
    // console.log("HEWLLLLLLO");
    // console.log(data);
    this.setState({
      ...this.state,
      dataNeeds: data,
      markets: markets,
      currentCustomers: currentCustomers,
    });
    console.log(this.state.data);
  }
  handleRowUpdate = (newData, oldData, resolve) => {
    // validation
   
    console.log("Update");
    console.log(oldData);
    const errorList = [];
    if (newData.needs === undefined) {
      errorList.push("Cost name cannot be empty");
    }
    // if (newData.valuesOf1 === undefined) {
    //     errorList.push("Supplier cannot be empty");
    //   }
    // if (newData.valuesO2 === undefined) {
    //     errorList.push("Base Cost Unit cannot be emptyyyyy");
    //   }

    if (errorList.length < 1) {

      let toServer = {
        dataValues: {
          ...newData,
          markets: this.state.selectedMarkets,
        },
        userId: this.props.userId,
      };
      // delete the default index given by material-table
      delete toServer.dataValues["tableData"];
      // we just want to keep the costId locally
      delete toServer.dataValues["costId"];
      delete toServer.dataValues["id"];
      const costId = oldData.id;
      console.log(oldData);
      this.props.onUpdateNeed(costId, toServer, this.props.token);
      const dataUpdate = [...this.state.data];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      this.setState({
        data: [...dataUpdate],
        errorMessages: [],
        isError: false
      });
      resolve();
    }
  };

  handleRowAdd = async (newData, resolve) => {
    // validation
    console.log("add")
    const errorList = [];
    if (newData.needs === undefined) {
      errorList.push("Cost name cannot be empty");
    }
    // if (newData.valuesOf1 === undefined) {
    //   errorList.push("Supplier cannot be empty");
    // }
    // if (newData.valuesOf2 === undefined) {
    //   errorList.push("Base Cost Unit cannot be emdddddpty");
    // }


    if (errorList.length < 1) {
      // no error
      // calculate the cost per 1;
      let toServer = {
        dataValues: {
          ...newData,
          markets: this.state.selectedMarkets,
        },
        userId: this.props.userId,
      };
      delete toServer.dataValues["tableData"];
      await this.props.onSubmitNeed(toServer, this.props.token);
      this.setState({
        data: this.props.data.map(elem => {
          return { ...elem.dataValues, id: elem.id };
        }),
        errorMessages: [],
        isError: false
      });

      resolve();
    } else {
      this.setState({
        errorMessages: errorList,
        isError: true
      });
      resolve();
    }
  };

  handleRowDelete = async (oldData, resolve) => {
    const costId = oldData.id;

    await this.props.onDeleteNeed(costId, this.props.token); // this will update the data inside the redux store
    const dataDelete = [...this.state.data]; // this will update the data in the state
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    this.setState({
      data: [...dataDelete],
      errorMessages: [],
      isError: false
    });

    resolve();
  };

  onChangedMulti = (event) => {
    var newData = [];
    // The following loop adds only the needs wiht the matching markets selected by the user
    // To do look into to faster method since it runs in O(n^2) time
    console.log("lloookkopok");
    console.log(this.state.dataNeeds);
    for (var indexOfNeeds = 0; this.state.dataNeeds.length !== indexOfNeeds; indexOfNeeds++) {
      for (var indexOfEvent = 0; event.length !== indexOfEvent; indexOfEvent++) {
        if (this.state.dataNeeds[indexOfNeeds].dataValues.markets.includes(event[indexOfEvent])) {
          newData.push(this.state.dataNeeds[indexOfNeeds].dataValues);
          indexOfEvent = event.length - 1;
        }
      }
    }
    this.setState({
      ...this.state,
      selectedMarkets: event,
      data: newData,
    });
    console.log(this.state.data);
    console.log(newData);
  }


  onChangedMultiCustomers = (event) => {
    
    this.setState({
      ...this.state,
      selectedCustomers: event,
    });

  }

  onClickHandler = (event) => {
    this.setState({
      ...this.state,
      openModal: true,
    });
  }
  render() {
    var columns = [
      { title: "Cost Id", field: "id", hidden: true },
      { title: "Needs", field: "needs" },
      { title: "Benefits" , field: "benefit" },
    ];

    // var columns = [
    //   { title: "Cost Id", field: "userId", hidden: true },
    //   { title: "Needs", field: "needs" },
    // ];
    // // this populates the rest of the columns with the rest of the customer segments, regardless
    // // how many there is.
    // for (var index = 0; index !== this.state.selectedCustomers.length; index++) {
    //   var tempHolder = {
    //     title: this.state.selectedCustomers[index],
    //     field: this.state.selectedCustomers[index],
    //   }
    //   columns.push(tempHolder);
    // }


    // NOTE: VERY IMPORTANT STEP!
    // create a copy of data to pass into material-table
    const tempData = this.state.data.map(x => Object.assign({}, x));
    return (

      <React.Fragment>
        <Container>
      <div>
        
        {/* <Grid container spacing={1}>
              <Grid item xs={3}>
                <LeftPannel />
              </Grid>
              <Grid item xs={6}> */}  <Container2>
          <div>
            <h1>Customer Benefits</h1>
            <Row>
              <p>You've indicated the needs that your primary customers have in the market. So lets start by 
                  defining what Benefits you will offer to address those needs defined.</p>
            </Row>

            <Row>
              
          
              <Col>
              <Tooltip title={<h5>Benefit is a loose term that allows you to think how you will serve your Customer 
                  and it's unmet need. It is more the "How" your solution will solve their need, more than with "What" 
                  features
              </h5>} arrow>
                        <button className='btn  btn-primary '>By benefits...</button>
                        </Tooltip>
              </Col>
              <Col>
              <Tooltip title={ <h3> If your need (as the customer) is to be able to set prices, one Benefit of nDexio 
                        is to help you build a pricing framework in a structured way to help you set 
                        them. 
              </h3>} arrow>
                        <a  className='btn  btn-primary '>Example</a>
                        </Tooltip>
              </Col>

              </Row>
              <Row>
            
              
                <img 
                style={{ width: "45%" }}
                alt="centered image"
                src={needs_triangle}/>
            
              </Row>

              <Row>

              <Tooltip 
              title={<h5> A Strong value driver (100) means the Customer would put very little thought about buying a product 
                              addressing this need; price would not be top of mind.
                              A Weak value driver (0) means that, while stated as a potential need,   
                              a product or service addressing it would not, on it's own, drive them to make the purchase or pay for it.

                         </h5>}arrow>
                        <p className=' btn '>Once you select your markets, How strong a Value driver do you believe each of these needs are to you target customer(s)</p>
                        </Tooltip>
              </Row>

            <Row>
              <Col>

                <h2>Select market(s)</h2>

                <Multiselect
                  id='extraContinents'
                  className='form-control'
                  sele
                  onChange={this.onChangedMulti}
                  data={this.state.markets}
                />
              </Col>
              <Col>

                                    <h2>Select customer segment(s)</h2>  

                                        <Multiselect
                                        id='extraContinents'
                                        className='form-control'
                                        sele
                                        onChange={this.onChangedMultiCustomers}
                                        data={this.state.currentCustomers}
                                        />


                                </Col>
            </Row>


          </div>
          <hr/>
          <div>
            {this.state.isError && (
              <Alert severity="error">
                {this.state.errorMessages.map((msg, i) => {
                  return <div key={i}>{msg}</div>;
                })}
              </Alert>
            )}
          </div>
          <MaterialTable
            title="Customer-Needs from remote source"
            columns={columns}
            data={tempData}
            icons={tableIcons}
            hidden={false}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise(resolve => {
                  this.handleRowUpdate(newData, oldData, resolve);
                }),
              onRowAdd: newData =>
                new Promise(resolve => {
                  this.handleRowAdd(newData, resolve);
                }),
              onRowDelete: oldData =>
                new Promise(resolve => {
                  this.handleRowDelete(oldData, resolve);
                })
            }}
          />
          {/* </Grid>
              <Grid item xs={3}>
                  <RightPannel/>
              </Grid>
            </Grid> */}


        </Container2>

     
      </div>
      </Container>
      </React.Fragment>





    );
  }


}



const mapStateToProps = state => {
  return {
    data: state.customerNeeds.data,
    customerData: state.customerNeeds.customerData,
    marketData: state.customerNeeds.marketData,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
    finished: state.data.finished,
    costId: state.data.costid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchNeed: (token, userId) => dispatch(actions.fetchNeed(token, userId)),
    onFetchCustomer: (token, userId) => dispatch(actions.fetchCustomer(token, userId)),
    onFetchMarket: (token, userId) => dispatch(actions.fetchMarketForCustomerNeeds(token, userId)),
    onSubmitNeed: (data, token) => dispatch(actions.submitNeed(data, token)),
    onUpdateNeed: (costId, data, token) =>
      dispatch(actions.updateNeed(costId, data, token)),
    onDeleteNeed: (costId, token) => dispatch(actions.deleteNeed(costId, token))
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(benefits)
);