import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Container from '../../hoc/container';
import Button from 'react-bootstrap/Button';
import Edit from '@material-ui/icons/Edit';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import * as actions from '../../store/actions/index';

class MarTable extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchPrimaryMarket(this.props.token, this.props.userId);
    this.props.onFetchData(this.props.token, this.props.userId);
  }
  state = {
    data: [],
    isError: false,
    errorMessages: [],
    showAddModal: false,
    showDeleteMessage: false,
    showEditModal: false,
    marketName: '',
    marketDescription: '',
    selectingMarket: null,
    primary: '', // id of primary market in marketValue database
    primaryId: '', // id of primary market in primaryMarketValue database
  };

  componentWillReceiveProps(nextProps) {
    const data = nextProps.marketData.map((elem) => {
      return { ...elem.marketInfo, marketId: elem.id };
    });
    this.setState({
      data: data,
      primary: nextProps.primaryMarket,
      primaryId: nextProps.primaryId,
    });
  }

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // handle submit new market
  handleSubmitData = async (e) => {
    // e.preventDefault();
    const { marketName, marketDescription } = this.state;

    // new data that will be save to database
    const newData = {
      marketInfo: { marketName, marketDescription },
      userId: this.props.userId,
    };

    // dispatch action to submit new data to db
    await this.props.onSubmitData(newData, this.props.token);

    this.setState({
      data: this.props.marketData.map((elem) => {
        return { ...elem.marketInfo, marketId: elem.id };
      }),
      errorMessages: [],
      isError: false,
      showAddModal: false,
    });
  };

  // handle user deleting a single market
  handleDeleteData = async (id) => {
    await this.props.onDeleteData(id, this.props.token);

    // remove deleted data from the current data array
    const dataDelete = this.state.data.filter(
      (market) => market.marketId !== id
    );

    this.setState({
      data: [...dataDelete],
      showDeleteMessage: false,
      selectingMarket: null,
    });
  };

  // handle user editing a single market
  handleEditData = async (oldData) => {
    // id of the data that is edited
    const { marketId } = oldData;

    // new data after editing
    const newData = {
      ...oldData.marketInfo,
      // if the users don't change marketName, keep the current market name
      marketName: this.state.marketName
        ? this.state.marketName
        : oldData.marketName,
      // if the users don't change marketDescription, keep the current market description
      marketDescription: this.state.marketDescription
        ? this.state.marketDescription
        : oldData.marketDescription,
    };

    // the new data that will be sent to the database for updating
    let toServer = {
      marketInfo: { ...newData },
      userId: this.props.userId,
    };

    // dispatch an action to update the data
    await this.props.onUpdateData(marketId, toServer, this.props.token);


    // if user edit the primary market
    // update primary market data in primaryMarketValue in database
    if (marketId === this.state.primary) {
      const primaryMarket = { ...toServer, marketId: marketId };
      await this.props.updatePrimaryMarket(
        this.state.primaryId,
        primaryMarket,
        this.props.token
      );
    }

    this.setState({
      showEditModal: false,
      marketName: '',
      marketDescription: '',
      selectingMarket: null,
    });
  };

  // handle  user change primary market
  handleOptionChange = async (e) => {
    const newPrimaryId = e.target.value;
    const primaryId = this.state.primaryId;
    // find market with new primary id
    const newPrimaryMarket = this.state.data.filter(
      (market) => market.marketId === newPrimaryId
    )[0];

    // data that will be store in database
    const toServer = {
      marketId: newPrimaryMarket.marketId,
      marketInfo: {
        marketName: newPrimaryMarket.marketName,
        marketDescription: newPrimaryMarket.marketDescription,
      },
      userId: this.props.userId,
    };

    // update the primaryMarketValue in database with new data
    await this.props.updatePrimaryMarket(primaryId, toServer, this.props.token);

    // selecting new market in the table
    this.setState({ primary: primaryId });
  };

  // delete button on click
  onDelete = (market) => {
    this.setState({ showDeleteMessage: true, selectingMarket: market });
  };

  // edit button on click
  onEdit = (market) => {
    this.setState({ showEditModal: true, selectingMarket: market });
  };

  renderMarkets = (market, index) => {
    return (
      <tr key={market.marketId}>
        <td>{market.marketName}</td>
        <td>{market.marketDescription}</td>
        <td>
          <input
            type='radio'
            name='primary'
            value={market.marketId}
            checked={this.state.primary === market.marketId}
            onChange={this.handleOptionChange}
          />
        </td>
        <td>
          <Button
            variant='outline-dark'
            size='sm'
            onClick={() => this.onEdit(market)}
          >
            <Edit />
          </Button>
          <Button
            variant='outline-dark'
            size='sm'
            onClick={() => this.onDelete(market)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };

  render() {
    // console.log(this.state);
    return (
      <Container>
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th>Market</th>
              <th>Short Description</th>
              <th>Primary</th>
              <th id='action'>Action</th>
            </tr>
          </thead>
          <tbody>{this.state.data.map(this.renderMarkets)}</tbody>
        </Table>
        <Button
          variant='primary'
          onClick={() => {
            this.setState({ showAddModal: true });
          }}
        >
          Add Market
        </Button>

        {/* handle add button */}
        {this.state.showAddModal ? (
          <Modal
            show={this.state.showAddModal}
            onHide={() => {
              this.setState({ showAddModal: false });
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Add Market</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <label>Market name: </label>
                <input
                  type='text'
                  placeholder='Enter your market name'
                  name='marketName'
                  onChange={this.handleInputChange}
                />
                <br />
                <label>Add description: </label>
                <input
                  type='text'
                  placeholder='Enter your market description'
                  name='marketDescription'
                  onChange={this.handleInputChange}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  this.setState({ showAddModal: false });
                }}
              >
                Close
              </Button>
              <Button variant='primary' onClick={this.handleSubmitData}>
                Add
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {/* handle edit button */}
        {this.state.showEditModal ? (
          <Modal
            show={this.state.showEditModal}
            onHide={() => {
              this.setState({ showEditModal: false });
            }}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Market</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <label>Edit market name: </label>
                <input
                  type='text'
                  placeholder='Enter your market name'
                  name='marketName'
                  onChange={this.handleInputChange}
                  defaultValue={this.state.selectingMarket.marketName}
                />
                <br />
                <label>Edit market description: </label>
                <input
                  type='text'
                  placeholder='Enter your market description'
                  name='marketDescription'
                  onChange={this.handleInputChange}
                  defaultValue={this.state.selectingMarket.marketDescription}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  this.setState({ showEditModal: false });
                }}
              >
                Close
              </Button>
              <Button
                variant='primary'
                onClick={() => this.handleEditData(this.state.selectingMarket)}
              >
                Edit
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {/* handle delete button */}
        {this.state.showDeleteMessage ? (
          <Modal
            show={this.state.showDeleteMessage}
            onHide={() => {
              this.setState({ showDeleteMessage: false });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning!!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this market?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  this.setState({ showDeleteMessage: false });
                }}
              >
                Close
              </Button>
              <Button
                variant='danger'
                onClick={() =>
                  this.handleDeleteData(this.state.selectingMarket.marketId)
                }
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  return {
    marketData: state.market.marketData,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
    finished: state.market.finished,
    primaryMarket: state.market.primaryMarket,
    primaryId: state.market.primaryId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) =>
      dispatch(actions.fetchMarket(token, userId)),
    onSubmitData: (data, token) =>
      dispatch(actions.submitMarketInfo(data, token)),
    onDeleteData: (marketId, token) =>
      dispatch(actions.deleteMarket(marketId, token)),
    onUpdateData: (marketId, data, token) =>
      dispatch(actions.updateMarket(marketId, data, token)),
    onFetchPrimaryMarket: (token, userId) =>
      dispatch(actions.fetchPrimaryMarket(token, userId)),
    updatePrimaryMarket: (marketId, data, token) => {
      dispatch(actions.updatePrimaryMarket(marketId, data, token));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarTable)
);
