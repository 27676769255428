import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
// This serves as the default value of Geo-Values. If this is seen then act as if
// the sure skiped the Geo-Info page
// OnOffs are used to keep track of what sections the user choose to skip, in the
// order of continent, country, city, and location
// ChangeInPrices keeps track of any changes in price, in the same order as OnOffs
// 'na' means the user did not fill in this feild.

const initialState = {
	BaseUnit: '',
	comsumable: '',
	listOfProducts: [],
	didFinish: false,
};

const ADD_BASEUNIT_INFOMATION = (state, action) => {
	console.log('perfectly');

	return updateObject(state, {
		BaseUnit: action.BaseUnit,
		comsumable: action.comsumable,
	});
};

const fetchlistOfProductsSuccess = (state, action) => {
	return updateObject(state, {
		listOfProducts: action.listOfProducts,
	});
};

const reducer = (state = initialState, action) => {
	// console.log('redux works');
	switch (action.type) {
		case actionTypes.ADD_BASEUNIT_INFOMATION:
			return ADD_BASEUNIT_INFOMATION(state, action);
		case actionTypes.FETCH_listOfProducts_SUCCESS:
			return fetchlistOfProductsSuccess(state, action);
		default:
			return state;
	}
};

export default reducer;
