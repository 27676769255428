import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonGroup, Row } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import Modal from "react-bootstrap/Modal";
import Container from "../../hoc/container"
// utilities
// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file enables the user to enter productServiceName-info one step at a time 
// Related files: store/actions/productServiceName.js, store/reducers/productServiceName.js
// productServiceName and related files are called in index.js and App.js
// Note: this file assumes the user is logged in to be used.
class productServiceInfo extends Component {

	// Opens controls the modal for a new product/service

    state = {
        mainProductSeriveName: "",
        description: "",
        primarily: "",
        newOrComing: "",
        newOrComingName: "",
		newPrimarily: "",
		open: false,
	}
	
	//Updates the state to the user input

    onChangedHandler = (event) =>{
        this.setState({
            ...this.state,
		    [event.target.id]: event.target.value,
         });
    }
	
	
	// Saves the current state
    onSubmitHandler = (event) =>{
        event.preventDefault();
    
       // console.log(this.props.token);
        this.props.ADD_PS_INFOMATION(
            this.state.mainProductSeriveName,
            this.state.description,
            this.state.primarily,
            this.state.newOrComing,
            this.state.newOrComingName,
            this.state.newPrimarily,
        );
        console.log("Saved data");
        const newData = {
            psData:{
                ...this.state,
            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
       this.props.onSubmitPS(newData, this.props.token);
	}
	
	//Opens the modal for the new product/service
	onClickToggleOpen = (event) =>{
		//event.preventDefault();
		if(event.target.id === "toOpen"){
			this.setState({
				...this.state,
				open: true,
				newOrComing: true,
			})
			return;
		}

		this.setState({
			...this.state,
			open: false,
			newOrComing: false,
		})

	}
	openModal = () =>{
		this.setState({
			...this.state,
			open: true,
		});
	}
	handleCloseModal = () =>{
		this.setState({
			...this.state,
			open:false,
		});
	}

    render() {
		return (
			<React.Fragment>
				<Container>
			<div className={'text-center'}>
				<form className='form-signin' >
					<div className='text-center mb-4'>
						<h1 className='h3 mb-3 font-weight-normal'>Product/Service Information</h1>
					</div>

					<div className='form-label-group'>
                    
						<input
							type='text'
							id='mainProductSeriveName'
							className='form-control'
							placeholder='Main Product or Service name:'
							required
							autoFocus
							onChange={this.onChangedHandler}
						/>
						<label htmlFor='mainProductSeriveName'>Main Product or Service name:</label>
					</div>

					<div className='form-label-group'>
						<input
							type='text'
							id='description'
							className='form-control'
							placeholder='How would you describe it, in a few words?'
							required
							onChange={this.onChangedHandler}
						/>
						<label htmlFor='description'>How would you describe it, in a few words?</label>
					</div>

					<div className='form-label-group'>
						<select
							id='primarily'
							className='form-control'
							onChange={this.onChangedHandler}
							defaultValue='default'>
							<option value='default' disabled>
                            I consider it to be primarily a
							</option>
							<option>
								Product
							</option>
							<option>
                                Service							
                            </option>
						</select>
					</div>

                    <Row>
					<div className='form-label-group'>
                        <div>
                        <p className='h3 mb-3 font-weight-normal'>Do you have, or do you expect to have, an extra Product or Service (esp. in next 12 months)?</p>
                        </div>

						<ButtonGroup size="sm">
                                    <Button  id="toOpen" onClick={this.openModal}>Yes</Button>
                                    <Button  id="toClose"> No</Button>
                        </ButtonGroup>


						{this.state.open ? (
            <Modal
              show={this.state.open}
              onHide={this.handleCloseModal}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                	
					<div className='form-signin'>
                    	<div>
                        <p className='h3 mb-3 font-weight-normal'>What is the name for the product or service</p>
                        </div>

					<input
						type='text'
						id='newOrComingName'
						className='form-control'
						placeholder='Please provide a name for it:'
						required
						autoFocus
						onChange={this.onChangedHandler}
					/>
					{/* <label htmlFor='newOrComingName'>Please provide a name for it:</label> */}
					
				

				
				<select
						id='newPrimarily'
						className='form-control'
						onChange={this.onChangedHandler}
						defaultValue='default'>
						<option value='default' disabled>
							and it is a ...
						</option>
						<option>
							Product
						</option>
						<option>
							Service							
						</option>
					</select>
			
						<ButtonGroup size="sm">
                                    <Button  id="toCloseFromModal" onClick={this.onClickToggleOpen}>Done</Button>
                        </ButtonGroup>
					</div>
					</form>
           
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

					
                        {/* <label htmlFor="newOrComing"> Do you have, or do you expect to have additional Products and Services (esp. in next 12 months)?</label> */}
					</div>
                    </Row>
                    
					<button className='btn btn-lg btn-primary btn-block' onClick={this.onSubmitHandler}>
						Continue
					</button>
				</form>
			</div>
			</Container>
			</React.Fragment>
		);
	}


}

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId,
		isAuthenticated: state.auth.token !== null,
		token: state.auth.token,
		finished: state.data.finished,
	};
};

// mainProductSeriveName: "",
// description: "",
// primarily: "",
// newOrComing: "",
// newOrComingName: "",
// newPrimarily: "",
const mapDispathToProps = (dispatch) => {
	return {
        ADD_PS_INFOMATION: (mainProductSeriveName, description, primarily, newOrComing, newOrComingName, newPrimarily) =>
        dispatch(
            actions.ADD_PS_INFOMATION(
                mainProductSeriveName,      
                description,
                primarily,
                newOrComing,
                newOrComingName,
                newPrimarily,
            )
        ),
        onSubmitPS: (data, token) => dispatch(actions.submitPS(data, token)),
    };
};


export default withRouter(
    connect( mapStateToProps, mapDispathToProps )(productServiceInfo)
);
