import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import PriceSensitivity from "./PriceSensitivity";

class Introduction extends Component {
  state = {
    showIntro: true,
    showPrompt1: false,
    showPrompt2: false,
    showPrice: false,
  };


  render() {
    console.log(this.state);
    if (this.state.showIntro) {
      return (
        <Jumbotron>
          <h1>What do we mean by Price sensitivity?</h1>
          <p>
            Simply put, it is the degree to which a given customer group will
            react to price. This includes their price recall, how their
            behaviour is affected by the price point, what will happen if you
            price changes of different degrees,...
          </p>
          <p>
            <Button
              variant="primary"
              onClick={() => {
                this.setState({ showIntro: false, showPrompt1: true });
              }}
            >
              Learn more
            </Button>
          </p>
        </Jumbotron>
      );
    } else if (this.state.showPrompt1) {
      return (
        <Jumbotron>
          <div>
            <p>
              Price sensitivity is important to make decisions in regards to
              your pricing. However, it is also the most difficult variable to
              define. Moreover, it is not something you define once, and can
              rely on afterwards. It is specific to each product, each feature,
              each channel, how it was communicated, sold. It changes due to a
              wide variety of factors and may change as soon as you, or your
              competitors, changed their prices.
            </p>
            <p>
              As such, the key is to both get an understanding of what is means,
              and review it regularly.
            </p>
          </div>
          <p>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                this.setState({ showIntro: true, showPrompt1: false});
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                this.setState({
                  showIntro: false,
                  showPrompt1: false,
                  showPrompt2: true,
                });
              }}
            >
              Next
            </Button>
          </p>
        </Jumbotron>
      );
    } else if (this.state.showPrompt2) {
      return (
        <Jumbotron>
          <div>
            <p>
              For now, we will keep it very simple. Later, you will be able to
              define it more granularly as well as integrate quantitative
              customer research insights.
            </p>
            <p>
              If you already conducted quantitative research on my customers,
              please directly enter a value for price elasticity. Otherwise,
              lets go through 2 exercises:
            </p>
          </div>
          <p>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                this.setState({ showPrompt1: true, showPrompt2: false });
              }}
            >
              Back
            </Button>
            <Button
              variant="primary"
              size="md"
              onClick={() => {
                this.setState({
                  showIntro: false,
                  showPrompt1: false,
                  showPrompt2: false,
                  showPrice: true,
                });
              }}
            >
              Next
            </Button>
          </p>
        </Jumbotron>
      );
    } else {
      return <PriceSensitivity />;
    }
  }
}

export default withRouter(Introduction);
