import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Container from 'react-bootstrap/Container';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import PrimaryCompetitor from './PrimaryCompetitor';

class Information extends Component {
  state = {
    next: false,
  };
  render() {
    if (!this.state.next) {
      return (
        <Jumbotron>
          <Container>
            <div>
              <p>
                Your Market has Sellers, which we'll refer to as potential
                Competitors.
              </p>
              <p>
                They may be much stronger or much weaker; they may not be direct
                competitors but will still compete with you on a proportion of
                your business (and may later target a larger proportion of it,
                but you will face some in most markets.
              </p>
              <p>
                If you believe there is no named competitor, you may still
                compete with the lack of an existing demand i.e. potential
                customers do not know they have a need for your product; it
                needs to be explained ; they need to be convinced they have such
                a need.
              </p>
              <p>
                For example, the first eReader competed with normal books and
                the resistance from buyers to switch to eReader. As such, a
                Competitor may be "Books" in this case.
              </p>
              <Button
                onClick={() => {
                  this.setState({ next: true });
                }}
                variant='success'
              >
                Next
              </Button>
            </div>
          </Container>
        </Jumbotron>
      );
    } else {
      return <PrimaryCompetitor />;
    }
  }
}

export default withRouter(Information);
