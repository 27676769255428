import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";

export const submitFeatureInfo = (newData, token) => {
  //console.log(newData);
  return async (dispatch) => {
    await axios
      .post("/featureValue.json?auth=" + token, newData)
      .then((response) => {
        dispatch(submitFeatureSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitFeatureFail(error));
      });
  };
};

export const submitFeatureSuccess = (id, featureData) => {
  return {
    type: actionTypes.SUBMIT_FEATURE_SUCCESS,
    featureId: id,
    feature: featureData,
  };
};

export const submitFeatureFail = (error) => {
  return {
    type: actionTypes.SUBMIT_FEATURE_FAIL,
    error: error,
  };
};

export const startSubmitFeature = () => {
  return {
    type: actionTypes.START_SUBMIT_FEATURE,
  };
};

export const fetchFeature = (token, userId) => {
  return (dispatch) => {
    const queryParams = "?auth=" + token + "&userId=" + userId;
    axios.get("/featureValue.json" + queryParams).then((response) => {
      const fetchedData = [];
      for (let key in response.data) {
        fetchedData.push({ ...response.data[key], id: key });
      }
      console.log(fetchedData);
      dispatch(fetchFeatureSuccess(fetchedData));
    });
  };
};

export const fetchFeatureSuccess = (fetchedData) => {
  //console.log("KKKKKKKKKK");
  //console.log(fetchedData);
  return {
    type: actionTypes.FETCH_FEATURE_SUCCESS,
    featureList: fetchedData,
  };
};

export const deleteFeature = (featureId, token) => {
  //console.log(featureId);
  return async (dispatch) => {
    await axios
      .delete(`/featureValue/${featureId}.json?auth=` + token)
      .then((response) => {
        dispatch(deleteFeatureSuccess(featureId));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(deleteFeatureFail(error));
      });
  };
};

export const deleteFeatureSuccess = (id) => {
  return {
    type: actionTypes.DELETE_FEATURE_SUCCESS,
    featureId: id,
  };
};

export const deleteFeatureFail = (error) => {
  return {
    type: actionTypes.DELETE_FEATURE_FAIL,
    error: error,
  };
};

export const updateFeature = (featureId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(`/featureValue/${featureId}.json?auth=` + token, newData)
      .then((response) => {
        dispatch(updateFeatureSuccess(featureId, newData));
      })
      .catch((error) => {
        dispatch(updateFeatureFail(error));
      });
  };
};

export const updateFeatureSuccess = (id, featureData) => {
  return {
    type: actionTypes.UPDATE_FEATURE_SUCCESS,
    featureId: id,
    feature: featureData,
  };
};

export const updateFeatureFail = (error) => {
  return {
    type: actionTypes.UPDATE_FEATURE_FAIL,
    error: error,
  };
};

// export const updatePrimaryCompetitor = (newPrimary) => {
//   console.log(newPrimary);
//   localStorage.setItem("primaryCompetitor", newPrimary);
//   return {
//     type: actionTypes.UPDATE_PRIMARY_COMPETITOR,
//     newPrimaryCompetitorId: newPrimary,
//   };
// };

