import React, { Component } from 'react';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './unmetNeed.css';

import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Container from '../../hoc/container';
import Button from 'react-bootstrap/Button';
import Edit from '@material-ui/icons/Edit';

class UnmeetNeed extends Component {
  state = {
    primaryMarketData: null,
    primaryMarketId: '',
    markets: [],
    // all customerNeeds
    customerNeeds: [],
    fetchDone: false,
    selectMarket: this.props.primaryMarketData.marketName,
    // customerNeeds for a current market
    needForCurrentMarket: [],
  };

  componentDidMount() {
    this.props.onFetchData(this.props.token, this.props.userId);
    this.props.onFetchCustomerNeeds(this.props.token, this.props.userId);
    this.props.onFetchUnmetNeed(this.props.token, this.props.userId);
  }

  handleClose = () => {
    this.props.history.push('/');
  };

  handleSelectMarket = (e) => {
    const needForCurrentMarket = this.state.customerNeeds.filter((need) => {
      // console.log(need);
      return need.markets.includes(e.target.value);
    });
    this.props.onFetchUnmetNeed(this.props.token, this.props.userId);

    this.setState({
      selectMarket: e.target.value,
      needForCurrentMarket: needForCurrentMarket,
    });
  };

  // handleEdit = () => {
  //   console.log('Handle edit');
  // };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e, key) => {
    e.preventDefault();
    const newPercent = e.target.value;
    const newData = [...this.state.needForCurrentMarket];
    let isSubmitData = true;

    if (this._timeOut) {
      clearTimeout(this._timeOut);
    }
    this._timeOut = setTimeout(() => {
      newData.forEach((element, indx) => {
        if (element.needId === key) {
          if (element.percent) {
            isSubmitData = false;
          }
          element.percent = newPercent;
        }
      });
      var newInfo = newData.filter((elem) => elem.needId === key)[0];
      var toServer = {
        userId: this.props.userId,
        unmetInfo: {
          ...newInfo,
        },
      };

      if (isSubmitData) {
        this.props.onSubmitNeed(toServer, this.props.token);
      } else {
        // this.handleEdit()
        var ID = toServer.unmetInfo.id;
        delete toServer.unmetInfo.id;
        // console.log(ID);
        this.props.onUpdateUnmetNeed(ID, toServer, this.props.token);
      }
      this.setState({ needForCurrentMarket: newData });
    }, 1000);
  };

  selectMarkets = () => {
    return (
      <Form.Group>
        <Form.Control
          as='select'
          size='lg'
          onChange={this.handleSelectMarket}
          defaultValue={this.state.primaryMarketData.marketName}
        >
          {this.state.markets.map((market) => {
            return (
              <option key={market.marketId} value={market.marketName}>
                {market.marketName}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  renderCustomerNeeds = (need, index) => {
    return (
      <tr key={need.needId}>
        <td>{need.needs}</td>
        <td>
          <input
            type='text'
            name='percent'
            defaultValue={need.percent}
            onChange={(e) => {
              this.handleChange(e, need.needId);
            }}
          />
        </td>

        <td>
          <Button variant='outline-dark' size='sm' onClick={this.handleEdit}>
            <Edit />
          </Button>
        </td>
      </tr>
    );
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    const markets = nextProps.marketData.map((elem) => {
      return { ...elem.marketInfo, marketId: elem.id };
    });

    // markets is now in dataValues
    // find all customer needs
    const customerNeeds = nextProps.customerNeeds.map((elem) => {
      return {
        ...elem.dataValues,
        // markets: [...elem.markets],
        needId: elem.id,
      };
    });

    if (nextProps.unmetNeedData) {
    }
    // markets is now in dataValues, so need.dataValues.market for need[index].dataValues.market
    //  find all needs for current selected market
    const needForCurrentMarket = nextProps.customerNeeds
      .filter((need) => {
        return need.dataValues.markets.includes(this.state.selectMarket);
      })
      .map((elem) => {
        return {
          ...elem.dataValues,
          // markets: [...elem.markets],
          needId: elem.id,
          percent: null,
        };
      });

    const unmetNeed = nextProps.unmetNeedData
      .filter((need) => {
        // console.log(need);
        return need.unmetInfo.markets.includes(this.state.selectMarket);
      })
      .map((elem) => {
        return {
          unmetInfo: elem.unmetInfo,
          //markets: [...elem.markets],
          //needId: elem.needId,
          //percent: elem.percent,
          id: elem.id,
        };
      });

    for (let i = 0; i !== needForCurrentMarket.length; i++) {
      for (let j = 0; j !== unmetNeed.length; j++) {
        if (undefined !== unmetNeed[j].unmetInfo) {
          // for the edge case of the user changing market with no data
          if (needForCurrentMarket[i].needs === unmetNeed[j].unmetInfo.needs) {
            needForCurrentMarket[i].percent = unmetNeed[j].unmetInfo.percent;
            needForCurrentMarket[i].id = unmetNeed[j].unmetInfo.id;
          }
        }
      }
    }

    this.setState({
      primaryMarketData: nextProps.primaryMarketData,
      primaryMarketId: nextProps.primaryMarketId,
      markets: markets,
      customerNeeds: customerNeeds,
      needForCurrentMarket: needForCurrentMarket,
      fetchDone: true,
    });
  }

  render() {
    // console.log(this.state);
    if (!this.state.fetchDone) {
      return (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      );
    } else {
      // if users haven't set up needs ==> show alert to ask them to set up customer need first
      if (this.state.customerNeeds.length === 0) {
        return (
          <Container>
            <Alert variant='warning'>
              Please set up{' '}
              <Alert.Link href='/customerNeeds'>Customer Needs</Alert.Link>{' '}
              first!!
            </Alert>
            {this.selectMarkets()}
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>Need</th>
                  <th>Percent</th>
                  <th id='action'>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.needForCurrentMarket.map(this.renderCustomerNeeds)}
              </tbody>
            </Table>
          </Container>
        );
      } else {
        return (
          <Container>
            {this.selectMarkets()}
            <Table striped bordered hover size='sm'>
              <thead>
                <tr>
                  <th>Need</th>
                  <th>Percent</th>
                  <th id='action'>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.needForCurrentMarket.map(this.renderCustomerNeeds)}
              </tbody>
            </Table>
          </Container>
        );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  // console.log(state);
  // console.log(ownProps);
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    marketData: state.market.marketData,
    isAuthenticated: state.auth.token !== null,
    primaryMarketData: ownProps.primaryMarketData,
    primaryMarketId: ownProps.primaryMarketId,
    customerNeeds: state.customerNeeds.data,
    unmetNeedData: state.unmetNeed.unmetData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) => {
      dispatch(actions.fetchMarket(token, userId));
    },
    onFetchPrimaryMarket: (token, userId) => {
      dispatch(actions.fetchPrimaryMarket(token, userId));
    },
    onFetchCustomerNeeds: (token, userId) => {
      dispatch(actions.fetchNeed(token, userId));
    },
    onSubmitNeed: (data, token) => {
      dispatch(actions.submitUnmetNeed(data, token));
    },
    onFetchUnmetNeed: (token, userId) => {
      dispatch(actions.fetchUnmetNeed(token, userId));
    },
    onUpdateUnmetNeed: (unmetId, data, token) => {
      dispatch(actions.updateUnmetNeed(unmetId, data, token));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnmeetNeed)
);
