// Created by: Quang Nguyen
import React, { Component } from "react";

import { forwardRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import Alert from "@material-ui/lab/Alert";
import * as actions from "../../store/actions/index";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

class CostTable extends Component {
  state = {
    data: [],
    isError: false,
    errorMessages: [],
  };

  async componentDidMount() {
    // fetch data from database

    await this.props.onFetchData(this.props.token, this.props.userId);
    const data = this.props.data.map((elem) => {
      return { ...elem.dataValues, costId: elem.id };
    });
    this.setState({
      data: data,
    });
    console.log(this.state.data);
  }

  handleRowUpdate = (newData, oldData, resolve) => {
    // validation
    const errorList = [];
    if (newData.costName === undefined) {
      errorList.push("Cost name cannot be empty");
    }
    if (newData.supplier === undefined) {
      errorList.push("Supplier cannot be empty");
    }
    if (newData.baseCostUnit === undefined) {
      errorList.push("Base Cost Unit cannot be empty");
    }
    if (newData.unitPrice === undefined) {
      errorList.push("Unit Price cannot be empty");
    }
    if (newData.qtyPerOne === undefined) {
      errorList.push("Quantity Per One ___ cannot be empty");
    }

    if (errorList.length < 1) {
      newData["costPerOne"] = newData.unitPrice * newData.qtyPerOne;

      let toServer = {
        dataValues: {
          ...newData,
        },
        userId: this.props.userId,
      };
      // delete the default index given by material-table
      delete toServer.dataValues["tableData"];
      // we just want to keep the costId locally
      delete toServer.dataValues["costId"];
      const costId = oldData.costId;
      this.props.onUpdateData(costId, toServer, this.props.token);
      const dataUpdate = [...this.state.data];
      const index = oldData.tableData.id;
      dataUpdate[index] = newData;
      this.setState({
        data: [...dataUpdate],
        errorMessages: [],
        isError: false,
      });
      resolve();
    }
  };

  handleRowAdd = async (newData, resolve) => {
    // validation
    const errorList = [];
    if (newData.costName === undefined) {
      errorList.push("Cost name cannot be empty");
    }
    if (newData.supplier === undefined) {
      errorList.push("Supplier cannot be empty");
    }
    if (newData.baseCostUnit === undefined) {
      errorList.push("Base Cost Unit cannot be empty");
    }
    if (newData.unitPrice === undefined) {
      errorList.push("Unit Price cannot be empty");
    }
    if (newData.qtyPerOne === undefined) {
      errorList.push("Quantity Per One ___ cannot be empty");
    }

    if (errorList.length < 1) {
      // no error
      // calculate the cost per 1
      newData["costPerOne"] = newData.unitPrice * newData.qtyPerOne;
      let toServer = {
        dataValues: {
          ...newData,
        },
        userId: this.props.userId,
      };
      delete toServer.dataValues["tableData"];
      await this.props.onSubmitData(toServer, this.props.token);
      this.setState({
        data: this.props.data.map((elem) => {
          return { ...elem.dataValues, costId: elem.id };
        }),
        errorMessages: [],
        isError: false,
      });
      // resolve();
    } else {
      this.setState({
        errorMessages: errorList,
        isError: true,
      });
      // resolve();
    }
  };

  handleRowDelete = async (oldData, resolve) => {
    const costId = oldData.costId;

    await this.props.onDeleteData(costId, this.props.token); // this will update the data inside the redux store
    const dataDelete = [...this.state.data]; // this will update the data in the state
    const index = oldData.tableData.id;
    dataDelete.splice(index, 1);
    this.setState({
      data: [...dataDelete],
      errorMessages: [],
      isError: false,
    });
    resolve();
  };

  render() {
    const columns = [
      { title: "Cost Id", field: "costId", hidden: true },
      { title: "Cost name", field: "costName" },
      { title: "Supplier", field: "supplier" },
      { title: "Base cost unit", field: "baseCostUnit" },
      { title: "Unit price", field: "unitPrice" },
      { title: "Quantity per 1 ___ of ___ ", field: "qtyPerOne" },
      {
        title: "Cost per 1 ___ of ___ ",
        field: "costPerOne",
        editable: "never",
        emptyValue: "-",
      },
    ];
    // NOTE: VERY IMPORTANT STEP!
    // create a copy of data to pass into material-table
    const tempData = this.state.data.map((x) => Object.assign({}, x));
    return (
      <div>
        <div>
          {this.state.isError && (
            <Alert severity="error">
              {this.state.errorMessages.map((msg, i) => {
                return <div key={i}>{msg}</div>;
              })}
            </Alert>
          )}
        </div>
        <MaterialTable
          title="User data from remote source"
          columns={columns}
          data={tempData}
          icons={tableIcons}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                this.handleRowUpdate(newData, oldData, resolve);
              }),
            onRowAdd: (newData) =>
              new Promise((resolve) => {
                this.handleRowAdd(newData, resolve);
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve) => {
                this.handleRowDelete(oldData, resolve);
              }),
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.data.data,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
    finished: state.data.finished,
    costId: state.data.costid,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) => dispatch(actions.fetchData(token, userId)),
    onSubmitData: (data, token) => dispatch(actions.submitData(data, token)),
    onUpdateData: (costId, data, token) =>
      dispatch(actions.updateData(costId, data, token)),
    onDeleteData: (costId, token) =>
      dispatch(actions.deleteData(costId, token)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CostTable)
);
