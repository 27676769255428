// Created by: Quang Nguyen
import React, { Component, useState } from "react"; // useState for sidebar functionality
import { connect } from "react-redux";
import Nav from "react-bootstrap/Nav";  // Includes Nav.Link and Nav.Item
import NavDropdown from "react-bootstrap/NavDropdown";
import { withRouter } from "react-router-dom";
import classes from "./navigationItems.module.css";
import Collapse from 'react-bootstrap/Collapse'; // Add Collapse functionality to sidebar - not yet implemented fully

function NavigationItem(props){

  // Declare a new state variable called "open"
  const [open, setOpen] = useState(false);

  return (
    <> {/* Container to wrap Collapsible Nav */}
      <Nav
        className={classes.middle}
        activeKey="/"
        onSelect={(selectedKey) => props.history.push(selectedKey)} // Takes selectedKey from clicked element and saves into history as child node to be used when render middle element of frame
      >
        <Nav defaultActiveKey="/home" className="flex-column">
          <Nav.Link eventKey="home">Home</Nav.Link>
          <Nav.Link eventKey="home">Introduction</Nav.Link>
          <NavDropdown title="Base input" id="basic-nav-dropdown" onClick={() => setOpen(!open)}> {/* Dropdown Base inputs */}
            <Nav.Link eventKey="companyprofile">Company Profile</Nav.Link>
            <Nav.Link eventKey="howItWorks">How it works?</Nav.Link>
            <Nav.Link eventKey="priority">Priority</Nav.Link>
            <Nav.Link eventKey="cost">Cost</Nav.Link>
            <Nav.Link eventKey="productOrService">Product or Service</Nav.Link>
            <Nav.Link eventKey="baseUnit">Base Unit</Nav.Link>
            <Nav.Link eventKey="geo">Geo Info</Nav.Link>
            <Nav.Link eventKey="market">Market</Nav.Link>
            <Nav.Link eventKey="customer">Customer Segmentation</Nav.Link>
            <Nav.Link eventKey="customerNeeds">Customer Needs</Nav.Link>
            <Nav.Link eventKey="benefits">Customer Benefits</Nav.Link>
            <Nav.Link eventKey="competition">Competition</Nav.Link>
            <Nav.Link eventKey="unmetNeed">Unmet Need</Nav.Link>
            <Nav.Link eventKey="priceSensitivity">Price Sensitivity</Nav.Link>
            <Nav.Link eventKey="features">Features</Nav.Link>
            <Nav.Link eventKey="featuresPower">Features' Power</Nav.Link>
            <Nav.Link eventKey="featuresVersion">Features' Versions</Nav.Link>
          </NavDropdown>
          <NavDropdown title="nDex" id="basic-nav-dropdown"> {/* Dropdown nDex inputs */}
            <Nav.Link eventKey="needsBasednDex">Needs-Based nDex</Nav.Link>
          </NavDropdown>
          <NavDropdown title="Prices" id="basic-nav-dropdown"> {/* Dropdown Price outputs */}
            <Nav.Link eventKey="valueBasednDex">Value-Based Price</Nav.Link>
            <Nav.Link eventKey="valueCustomerBasednDex">Customer-Based Price</Nav.Link>
            <Nav.Link eventKey="valueVersionBasednDex">Version-Based Price</Nav.Link>
          </NavDropdown>
        </Nav>
      </Nav>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default withRouter(connect(mapStateToProps)(NavigationItem));
