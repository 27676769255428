import React from 'react';
import './UserCurrSeg.css';

const UserCurrSegment = () => {
	return (
		<div className='current_segment'>
			User current segment Country:USA • Customer group:Group1 •
			Product:Product1 • Time Period: All
		</div>
	);
};

export default UserCurrSegment;
