import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-instance';

export const ADD_BASEUNIT_INFOMATION = (BaseUnit, consumable) => {
	return {
		type: 'ADD_BASEUNIT_INFORMATION',
		BaseUnit: BaseUnit,
		consumable: consumable,
	};
};

export const submitBaseUnit = (newData, token) => {
	return async (dispatch) => {
		await axios
			.post('/baseUnitValue.json?auth=' + token, newData)
			.then((response) => {
				dispatch(submitBaseUnitSuccess(response.data.name, newData));
			})
			.catch((error) => {
				dispatch(submitBaseUnitFail(error));
			});
	};
};

export const submitBaseUnitSuccess = (id, psData) => {
	return {
		type: actionTypes.SUBMIT_DATA_SUCCESS,
		geoId: id,
		psData: psData,
	};
};

export const submitBaseUnitFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error,
	};
};

export const fetchListOfProductsSuccess = (listOfProducts) => {
	console.log(listOfProducts);
	return {
		type: actionTypes.FETCH_listOfProducts_SUCCESS,
		listOfProducts: listOfProducts,
	};
};

export const fetchListOfProducts = (token, userId) => {
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/proServValue.json' + queryParams);
		const fetchedData = [];
		console.log(response);
		//let variableCost = 0;
		for (let key in response.data) {
			console.log(key);
			//variableCost += Number(response.data[key].dataValues['costPerOne']);
			fetchedData.push({
				...response.data[key],
				//	variableCost: variableCost * 1,
				id: key,
			});
		}

		console.log(fetchedData);

		dispatch(fetchListOfProductsSuccess(fetchedData));
	};
};
