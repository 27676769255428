import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { deleteNeedFail } from '../actions/customerNeeds';
const initialState = {
	data: [],
	customerData: [],
	marketData: [],
	finished: false,
};

const updateNeedSuccess = (state, action) => {
	let index = -1;
	const data = [...state.data];
	const newData = updateObject(action.needData, { id: action.userId });
	for (let elem of data) {
		if (elem.id === action.userId) {
			index = data.indexOf(elem);
			break;
		}
	}
	if (index !== -1) {
		data[index] = newData;
	}
	return updateObject(state, {
		finished: true,
		data: data,
	});
};

const deleteNeedSuccess = (state, action) => {
	let index = -1;
	const data = [...state.data];
	for (let elem of data) {
		if (elem.id === action.userId) {
			index = data.indexOf(elem);
			break;
		}
	}
	if (index !== -1) {
		data.splice(index, 1);
	}

	return updateObject(state, {
		finished: true,
		data: data,
	});
};

const submitNeedSuccess = (state, action) => {
	const newData = updateObject(action.data, { id: action.needId });
	return updateObject(state, {
		finished: true,
		data: state.data.concat(newData),
	});
};

const startSubmitNeed = (state, action) => {
	return updateObject(state, {
		finished: false,
	});
};

const fetchNeedSuccess = (state, action) => {
	// console.log(action.data);
	return updateObject(state, {
		data: action.data,
	});
};

const fetchCustomerSuccess = (state, action) => {
    console.log(action.customerData);
	return updateObject(state, {
		customerData: action.customerData,
	});
};

const fetchMarketSuccess = (state, action) => {
    console.log(action.customerData);
	return updateObject(state, {
		marketData: action.marketData,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_NEED_SUCCESS:
			return fetchNeedSuccess(state, action);
		case actionTypes.FETCH_CUSTOMER_SUCCESS:
			return fetchCustomerSuccess(state, action);
		case actionTypes.FETCH_MARKET_SUCCESS:
			return fetchMarketSuccess(state, action);
		case actionTypes.START_SUBMIT_NEED:
			return startSubmitNeed(state, action);
		case actionTypes.SUBMIT_NEED_SUCCESS:
			return submitNeedSuccess(state, action);
		case actionTypes.UPDATE_NEED_SUCCESS:
			return updateNeedSuccess(state, action);
		case actionTypes.DELETE_NEED_SUCCESS:
			return deleteNeedSuccess(state, action);
		case actionTypes.DELETE_NEED_FAIL:
			return deleteNeedFail(state, action);
		default:
			return state;
	}
};

export default reducer;
