import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  marketData: [],
  finished: false,
  primaryMarket: '', // id of primary market stored in marketValue
  primaryId: '', // id of primary market stored in primaryMarketValue
  primaryMarketData: null,
  error: null,
};

// ***********************************************
// Primary market
// CRUD operators for primary market
// ***********************************************

// updating primary market success
const updatePrimaryMarketSuccess = (state, action) => {
  return updateObject(state, {
    finished: true,
    primaryId: action.primaryId,
    primaryMarket: action.marketData.marketId,
  });
};

// updating primary market fail
const updatePrimaryMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// submit primary market fail
const submitPrimaryMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// submit primary market success
const submitPrimaryMarketSuccess = (state, action) => {
  // console.log(action);
  return updateObject(state, {
    finished: true,
    primaryMarket: action.marketData.marketId,
    primaryId: action.primaryId,
  });
};

// fetch primary market success
const fetchPrimaryMarketSuccess = (state, action) => {
  return updateObject(state, {
    primaryMarket: action.marketData.marketId,
    primaryId: action.primaryId,
    primaryMarketData: { ...action.marketData.marketInfo },
    finished: true,
  });
};

// fetch primary market fail
const fetchPrimaryMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// ***********************************************
//  Markets
// CRUD operators for markets
// ***********************************************

// start submiting market
const startSubmitMarket = (state, action) => {
  return updateObject(state, {
    finished: false,
  });
};

// submit market success
const submitMarketSuccess = (state, action) => {
  // console.log(action);
  const newData = updateObject(action.marketData, { id: action.marketId });
  return updateObject(state, {
    finished: true,
    marketData: state.marketData.concat(newData),
  });
};

// submit market fail
const submitMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// fetch market success
const fetchMarketSuccess = (state, action) => {
  return updateObject(state, {
    marketData: action.marketData,
    finished: true,
  });
};

// delete market success
const deleteMarketSuccess = (state, action) => {
  let index = -1;
  const data = [...state.marketData];
  for (let elem of data) {
    if (elem.id === action.marketId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data.splice(index, 1);
  }

  return updateObject(state, {
    finished: true,
    marketData: data,
  });
};

// delete market fail
const deleteMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// update market success
const updateMarketSuccess = (state, action) => {
  let index = -1;
  const data = [...state.marketData];
  const newData = updateObject(action.marketData, { id: action.marketId });
  for (let elem of data) {
    // find the deleting element
    if (elem.id === action.marketId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data[index] = newData;
  }
  return updateObject(state, {
    finished: true,
    marketData: data,
  });
};

// update market fail
const updateMarketFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MARKET_SUCCESS:
      return fetchMarketSuccess(state, action);
    case actionTypes.START_SUBMIT_MARKET:
      return startSubmitMarket(state, action);
    case actionTypes.SUBMIT_MARKET_SUCCESS:
      return submitMarketSuccess(state, action);
    case actionTypes.SUBMIT_MARKET_FAIL:
      return submitMarketFail(state, action);
    case actionTypes.DELETE_MARKET_SUCCESS:
      return deleteMarketSuccess(state, action);
    case actionTypes.DELETE_MARKET_FAIL:
      return deleteMarketFail(state, action);
    case actionTypes.UPDATE_MARKET_SUCCESS:
      return updateMarketSuccess(state, action);
    case actionTypes.UPDATE_MARKET_FAIL:
      return updateMarketFail(state, action);
    case actionTypes.UPDATE_PRIMARY_MARKET_SUCCESS:
      return updatePrimaryMarketSuccess(state, action);
    case actionTypes.UPDATE_PRIMARY_MARKET_FAIL:
      return updatePrimaryMarketFail(state, action);
    case actionTypes.SUBMIT_PRIMARY_MARKET_SUCCESS:
      return submitPrimaryMarketSuccess(state, action);
    case actionTypes.SUBMIT_PRIMARY_MARKET_FAIL:
      return submitPrimaryMarketFail(state, action);
    case actionTypes.FETCH_PRIMARY_MARKET_SUCCESS:
      return fetchPrimaryMarketSuccess(state, action);
    case actionTypes.FETCH_PRIMARY_MARKET_FAIL:
      return fetchPrimaryMarketFail(state, action);
    default:
      return state;
  }
};

export default reducer;
