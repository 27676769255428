import React from 'react';
import './LeftPannel.css';
import Menu from './Menu';

const LeftPannel = () => {
	return (
		<div className='left_pannel'>
			{/* <div className='company_logo'>Company Logo</div> */} {/* Commented out at this point of time until fully setup */}
			<Menu />
		</div>
	);
};

export default LeftPannel;
