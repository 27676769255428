import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import CompetitionTable from './CompetitionTable';
import Modal from 'react-bootstrap/Modal';

class SecondaryCompetitor extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchCompetitor(this.props.token, this.props.userId);

    // get primary competitor 
    const primaryCompetitor = this.props.competitors[0];
    if (primaryCompetitor) {
      const toServer = {
        competitorInfo: primaryCompetitor.competitorInfo,
        competitorId: primaryCompetitor.id,
        userId: this.props.userId,
      };

      // submit primary competitor
      this.props.submitPrimaryCompetitor(toServer, this.props.token);
    }
  }
  state = {
    competitorName: '',
    skip: false,
    data: [],
    fetchDone: false,
    primaryCompetitorId: '',
  };

  componentWillReceiveProps(nextProps) {
    // get list of competitors
    const data = nextProps.competitors.map((elem) => {
      return { ...elem.competitorInfo, competitorId: elem.id };
    });

    this.setState({
      data: data,
      primaryCompetitorId: nextProps.competitors[0].id,
      fetchDone: true,
    });
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { competitorName } = this.state;
    const newData = {
      competitorInfo: { competitorName },
      userId: this.props.userId,
    };
    // submit secondary competitor
    await this.props.onSubmitCompetitor(newData, this.props.token);

    this.setState({
      data: newData,
      skip: true,
      // primaryCompetitorId: this.props.competitors[0].id,
    });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleOptionChange = (e) => {
    e.preventDefault();
    this.setState({
      skip: true,
      primaryCompetitorId: this.props.competitors[0].id,
    });
  };

  handleClose = () => {
    this.props.history.push('/');
  };

  render() {
    // console.log(this.state);
    if (!this.state.fetchDone) {
      return <div>Loading</div>;
    } else {
      if (!this.state.skip) {
        return (
          <Modal show={!this.state.skip} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Who is your secondary competitor?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <label>Secondary competitor: </label>
                <input
                  type='text'
                  placeholder='Enter your secondary competitor'
                  name='competitorName'
                  onChange={this.handleInputChange}
                />
              </form>
              <div>
                <input
                  type='checkbox'
                  checked={this.state.skip}
                  onChange={this.handleOptionChange}
                />
                No additional competitor
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' onClick={this.handleSubmit}>
                Show table
              </Button>
            </Modal.Footer>
          </Modal>
        );
      } else {
        return <CompetitionTable />;
      }
    }
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    competitors: state.competitor.competitors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitCompetitor: (data, token) => {
      dispatch(actions.submitCompetitorInfo(data, token));
    },
    onFetchCompetitor: (token, userId) => {
      dispatch(actions.fetchCompetitor(token, userId));
    },
    updatePrimaryCompetitor: (primaryCompetitorId, token) => {
      dispatch(actions.updatePrimaryCompetitor(primaryCompetitorId));
    },
    submitPrimaryCompetitor: (data, token) => {
      dispatch(actions.submitPrimaryCompetitor(data, token));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SecondaryCompetitor)
);
