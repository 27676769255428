import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import CostTable from "./CostTable";
import IntroductionModal from "./IntroductionModal";
import Container from "../../hoc/container";

class Profile extends Component {
  state = {
    fixedCost: "",
    fixedCostElements: "",
    show: true,
  };

  onChangedHandler = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  onSubmitHandler = (event) => {
    event.preventDefault();
    let submitData = {
      ...this.state,
    };
    // this.props.onSubmitCompanyData(this.props.token, submitData)
  };

  hideModal = () => {
    this.setState({ show: false });
  };
  showModal = () => {
    this.setState({ show: true });
    console.log(this.state.show);
  };

  render() {
    return (
      <React.Fragment>
        <Container>
          <IntroductionModal show={this.state.show} onHide={this.hideModal} />

          <div className="container h-100 mt-5">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <h2>Fixed Costs</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xs-offset-3">
                <form
                  id="company-profile-form"
                  className="form"
                  onSubmit={this.onSubmitHandler}
                >
                  <div className="form-group mt-4">
                    <label className="form-label" htmlFor="fixedCost">
                      How much do your full year fixed costs amount to?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fixedCost"
                      name="fixedCost"
                      tabIndex="2"
                      onChange={this.onChangedHandler}
                      required
                    />
                  </div>

                  <div className="form-group mt-5">
                    <label className="form-label" htmlFor="fixedCostElements">
                      What elements do you consider as part of your fixed costs?
                      (Please list, dividing by commas [,] - this can be used
                      later to refine the assumptions)
                    </label>
                    <textarea
                      className="form-control"
                      id="fixedCostElements"
                      rows="3"
                      onChange={this.onChangedHandler}
                      required
                    ></textarea>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn btn-start-order">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <h2>Variable Costs</h2>

            <CostTable />
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    userId: state.auth.userId,
    userData: state.aboutMe,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUserData: (token, userId) =>
      dispatch(actions.fetchUserData(token, userId)),
    onSubmitCompanyData: (token, companyData) =>
      dispatch(actions.submitCompanyData(token, companyData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
