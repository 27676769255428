import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";

// submit unmet need data
export const submitUnmetNeed = (newData, token) => {
  return async (dispatch) => {
    await axios
      .post("/unmetValue.json?auth=" + token, newData)
      .then((response) => {
        dispatch(submitUnmetSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitUnmetFail(error));
      });
  };
};

// submit unmet need data success
export const submitUnmetSuccess = (id, unmetData) => {
  return {
    type: actionTypes.SUBMIT_UNMET_SUCCESS,
    unmetId: id,
    unmetData: unmetData,
  };
};

// submit unmet need data fail
export const submitUnmetFail = (error) => {
  return {
    type: actionTypes.SUBMIT_UNMET_FAIL,
    error: error,
  };
};

// fetch unmet need data
export const fetchUnmetNeed = (token, userId) => {
  return (dispatch) => {
    const queryParams = "?auth=" + token + "&userId=" + userId;
    axios.get("/unmetValue.json" + queryParams).then((response) => {
      const fetchedData = [];
      for (let key in response.data) {
        fetchedData.push({ ...response.data[key], id: key });
      }
      dispatch(fetchUnmetNeedSuccess(fetchedData));
    });
  };
};

// fetch unmet need data success
export const fetchUnmetNeedSuccess = (unmetData) => {
  return {
    type: actionTypes.FETCH_UNMETNEED_SUCCESS,
    unmetData: unmetData,
  };
};

// update unmet need data success
export const updateUnmetNeedSuccess = (id, unmetData) => {
  return {
    type: actionTypes.UPDATE_UNMETNEED_SUCCESS,
    unmetId: id,
    unmetData: unmetData,
  };
};

// update unmet need data fail
export const updateUnmetNeedFail = (error) => {
  return {
    type: actionTypes.UPDATE_UNMETNEED_FAIL,
    error: error,
  };
};

// update unmet need data
export const updateUnmetNeed = (unmetId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(`/unmetValue/${unmetId}.json?auth=` + token, newData)
      .then((response) => {
        dispatch(updateUnmetNeedSuccess(unmetId, newData));
      })
      .catch((error) => {
        dispatch(updateUnmetNeedFail(error));
      });
  };
};
