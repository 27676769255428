import * as actionTypes from './actionTypes';
import axios from '../../axios-instance';

// ***********************************************
// Competitors
// CRUD operators for competitors
// ***********************************************

// submit single competitor
export const submitCompetitorInfo = (newData, token) => {
  console.log(newData);
  return async (dispatch) => {
    await axios
      .post('/competitorValue.json?auth=' + token, newData)
      .then((response) => {
        dispatch(submitCompetitorSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitCompetitorFail(error));
      });
  };
};

// submit single competitor success
export const submitCompetitorSuccess = (id, competitorData) => {
  return {
    type: actionTypes.SUBMIT_COMPETITOR_SUCCESS,
    competitorId: id,
    competitors: competitorData,
  };
};

// submit single competitor fail
export const submitCompetitorFail = (error) => {
  return {
    type: actionTypes.SUBMIT_COMPETITOR_FAIL,
    error: error,
  };
};

// start submitting single competitor
export const startSubmitCompetitor = () => {
  return {
    type: actionTypes.START_SUBMIT_COMPETITOR,
  };
};

// fetch all competitors
export const fetchCompetitor = (token, userId) => {
  return (dispatch) => {
    const queryParams = '?auth=' + token + '&userId=' + userId;
    axios.get('/competitorValue.json' + queryParams).then((response) => {
      const fetchedData = [];
      for (let key in response.data) {
        fetchedData.push({ ...response.data[key], id: key });
      }
      dispatch(fetchCompetitorSuccess(fetchedData));
    });
  };
};

// fetch all competitors success
export const fetchCompetitorSuccess = (competitorData) => {
  console.log(competitorData);
  return {
    type: actionTypes.FETCH_COMPETITOR_SUCCESS,
    competitors: competitorData,
  };
};

// delete single competitor
export const deleteCompetitor = (competitorId, token) => {
  console.log(competitorId);
  return async (dispatch) => {
    await axios
      .delete(`/competitorValue/${competitorId}.json?auth=` + token)
      .then((response) => {
        dispatch(deleteCompetitorSuccess(competitorId));
      })
      .catch((error) => {
        // console.log(error);
        dispatch(deleteCompetitorFail(error));
      });
  };
};

// delete single competitor success
export const deleteCompetitorSuccess = (id) => {
  return {
    type: actionTypes.DELETE_COMPETITOR_SUCCESS,
    competitorId: id,
  };
};

// delete single competitor fail
export const deleteCompetitorFail = (error) => {
  return {
    type: actionTypes.DELETE_COMPETITOR_FAIL,
    error: error,
  };
};

// update single competitor
export const updateCompetitor = (competitorId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(`/competitorValue/${competitorId}.json?auth=` + token, newData)
      .then((response) => {
        dispatch(updateCompetitorSuccess(competitorId, newData));
      })
      .catch((error) => {
        dispatch(updateCompetitorFail(error));
      });
  };
};

// update single competitor success
export const updateCompetitorSuccess = (id, competitorData) => {
  return {
    type: actionTypes.UPDATE_COMPETITOR_SUCCESS,
    competitorId: id,
    competitors: competitorData,
  };
};

// update single competitor fail
export const updateCompetitorFail = (error) => {
  return {
    type: actionTypes.UPDATE_COMPETITOR_FAIL,
    error: error,
  };
};

// ***********************************************
// Primary competitors
// CRUD operators for primary competitors
// ***********************************************

// update primary competitor
export const updatePrimaryCompetitor = (competitorId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(
        `/primaryCompetitorValue/${competitorId}.json?auth=` + token,
        newData
      )
      .then((response) => {
        dispatch(updatePrimaryCompetitorSuccess(competitorId, newData));
      })
      .catch((error) => {
        dispatch(updatePrimaryCompetitorFail(error));
      });
  };
};

// update primary competitor success
export const updatePrimaryCompetitorSuccess = (id, competitorData) => {
  return {
    type: actionTypes.UPDATE_PRIMARY_COMPETITOR_SUCCESS,
    primaryId: id,
    competitorData: competitorData,
  };
};

// update primary competitor fail
export const updatePrimaryCompetitorFail = (error) => {
  return {
    type: actionTypes.UPDATE_PRIMARY_COMPETITOR_FAIL,
    error: error,
  };
};

// submit primary competitor
export const submitPrimaryCompetitor = (newData, token) => {
  return async (dispatch) => {
    await axios
      .post('/primaryCompetitorValue.json?auth=' + token, newData)
      .then((response) => {
        dispatch(submitPrimaryCompetitorSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitPrimaryCompetitorFail(error));
      });
  };
};

// submit primary competitor success
export const submitPrimaryCompetitorSuccess = (id, competitorData) => {
  console.log(id);
  console.log(competitorData);
  return {
    type: actionTypes.SUBMIT_PRIMARY_COMPETITOR_SUCCESS,
    primaryId: id,
    competitorData: competitorData,
  };
};

// submit primary competitor fail
export const submitPrimaryCompetitorFail = (error) => {
  return {
    type: actionTypes.SUBMIT_PRIMARY_COMPETITOR_FAIL,
    error: error,
  };
};

// fetch primary competitor
export const fetchPrimaryCompetitor = (token, userId) => {
  return async (dispatch) => {
    const queryParams =
      '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
    const response = await axios.get(
      '/primaryCompetitorValue.json' + queryParams
    );
    // console.log(response);

    const fetchedData = [];
    for (let key in response.data) {
      fetchedData.push({ ...response.data[key], id: key });
    }
    if (fetchedData.length !== 0) {
      dispatch(fetchPrimaryCompetitorSuccess(fetchedData));
    } else {
      const error = 'Empty primary competitor';
      dispatch(fetchPrimaryCompetitorFail(error));
    }
  };
};

// fetch primary competitor success
export const fetchPrimaryCompetitorSuccess = (competitorData) => {
  console.log(competitorData);
  const data = competitorData[0];
  const competitorInfo = { ...data.competitorInfo };
  const competitorId = data.competitorId;
  return {
    type: actionTypes.FETCH_PRIMARY_COMPETITOR_SUCCESS,
    competitorData: { competitorId, competitorInfo },
    primaryId: data.id,
  };
};
// fetch primary competitor fail
export const fetchPrimaryCompetitorFail = (error) => {
  return {
    type: actionTypes.FETCH_PRIMARY_COMPETITOR_FAIL,
    error: error,
  };
};
