import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import SecondaryCompetitor from './SecondaryCompetitor';
import Modal from 'react-bootstrap/Modal';
import CompetitionTable from './CompetitionTable';
import Spinner from 'react-bootstrap/Spinner';

class PrimaryCompetitor extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchCompetitor(this.props.token, this.props.userId);
  }
  state = {
    showAlert: false,
    competitorName: '',
    primary: true,
    secondary: false,
    data: [],
    fetchDone: false,
    showTable: false,
  };

  handleOptionChange = (e) => {
    this.setState({ showAlert: !this.state.showAlert });
  };

  onClose = () => {
    this.setState({ showAlert: false });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { competitorName } = this.state;
    const newData = {
      competitorInfo: { competitorName },
      userId: this.props.userId,
    };
    await this.props.onSubmitCompetitor(newData, this.props.token);


    this.setState({ primary: false, secondary: true });
  };

  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClose = () => {
    this.props.history.push('/');
  };

  componentWillReceiveProps(nextProps) {
    const data = nextProps.competitors.map((elem) => {
      return { ...elem.competitorInfo, competitorId: elem.id };
    });
    if (!nextProps.finished) {
      return;
    }
    // if already have data => show table
    if (data.length) {
      this.setState({
        data: data,
        fetchDone: true,
        showTable: true,
        primary: false,
      });
    } else {
      this.setState({
        data: data,
        fetchDone: true,
        showTable: false,
        primary: true,
      });
    }
  }

  render() {
    // console.log(this.state);
    if (!this.state.fetchDone) {
      return (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      );
    } else {
      if (this.state.primary && !this.state.showTable) {
        return (
          <>
            <Modal show={this.state.primary} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Who is your primary Competitor?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.showAlert ? (
                  <Alert variant='warning' onClose={this.onClose} dismissible>
                    Are you sure? A competitor may not be a business but an
                    alternative need that competes with your [product /
                    service]?
                  </Alert>
                ) : null}
                <form>
                  <label>Primary competitor: </label>
                  <input
                    type='text'
                    placeholder='Enter your competitor'
                    name='competitorName'
                    onChange={this.handleInputChange}
                  />
                </form>
                <div>
                  <input
                    type='checkbox'
                    checked={this.state.showAlert}
                    onChange={this.handleOptionChange}
                  />
                  We do not have any competitors
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={this.handleSubmit}>
                  Next
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        );
      } else if (this.state.secondary) {
        return <SecondaryCompetitor />;
      } else {
        return <CompetitionTable />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    competitors: state.competitor.competitors,
    finished: state.competitor.finished,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onSubmitCompetitor: (data, token) => {
      dispatch(actions.submitCompetitorInfo(data, token));
    },
    onFetchCompetitor: (data, token) => {
      dispatch(actions.fetchCompetitor(data, token));
    },
    submitPrimaryCompetitor: (data, token) => {
      dispatch(actions.submitPrimaryCompetitor(data, token));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PrimaryCompetitor)
);
