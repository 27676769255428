import {
	SET_USER,
	SET_AUTHENTICATED,
	SET_UNAUTHENTICATED,
} from '../actions/actionTypes';

const initialState = {
	authenticated: false,
    // todo add user info state
};
export default function (state = initialState, action) {
	switch (action.type) {
		case SET_AUTHENTICATED:
			return {
				...state,
				authenticated: true,
			};
		case SET_UNAUTHENTICATED:
			return initialState;
		case SET_USER:
			return {
				authenticated: true,
				...action.payload,
			};
		default:
			return state;
	}
}
