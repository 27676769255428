// starter code Created by: Quang Nguyen
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Container as Container2, Row, Col } from 'react-bootstrap';
import { Multiselect } from 'react-widgets';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Tooltip } from '@material-ui/core';
import needs_triangle from "../../assets/images/needs_triangle.jpg";
import 'react-widgets/dist/css/react-widgets.css';
import Container from "../../hoc/container";


import * as actions from "../../store/actions/index";
import Market from "../../components/Market/Market";

// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file enables the user to enter customer-needs-info, the table updates with the selection(s) from the
// the drops. The user can add as many needs as they want ( this effectly turns of the given section of MVP )
// Related files: store/actions/customerNeeds.js, store/reducers/customerNeeds.js
// Customer and related files are called in index.js and App.js
// Note: this file assumes the user is logged in to be used.


class customerNeeds extends Component {

  // data holds the information to be printed on screen, 
  // dataNeeds holds all the needs data,
  // all data is sorted to a filter version based on the selction of the user

  state = {
    data: [],
    dataNeeds: [],
    currentCustomers: [],
    markets: [],
    selectedMarkets: [],
    selectedCustomers: [],
    openModal: false,
    openAddModal: false,
    openEditModal: false,
    openDeleteModal: false,
    isError: false,
    tempNeed: "",
    errorMessages: [],
    deleteNeedId: "",
    deleteNeed: "",
  };

  async componentDidMount() {
    // fetchs all data needed from database

    await this.props.onFetchNeed(this.props.token, this.props.userId);
    var data = this.props.data.map(elem => {
      return { ...elem, id: elem.id };
    });
   console.log("needData:")
    console.log(data);
    await this.props.onFetchCustomer(this.props.token, this.props.userId);
    const dataCustomer = this.props.customerData.map(elem => {
      return { ...elem.customerData, timestamp: elem.timestamp };
    });
    

    var customersHolder;
    // the below logic gets the current customr data;
    if (dataCustomer.length !== 0) {
      customersHolder = dataCustomer[0];
    }
    // console.log("Holder:");
    // console.log(customersHolder);
    for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
      //console.log(index + " " + dataCustomer[index]);
      if (customersHolder.timestamp < dataCustomer[index].timestamp) {
        customersHolder = dataCustomer[index];
      }
    }
    // console.log("customerHolder: ");
    // console.log(customersHolder);
    var currentCustomers = [];

    currentCustomers.push(customersHolder.primaryTargetCustomer);
    currentCustomers.push(customersHolder.secondaryTargetCustomer);

    await this.props.onFetchMarket(this.props.token, this.props.userId);
    const dataMarket = this.props.marketData.map(elem => {
      return { ...elem.marketInfo, userId: elem.userId };
    });
    //console.log(dataMarket);
    // the below logic gets the names of the markets the user enter in a prev Section
    var markets = [];
    for (var index = 0; dataMarket.length !== index; index++) {
      markets.push(dataMarket[index].marketName)
    }
    // console.log(markets);
 
    // console.log(data);
    this.setState({
      ...this.state,
      dataNeeds: data,
      markets: markets,
      currentCustomers: currentCustomers,
    });
    //console.log(this.state.data);
  }

  // handleRowUpdate = (newData, oldData, resolve) => {
  //   // TODO validation
  //   // console.log("Update");
  //   // console.log(oldData);
  //   const errorList = [];
  //   if (newData.needs === undefined) {
  //     errorList.push("Needs cannot be empty");
  //   }
  //   // if (newData.valuesOf1 === undefined) {
  //   //     errorList.push("Supplier cannot be empty");
  //   //   }
  //   // if (newData.valuesO2 === undefined) {
  //   //     errorList.push("Base Cost Unit cannot be emptyyyyy");
  //   //   }

  //   if (errorList.length < 1) {

  //     let toServer = {
  //       dataValues: {
  //         ...newData,
  //         benefits: "",
  //       },
  //       userId: this.props.userId,
  //       markets: this.state.selectedMarkets,
  //     };
  //     // delete the default index given by material-table
  //     delete toServer.dataValues["tableData"];
  //     // we just want to keep the costId locally
  //     delete toServer.dataValues["costId"];
  //     const costId = oldData.id;
  //     //console.log(costId);
  //     this.props.onUpdateNeed(costId, toServer, this.props.token);
  //     const dataUpdate = [...this.state.data];
  //     const index = oldData.tableData.id;
  //     dataUpdate[index] = newData;
  //     this.setState({
  //       data: [...dataUpdate],
  //       errorMessages: [],
  //       isError: false
  //     });
  //     resolve();
  //   }
  // };
  handleSubmitData = async (e) => {
      let newData = {
        listOfNeeds: "",
        needs: this.state.tempNeed,
        benefit: "",
        markets: this.state.selectedMarkets,
      }
      let toServer = {
        dataValues: {
          ...newData
        },
        userId: this.props.userId,
      };
      
      await this.props.onSubmitNeed(toServer, this.props.token);
      const dataNeeds = this.props.data.map(elem => {
        return { ...elem, id: elem.id };
      });
      const data = [];

      for (var indexOfNeeds = 0; dataNeeds.length !== indexOfNeeds; indexOfNeeds++) {
        if(dataNeeds[indexOfNeeds].dataValues.markets !== ""){
        for(var indexOfMarkets = 0; dataNeeds[indexOfNeeds].dataValues.markets.length != indexOfMarkets; indexOfMarkets++){
          if (this.state.selectedMarkets.includes(dataNeeds[indexOfNeeds].dataValues.markets[indexOfMarkets])) {
            data.push(dataNeeds[indexOfNeeds]);
            indexOfMarkets = dataNeeds[indexOfNeeds].dataValues.markets.length - 1;
          }
        }
      }
      }      

      this.setState({
        data: data,
        dataNeeds: dataNeeds,
        errorMessages: [],
        isError: false,
        openAddModal: false,
      });
      
  }

  handleDelete = async (e) => {
    var ID = this.state.deleteNeedId;
    await this.props.onDeleteNeed(ID, this.props.token)
    this.setState({
      ...this.state,
      data: this.state.data.filter(function(value, index, arr){ return value.id !== ID;}),
      dataNeeds:this.state.dataNeeds.filter(function(value, index, arr){ return value.id !== ID;}),
      openDeleteModal: false,
    });
  }
  // handleRowAdd = async (newData, resolve) => {
  //   // TODO: validation
  //   //console.log("add")
  //   const errorList = [];
  //   if (newData.needs === undefined) {
  //     errorList.push("Needs cannot be empty");
  //   }
  //   // if (newData.valuesOf1 === undefined) {
  //   //   errorList.push("Supplier cannot be empty");
  //   // }
  //   // if (newData.valuesOf2 === undefined) {
  //   //   errorList.push("Base Cost Unit cannot be emdddddpty");
  //   // }


  //   if (errorList.length < 1) {
  //     // no error
  //     // calculate the cost per 1;
  //     let toServer = {
  //       dataValues: {
  //         ...newData
  //       },
  //       userId: this.props.userId,
  //       markets: this.state.selectedMarkets,
  //     };
  //     delete toServer.dataValues["tableData"];
  //     await this.props.onSubmitNeed(toServer, this.props.token);
  //     this.setState({
  //       data: this.props.data.map(elem => {
  //         return { ...elem.dataValues, costId: elem.id };
  //       }),
  //       errorMessages: [],
  //       isError: false
  //     });

  //     resolve();
  //   } else {
  //     this.setState({
  //       errorMessages: errorList,
  //       isError: true
  //     });
  //     resolve();
  //   }
  // };

  // handleRowDelete = async (oldData, resolve) => {
  //   const costId = oldData.id;

  //   await this.props.onDeleteNeed(costId, this.props.token); // this will update the data inside the redux store
  //   const dataDelete = [...this.state.data]; // this will update the data in the state
  //   const index = oldData.tableData.id;
  //   dataDelete.splice(index, 1);
  //   this.setState({
  //     data: [...dataDelete],
  //     errorMessages: [],
  //     isError: false
  //   });

  //   resolve();
  // };

  onChangedMulti = (event) => {
    var newData = [];
    // The following loop adds only the needs wiht the matching markets selected by the user
    // To do look into to faster method since it runs in O(n^2) time
    console.log("ssssss");
    console.log(this.state.dataNeeds);
    //var toServer = null;
    for (var indexOfNeeds = 0; this.state.dataNeeds.length !== indexOfNeeds; indexOfNeeds++) {
      if(this.state.dataNeeds[indexOfNeeds].dataValues.markets !== ""){
      for(var indexOfMarkets = 0; this.state.dataNeeds[indexOfNeeds].dataValues.markets.length != indexOfMarkets; indexOfMarkets++){
        if (event.includes(this.state.dataNeeds[indexOfNeeds].dataValues.markets[indexOfMarkets])) {
          newData.push(this.state.dataNeeds[indexOfNeeds]);
       //   toServer = this.state.dataNeeds[indexOfNeeds];
          indexOfMarkets = this.state.dataNeeds[indexOfNeeds].dataValues.markets.length - 1;
        }
      }
    }
    }
    // var ID = toServer.id;
    // delete toServer.id;
    // this.props.onUpdateNeed(costId, toServer, this.props.token);
    console.log(newData);
    this.setState({
      ...this.state,
      selectedMarkets: event,
      data: newData,
    });
    // console.log(this.state.data);
    // console.log(newData);
  }


  onChangedMultiCustomers = (event) => {

    this.setState({
      ...this.state,
      selectedCustomers: event,
    });

  }

  onClickHandler = (event) => {
    this.setState({
      ...this.state,
      openModal: true,
    });
  }

  renderHeadTable = (customer, index) => {
    return (
      <th>{customer}</th>
    );
  };

  renderBodyTable = (need, index) => {

    const row = [];
    //console.log("Example");
    //console.log(need);
    row.push(
    <td>{need.dataValues.needs}</td>
    );
    var tempIndex = -1;
    for(var index =0; index !== this.state.selectedCustomers.length; index++){
      for(var indexOfNeeds = 0; indexOfNeeds !== need.dataValues.listOfNeeds.length; indexOfNeeds++){
        if(need.dataValues.listOfNeeds[indexOfNeeds][0] === this.state.selectedCustomers[index]){
          tempIndex = indexOfNeeds;
        }
      }
      if(tempIndex === -1){
        row.push(
        <td><input
        type="text"
        name={this.state.selectedCustomers[index]}
        defaultValue="No value entered"
        onChange={(e) => {
          this.handleChange(e, need.id);
        }}
      /></td>
        );
      }else{
        row.push(
          <td><input
          type="text"
          name={this.state.selectedCustomers[index]}
          defaultValue={need.dataValues.listOfNeeds[tempIndex][1]}
          onChange={(e) => {
            this.handleChange(e, need.id);
          }}
        /></td>
        );
      }

      
    }
    row.push(
      <td>
        <Button variant="primary" 
        onClick={(e) => {
          this.openDeleteModal(e, need.id);
        }}
        >Delete Need</Button>
      </td>
    )
    return (
      <tr key={need.id}>
        {row}
      </tr>
    );
  };
  openAdd = () => {
    this.setState({
      ...this.state,
      openAddModal: true,
    });
  }

  handleClose = () => {
    this.setState({
      ...this.state,
      openAddModal: false,
      openDeleteModal: false,
      openEditModal: false,
    });
  }

  handleInputChange = (e) => {
    //e.preventDefault();
    //console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  // This function sets to states needed to delete a given need, by opening 
  //  the deleteModal and saying the given needId to the state
  openDeleteModal = (e, key) => {
    e.preventDefault();
    var need = "";
    for(var index = 0; index !== this.state.data.length; index++){
      if(key === this.state.data[index].id){
        need = this.state.data[index].dataValues.needs;
        index = this.state.data.length - 1;
      }
    }
    this.setState({
      ...this.state,
      openDeleteModal: true,
      deleteNeedId: key,
      deleteNeed: need,
    });
  }

  handleChange = async (e, key) => {
    e.preventDefault();
     //const newPercent = e.target.value;
     console.log(e.target);
     console.log(key);
     var newData = [...this.state.data];
     var index = 0;
     var flag = true
     for(index = 0; index !== newData.length && flag; index++){
       if(newData[index].id === key){
         flag = false;
         index--;
       }
     }

     console.log(newData[index]);
     if(newData[index].dataValues.listOfNeeds === ""){
       var array = [ e.target.name, e.target.value];
       newData[index].dataValues.listOfNeeds = [];
       console.log(newData[index].dataValues.listOfNeeds);
       newData[index].dataValues.listOfNeeds.push([ e.target.name, e.target.value]);
       console.log(newData[index].dataValues.listOfNeeds);

     }else{
       var found = false;
       console.log(newData[index].dataValues.listOfNeeds.length);
       for(var jndex = 0; jndex !== newData[index].dataValues.listOfNeeds.length; jndex++){
         if(e.target.name === newData[index].dataValues.listOfNeeds[jndex][0]){
           found = true;
           newData[index].dataValues.listOfNeeds[jndex][1] = e.target.value;
         }
       }
       if(!found){
         var array = [ e.target.name, e.target.value];
         newData[index].dataValues.listOfNeeds.push( [...array] );
       }
     }
     console.log(newData[index]);
     var toServer = newData[index];
     //var ID = toServer.id;
     delete toServer.id; 
     this.props.onUpdateNeed(key, toServer, this.props.token);
     newData[index].id = key;
    // const newData = [...this.state.needForCurrentMarket];
    // let isSubmitData = true;

    // if (this._timeOut) {
    //   clearTimeout(this._timeOut);
    // }
    // this._timeOut = setTimeout(() => {
    //   newData.forEach((element, indx) => {
    //     if (element.needId === key) {
    //       if (element.percent) {
    //         isSubmitData = false;
    //       }
    //       element.percent = newPercent;
    //     }
    //   });
    //   const toServer = newData.filter((elem) => elem.needId === key)[0];
    //   if (isSubmitData) {
    //     this.props.onSubmitNeed(toServer, this.props.token);
    //   } else {
    //     // this.handleEdit()
    //     this.props.onUpdateUnmetNeed(toServer.id, toServer, this.props.token);
    //   }
    //   this.setState({ needForCurrentMarket: newData });
    // }, 1000);
    this.setState({
      ...this.state,
      data:newData,
    });
  };

  
  render() {
    // var columns = [
    //   { title: "Cost Id", field: "costId", hidden: true },
    //   { title: "Needs", field: "needs" },
    //   { title: "Values of group1" , field: "valuesOf1" },
    //   { title: "Values of group2", field: "valuesOf2" }, 
    // ];

   

    return (

      <React.Fragment>
        <Container>
      <div>

        {/* <Grid container spacing={1}>
              <Grid item xs={3}>
                <LeftPannel />
              </Grid>
              <Grid item xs={6}> */}  <Container2>
          <div>
            <h1>Customer Needs</h1>
            <Row>
              <p>As defined before, each market addresses needs. What are the Top needs you aim to address (Enter at least 1).".</p>
            </Row>

            <Row>


              <Col>
                <Tooltip title={<h5> We are talking about the basic intrinsic requirements or search that companies have before they
                even become prospective customers. It is present before they are made aware that a solution exists that
                fullfills this need. As such, it is independent to your product & services, their Features, or the Benefits
                those features offer.
                A basic illustration of this can be found in the pyramid in the center
              </h5>} arrow>
                  <button className='btn  btn-primary '>By needs...</button>
                </Tooltip>
              </Col>
              <Col>
                <Tooltip title=" Clicking will take you to further examples of Needs can be found by 
                looking at Maslow's Pyramid of Needs which further classifies basic human needs. 
                        " arrow>
                  <a href="https://en.wikipedia.org/wiki/Maslow%27s_hierarchy_of_needs" className='btn  btn-primary '>For more examples...</a>
                </Tooltip>
              </Col>

            </Row>
            <Row>
            
              
            <img 
            style={{ width: "45%" }}
            alt="centered image"
            src={needs_triangle}/>
        
          </Row>


            <Row>

              <Tooltip
                title={<h5> A Strong value driver (100) means the Customer would put very little thought about buying a product
                addressing this need; price would not be top of mind.
                A Weak value driver (0) means that, while stated as a potential need,
                a product or service addressing it would not, on it's own, drive them to make the purchase or pay for it.

                         </h5>} arrow>
                <p className=' btn '>Once you select your markets, How strong a Value driver do you believe each of these needs are to you target customer(s)</p>
              </Tooltip>
            </Row>

            <Row>
              <Col>

                <h2>Select market(s)</h2>

                <Multiselect
                  id='extraContinents'
                  className='form-control'
                  sele
                  onChange={this.onChangedMulti}
                  data={this.state.markets}
                />
              </Col>
              <Col>

                <h2>Select customer segment(s)</h2>

                <Multiselect
                  id='extraContinents'
                  className='form-control'
                  sele
                  onChange={this.onChangedMultiCustomers}
                  data={this.state.currentCustomers}
                />


              </Col>
            </Row>


          </div>
                  <hr/>
          <Row>
          <Table striped bordered hover size="sm" >
            <thead>
              <tr>
                <th>Needs</th>
                {this.state.selectedCustomers.map(this.renderHeadTable)}
              </tr>
              
            </thead>
            <tbody>{this.state.data.map(this.renderBodyTable)}</tbody>
          </Table>
          </Row>

          <Row>
            <Button onClick={this.openAdd}>Add New Need</Button>
          </Row>
                
          {this.state.openAddModal ? (
            <Modal
              show={this.state.openAddModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Need</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <hr/>
                  <label>What does the customer's need: </label>
                  <input
                    type="text"
                    placeholder="Need"
                    name="tempNeed"
                    onChange={this.handleInputChange}
                  />
                  <hr/>
                  
                
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" 
                 onClick={this.handleSubmitData}
                >
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
     {this.state.openDeleteModal ? (
            <Modal
              show={this.state.openDeleteModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Delete Need</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                  <h2>Are you sure want to delete {this.state.deleteNeed}</h2>
                  <Button
                    onClick={this.handleDelete}
                  >Yes Delete {this.state.deleteNeed} </Button>
                  
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
               
              </Modal.Footer>
            </Modal>
          ) : null}

        </Container2>






      </div>
      </Container>
      </React.Fragment>





    );
  }


}



const mapStateToProps = state => {
  return {
    data: state.customerNeeds.data,
    customerData: state.customerNeeds.customerData,
    marketData: state.customerNeeds.marketData,
    userId: state.auth.userId,
    isAuthenticated: state.auth.token !== null,
    token: state.auth.token,
    finished: state.data.finished,
    costId: state.data.costid
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchNeed: (token, userId) => dispatch(actions.fetchNeed(token, userId)),
    onFetchCustomer: (token, userId) => dispatch(actions.fetchCustomer(token, userId)),
    onFetchMarket: (token, userId) => dispatch(actions.fetchMarketForCustomerNeeds(token, userId)),
    onSubmitNeed: (data, token) => dispatch(actions.submitNeed(data, token)),
    onUpdateNeed: (costId, data, token) =>
      dispatch(actions.updateNeed(costId, data, token)),
    onDeleteNeed: (costId, token) => dispatch(actions.deleteNeed(costId, token))
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(customerNeeds)
);