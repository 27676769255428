import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension'; // Use redux-devtools-extension package from npm instead of chrome extension

// reducers
import userReducer from './reducers/userReducer';
import uiReducer from './reducers/uiReducer';
import authReducer from './reducers/auth';
import dataReducer from './reducers/data';
import aboutMeReducer from './reducers/aboutMe';
import geoReducer from './reducers/geo';
import productServiceInfoReducer from './reducers/productServiceInfo';
import customerReducer from './reducers/customer';
import marketReducer from './reducers/market';
import baseUnitReducer from './reducers/baseUnit';
import customerNeedsReducer from './reducers/customerNeeds';
import competitionReducer from './reducers/competition';
import featureReducer from './reducers/features';
import unmetNeedReducer from "./reducers/unmetNeed"

// const initialState = {};

// const middleware = [thunk];

const rootReducer = combineReducers({
	user: userReducer,
	UI: uiReducer,
	auth: authReducer,
	aboutMe: aboutMeReducer,
	geo: geoReducer,
	productServiceInfo: productServiceInfoReducer,
	customer: customerReducer,
	baseUnit: baseUnitReducer,
	customerNeeds: customerNeedsReducer,
	market: marketReducer,
	competitor: competitionReducer,
	data: dataReducer,
	features: featureReducer,
	unmetNeed: unmetNeedReducer
});

// dev tools middleware
// const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION__() ?
// 	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION__() : compose

// store
const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))	// applies ReduxDevTools in Development mode only
);
// const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
