import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
function ConfirmationModal(props) {
    const { tasks } = props;
	return (
		<Modal
			{...props}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
                    Please confirm your selections
				</Modal.Title>
			</Modal.Header>
            <Modal.Body>

                <h4>Most important</h4>
                    {props.columns['column-2'].taskIds.map(id => (
                        <li key={id}>{tasks[id].content}</li>
                    ))}

                <h4>Not selected</h4>
                    {props.columns['column-1'].taskIds.map(id => (
                        <li key={id}>{tasks[id].content}</li>
                    ))}
			</Modal.Body>
			<Modal.Footer>
				<Button variant='danger' onClick={props.onHide}>Go back</Button>
				<Button onClick={props.onHide}>Continue</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default ConfirmationModal;
