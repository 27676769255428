import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// MUI STUFF
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

// REDUX STUFF
import { connect } from 'react-redux';
import { updateUserInformation } from '../../store/actions/aboutMe'
// import { loginUser } from '../store/actions/userActions';

const styles = (theme) => ({
	inputLeft: {
		textAlign: 'left',
	},
	typography: {
		useNextVariants: true,
	},
	pageTitle: {
		margin: '10px auto 10px auto',
		textAlign: 'center',
	},
	textField: {
		margin: '10px auto 10px auto',
	},
	button: {
		marginTop: 20,
		position: 'relative',
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10,
	},
	progress: {
		position: 'absolute',
	},
});

export class SetupUserInformation extends Component {
	state = {
		companyName: '',
		firstName: '',
		lastName: '',
		goal: '',
		role: '',
		companyType: '',
		errors: {},
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.UI.errors) {
			this.setState({ errors: nextProps.UI.errors });
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const userInformation = {
			companyName: this.state.companyName,
			firstName: this.state.firstName,
			lastName: this.state.lastName,
			goal: this.state.goal,
			role: this.state.role,
			companyType: this.state.companyType,
		};
		this.props.updateUserInformation(userInformation, this.props.history);
	};

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		console.log(this.state);
		const {
			classes,
			UI: { loading },
		} = this.props;
		const { errors } = this.state;
		return (
			<Grid container>
				<Grid item sm />
				<Grid item sm>
					<Typography variant='h2' className={classes.pageTitle}>
						About you
					</Typography>
					<form noValidate onSubmit={this.handleSubmit}>
						<TextField
							id='companyName'
							name='companyName'
							type='companyName'
							label='Company name'
							className={classes.textField}
							helperText={errors.companyName}
							error={errors.companyName ? true : false}
							value={this.state.companyName}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='firstName'
							name='firstName'
							type='firstName'
							label='First name'
							className={classes.textField}
							helperText={errors.firstName}
							error={errors.firstName ? true : false}
							value={this.state.firstName}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='lastName'
							name='lastName'
							type='lastName'
							label='Last name'
							className={classes.textField}
							helperText={errors.lastName}
							error={errors.lastName ? true : false}
							value={this.state.lastName}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='role'
							name='role'
							type='role'
							label='What is your role?'
							className={classes.textField}
							helperText={errors.role}
							error={errors.role ? true : false}
							value={this.state.role}
							onChange={this.handleChange}
							fullWidth
						/>
						<TextField
							id='goal'
							name='goal'
							label='Goal'
							className={classes.textField}
							select
							value={this.state.goal}
							fullWidth
							onChange={this.handleChange}
							error={errors.goal ? true : false}
							helperText={errors.goal}>
							<MenuItem
								value='Build new offers / packages / bundles / propositions using
								existing product- / service-features'>
								Build new offers / packages / bundles / propositions using
								existing product- / service-features
							</MenuItem>
							<MenuItem value='Price my first product / service'>
								Price my first product / service
							</MenuItem>
							<MenuItem value='Price an additional product / service'>
								Price an additional product / service
							</MenuItem>
							<MenuItem
								value='Optimise / review existing price(s) for product / services
								already launched'>
								Optimise / review existing price(s) for product / services
								already launched
							</MenuItem>
							<MenuItem value='Price my product in a new channel, country'>
								Price my product in a new channel, country
							</MenuItem>
							<MenuItem value='Assess any gaps in my product / service portfolio'>
								Assess any gaps in my product / service portfolio
							</MenuItem>
							<MenuItem value='Better track my pricing decisions, to take better decisions'>
								Better track my pricing decisions, to take better decisions
							</MenuItem>
							<MenuItem
								value='Conduct customer / market research for a new product / market.
								As such, I need to understand how to test pricing / get
								customers to tell me what price they would pay'>
								Conduct customer / market research for a new product / market.
								As such, I need to understand how to test pricing / get
								customers to tell me what price they would pay
							</MenuItem>
							<MenuItem value='Other'>Other</MenuItem>
						</TextField>

						<TextField
							id='companyType'
							name='companyType'
							label='What kind of company are you?'
							className={classes.textField}
							select
							value={this.state.companyType}
							fullWidth
							onChange={this.handleChange}
							error={errors.companyType ? true : false}
							helperText={errors.companyType}>
							<MenuItem value='Software and Internet'>Software and Internet</MenuItem>
							<MenuItem value='Agriculture and Mining'>Agriculture and Mining</MenuItem>
							<MenuItem value='Business Services'>Business Services</MenuItem>
							<MenuItem value='Computer and Electronics'>Computer and Electronics</MenuItem>
							<MenuItem value='Consumer Services'>Consumer Services</MenuItem>
							<MenuItem value='Education'>Education</MenuItem>
							<MenuItem value='Energy and Utilities'>Energy and Utilities</MenuItem>
							<MenuItem value='Financial Services'>Financial Services</MenuItem>
							<MenuItem value='Government'>Government</MenuItem>
							<MenuItem value='Health, Pharmaceuticals, and Biotech'>Health, Pharmaceuticals, and Biotech</MenuItem>
							<MenuItem value='Manufacturing'>Manufacturing</MenuItem>
							<MenuItem value='Media and Entertainment'>Media and Entertainment</MenuItem>
							<MenuItem value='Real Estate and Construction'>Real Estate and Construction</MenuItem>
							<MenuItem value='Retail'>Retail</MenuItem>
							<MenuItem value='Telecommunications'>Telecommunications</MenuItem>
							<MenuItem value='Transportation and Storage'>Transportation and Storage</MenuItem>
							<MenuItem value='Travel Recreation and Leisure'>Travel Recreation and Leisure</MenuItem>
							<MenuItem value='Wholesale and Distribution'>Wholesale and Distribution</MenuItem>
							<MenuItem value='Other'>Other</MenuItem>
						</TextField>

						{errors.general && (
							<Typography variant='body2' className={classes.customError}>
								{errors.general}
							</Typography>
						)}
						<Button
							type='submit'
							variant='contained'
							color='primary'
							className={classes.button}
							disabled={loading}
						>
                            Next
                            {loading && (
								<CircularProgress className={classes.progress} size={30} />
							)}
						</Button>
						<br />
						<small>
							dont have an account ? sign up <Link to='/signup'>here</Link>
						</small>
					</form>
				</Grid>
				<Grid item sm />
			</Grid>
		);
	}
}

SetupUserInformation.propTypes = {
	// classes: PropTypes.object.isRequired,
	// loginUser: PropTypes.func.isRequired,
	// user: PropTypes.object.isRequired,
	// UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
	return {
		UI: state.UI,
	};
};
const mapDispatchToProps = {
	updateUserInformation
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SetupUserInformation));
