import * as actionTypes from './actionTypes';

import axios from './../../axios-instance';
import axiosapi from './../../axios-instance-api';
export const nextStepSuccess = (
	companyName,
	firstName,
	lastName,
	goal,
	role,
	companyType
) => {
	return {
		type: actionTypes.NEXT_STEP_SUCCESS,
		companyName: companyName,
		firstName: firstName,
		lastName: lastName,
		goal: goal,
		role: role,
		companyType: companyType,
	};
};

export const fetchUserData = (token, userId) => {
	let fetchedUser = null;
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/users.json' + queryParams);
		for (const key in response.data) {
			fetchedUser = response.data[key].userData;
		}
		dispatch(
			nextStepSuccess(
				fetchedUser.companyName,
				fetchedUser.firstName,
				fetchedUser.lastName,
				fetchedUser.goal,
				fetchedUser.role,
				fetchedUser.companyType
			)
		);
	};
};

export const updateUserInformation = (userInformation, history) => {
	return (dispatch) => {
		dispatch({ type: actionTypes.LOADING_UI });
		axiosapi
			.put('/user', userInformation)
			.then((res) => {
				dispatch(
					nextStepSuccess(
						res.data.companyName,
						res.data.firstName,
						res.data.lastName,
						res.data.goal,
						res.data.role,
						res.data.companyType
					)
                );
                dispatch({ type: actionTypes.CLEAR_ERRORS })
                history.push('/')
            }).catch(err => {
                console.log(err)
                dispatch({
                    type: actionTypes.SET_ERRORS,
                    payload: err.response.data
                })
            })
	};
};
