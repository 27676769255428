import React, { Component } from "react";
import Information from "./Information";

class Competition extends Component {
  render() {
    return <Information />;
  }
}

export default Competition;
