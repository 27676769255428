const initialData = {
	tasks: {
		'task-1': { id: 'task-1', content: 'Costs' },
        'task-2': { id: 'task-2', content: 'Value-drivers' },
		'task-3': { id: 'task-3', content: 'Customers' },
		'task-4': { id: 'task-4', content: 'Channels' },
		'task-5': { id: 'task-5', content: 'Features' },
		'task-6': { id: 'task-6', content: 'Competitors' },

	},
	columns: {
		'column-1': {
			id: 'column-1',
			title: 'Modules',
			taskIds: ['task-1', 'task-2', 'task-3', 'task-4', 'task-5', 'task-6'], // indicate ownership, and maintain order
		},
		'column-2': {
			id: 'column-2',
			title: 'Priority',
			taskIds: [], // indicate ownership, and maintain order
		}
	},
	columnOrder: ['column-2', 'column-1'],
};

export default initialData;