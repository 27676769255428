import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { } from 'react-bootstrap/Form';
import { withRouter } from 'react-router-dom';
import * as actions from '../../../store/actions/index';
import Table from "react-bootstrap/Table";
import Container from "../../../hoc/container";
import Button from "react-bootstrap/Button";
import Edit from "@material-ui/icons/Edit";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from '@material-ui/core';
import {  Row, Col } from 'react-bootstrap';

// utilities
// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file prints an output for the user using the data from of needs and unmetNeeds
// Related files: None
// This file and related files are called in index.js and App.js
// Note: this file assumes the user is logged in and has entered the needed data to work poperly. 


//  RIGHT now this is the same as needs-basednDex.js, fix that.
class valueBasednDex extends Component {

  // Opens controls the modal for a new product/service

  state = {
    featureData: [],
    selectedFeature: "",
    load: false,
    primaryMarketData: "",
    primaryMarketId: "",
    markets: [],
    customerNeeds: [],
    needForCurrentMarket: [],
    currentCustomers: [],
    fetchDone: true,
    unmetNeeds: [],
    listOfNeeds: [],
    selectedNeed: "",
    selectMarket: "",
    helpOneModal: false,
    // customerNeeds for a current market

  }

  openHelp = () =>{
      this.setState({
          ...this.state,
          helpOneModal:true,
      });
  }

  handleCloseModals = () =>{
      this.setState({
          ...this.state,
          helpOneModal: false,
      });
  }



  //This function is responsible foe calling allm the need data from the server for the page to render correctly
  async componentDidMount() {
    await this.props.onFetchData(this.props.token, this.props.userId);
    await this.props.onFetchCustomerNeeds(this.props.token, this.props.userId);
    await this.props.onFetchCustomer(this.props.token, this.props.userId);
    await this.props.onFetchUnmetNeed(this.props.token, this.props.userId);
    await this.props.onFetchFeature(this.props.token, this.props.userId);
  }

  // reload the page
  handleClose = () => {
    this.props.history.push("/");
  };

  // This function handles when the user picks a new need, and reINITs the array of customerNeeds so all the data
  //   is calculated again.
  handleSelectNeed = (e) => {

    this.setState({
      selectedNeed: e.target.value,
      customerNeeds: [...this.state.customerNeeds],
      //needForCurrentMarket: needForCurrentMarket,
    });
  };
  handleSelectFeature = (e) => {
    var holder;

    for(var index = 0; index !== this.state.featureData.length; index++){
        if(e.target.value === this.state.featureData[index].servesTheBenefit){
            holder = this.state.featureData[index];
        }
    }

    console.log(e.target.value);

    this.setState({
      selectedFeature: holder,
      featureData: [...this.state.featureData],
      //needForCurrentMarket: needForCurrentMarket,
    });
  };

  // For the user to make them feel like the edit is now saved, 
  //  current version of the code saves all changes to the serve. If 
  //  this needs to be changed, this is the file to do it.
  handleEdit = () => {
    console.log("Handle edit");
  };

  // The current version of the code does not need this yet, future versions might need this.
  // handleInputChange = (e) => {
  //   e.preventDefault();
  //   this.setState({ [e.target.name]: e.target.value });
  // };

  //This function renders the selectMartet part of the page and sets it up so everything is called 
  //  correctly
  selectMarkets = () => {
    return (
      <Form.Group>
        <Form.Control
          as="select"
          size="lg"
          onChange={this.handleSelectNeed}// handles all for the the table to be calculated correctly
        //defaultValue="Pick one"
        >
          {this.state.listOfNeeds.map((val) => {
            // uses the list of needs to map them to optiosn to be 
            //  to be displayed 
            return (
              <option key={val} value={val}>
                {val}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };

  selectFeature = () => {
    return (
      <Form.Group>
        <Form.Control
          as="select"
          size="lg"
          onChange={this.handleSelectFeature}// handles all for the the table to be calculated correctly
        //defaultValue="Pick one"
        >
          {this.state.featureData.map((val) => {
            // uses the list of needs to map them to optiosn to be 
            //  to be displayed 
            return (
              <option key={val.servesTheBenefit} value={val.servesTheBenefit}>
                {val.servesTheBenefit}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    );
  };


  // This function handles all th logic to map all the data the servers to usable form
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps);
    // maps markets to an array 
    const features = nextProps.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
       });

    const markets = nextProps.marketData.map((elem) => {
      return { ...elem.marketInfo, marketId: elem.id };
    });

    // maps customerNeeds to an array
    const customerNeeds = nextProps.customerNeeds.map((elem) => {
      return {
        ...elem.dataValues,
        // markets: [...elem.markets],
        needId: elem.id,
      };
    });

    var listOfNeeds = [];
    // console.log("loooooooook");
    // console.log(customerNeeds);
    // Gives a list of needs for easy user later on (array of strings)
    for (var i = 0; i !== customerNeeds.length; i++) {
      listOfNeeds.push(customerNeeds[i].needs);
    };


    // markets is now in dataValues, so need.dataValues.market for need[index].dataValues.market
    //  if need is an array
    // maps customerNeeds to an array and fillters out unneeded data.
    const needForCurrentMarket = nextProps.customerNeeds
      .filter((need) => {
        return need.dataValues.markets.includes(this.state.selectMarket);
      })
      .map((elem) => {
        return {
          ...elem.dataValues,
          // markets: [...elem.markets],
          needId: elem.id,
          percent: null,
        };
      });

    // maps unmetNeed to an arrat
    const unmetNeed = nextProps.unmetNeedData
      .map((elem) => {
        return {
          unmetInfo: elem.unmetInfo,
          //markets: [...elem.markets],
          //needId: elem.needId,
          //percent: elem.percent,
          id: elem.id,
        };
      });

    // Connects the current needs to th unmet Needs
    for (let i = 0; i !== needForCurrentMarket.length; i++) {
      for (let j = 0; j !== unmetNeed.length; j++) {
        if (undefined !== unmetNeed[j].unmetInfo) {  // for the edge case of the user changing market with no data
          if (needForCurrentMarket[i].needs === unmetNeed[j].unmetInfo.needs) {
            needForCurrentMarket[i].percent = unmetNeed[j].unmetInfo.percent;
            needForCurrentMarket[i].id = unmetNeed[j].unmetInfo.id;
          };
        };
      };
    };
    // Maps cutomerData to an array
    const dataCustomer = nextProps.customerData.map(elem => {
      return { ...elem.customerData, timestamp: elem.timestamp };
    });

    // console.log("segment");
    // console.log(dataCustomer);

    // The below handles the logic for getting the current customers
    var customersHolder;
    // the below logic gets the current customr data;
    if (dataCustomer.length !== 0) {
      customersHolder = dataCustomer[0];
    }
    // console.log("Holder:");
    // console.log(customersHolder);
    var holdIndex = -1;
    for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
      //console.log(index + " " + dataCustomer[index]);
      if (customersHolder.timestamp < dataCustomer[index].timestamp) {
        customersHolder = dataCustomer[index];
        holdIndex = index;
      }
    }
    // console.log("customerHolder: ");
    // console.log(customersHolder);
    var currentCustomers = [];
    if(holdIndex !== -1){
      currentCustomers.push(customersHolder.primaryTargetCustomer);
      currentCustomers.push(customersHolder.secondaryTargetCustomer);
    }
    // console.log("Look hereeeee")
    // console.log(features);
    // saves the data pulled from the server also tells the page it is ok to render.
    this.setState({
      featureData: features,
      primaryMarketData: nextProps.primaryMarketData,
      primaryMarketId: nextProps.primaryMarketId,
      currentCustomers: currentCustomers,
      markets: markets,
      customerNeeds: customerNeeds,
      unmetNeeds: unmetNeed,
      needForCurrentMarket: needForCurrentMarket,
      fetchDone: false,
      listOfNeeds: listOfNeeds,
    });
  }

  // This function handles the header rendering with the current customers
  renderHeaderChart() {
    const row = [];
    // console.log("HJekljfhbkusdbgfsd");
    // console.log(this.state.needForCurrentMarket);
    for (var index = 0; index !== this.state.currentCustomers.length; index++) {
      row.push(
        <th>{this.state.currentCustomers[index]}</th>
      );
    }
    // this addes a copy of row to the end of row
    row.push(...row);
    // console.log("row");
    // console.log(row);
    return (row);
  }

  // this function handles all the logic for rendering the Body fo the chart one line at the time
  // name of need| percert of col|... this contines for all cols needed...| percent of unmetNeed|
  //    ... math of met need based on selected need.
  renderBodyChart = (need, index) => {
    // row holds the given info to print for a given need
    const row = [];
    //console.log(need);
    // First the need name is added
    row.push(
      <td>{need.needs}</td>
    );
    // listOfPercents holds the percents that will be printed in order for later use in the math. 
    // flag keeps track of if a given value exits, if not it will remain false, else it will be true
    //  this var is reused in later parts for the same/simliar perpose. 
    var listOfPercents = [];
    var flag = false;
    // This loop goes thru the currentCustomes in order and adds only the percent of a matching need,
    //  else it is not added, instead a message of "No value entered is printed"
    for (var index = 0; index !== this.state.currentCustomers.length; index++) {
      for (var j = 0; j !== need.listOfNeeds.length; j++) {
        if (this.state.currentCustomers[index] === need.listOfNeeds[j][0]) {
          row.push(
            <td>{need.listOfNeeds[j][1]}</td>
          );
          listOfPercents.push(
            parseInt(need.listOfNeeds[j][1])
          );
          flag = true;
        }
      }
      if (!flag) {
        row.push(
          <td>No value entered</td>
        );
        listOfPercents.push(
          "No value entered"
        );
      }
    }

    // percent holds the percent of the given unmetNeed, if it was entered
    var percent = "No value entered";
    // selectedPercet holds the percent of the given selected need's unmet percent
    var selectedPercet = "No value entered";
    var selected = this.state.selectedNeed;
    // incase none is selected at the moment
    if (selected === "") {
      selected = this.state.listOfNeeds[0];
    }
    // console.log("look here");
    // console.log(this.state.unmetNeeds);
    // this loop finds the percent and the select percetn if they exist.
    for (var index = 0; index !== this.state.unmetNeeds.length; index++) {
      if (need.needs === this.state.unmetNeeds[index].unmetInfo.needs) {
        percent = this.state.unmetNeeds[index].unmetInfo.percent;
        percent = parseInt(percent);
      }
      if (selected === this.state.unmetNeeds[index].unmetInfo.needs) {
        selectedPercet = this.state.unmetNeeds[index].unmetInfo.percent;
      }
    }

    // pushed the percent of a given unmet percent
    row.push(
      <td>{percent}</td>
    );

    //percent = "missing value";

    // get value from selected
    //flag = false;
    // For all percents in the ListOfPercents it calculates the percent if posible else it prints a message for a given 
    //  value.
    for (var index = 0; index !== listOfPercents.length; index++) {

      // If the row belongs to the selected need then 100 is printed
      if (selected === need.needs) {
        row.push(
          <td>100</td>
        );
        flag = true;
      } else if (listOfPercents[index] === "No value entered" || selectedPercet === "No value entered" || percent === "No value entered") {
        // If the row is missing a value this is printed
        row.push(
          <td>"Missing a input"</td>
        );
      } else {
        // Else the input is valid and the following calcuation is done an printed
        //: (percent of need * ( 1 - unmetNeed percent)) / (percent of Need* (1 - percent of SELECTED unmetned))
        var termOne = listOfPercents[index] / 100;
        var termTwo = selectedPercet / 100;
        percent = percent / 100;
        var value = (termOne * (1 - percent));
        value = value / (termOne * (1 - termTwo));
        value = value * 100;
        row.push(
          <td>{value}</td>
        );
      }




    }

    //console.log(need);
    // prints row[] as a row tr seperates the rows.
    return (
      <tr >
        {row}
      </tr>
    );
  }

  renderFirstBodyChart = (feature, index) => {

    var row = [];

    row.push(
        <td>{feature.featureName}</td>
        );

    var percent = parseInt(feature.percivedValue);
      if (percent !== "NaN") {
          percent = (percent / 5) * 100;

          if (percent >= 80) {
              row.push(
              <td>🍔</td>
              );
          } else if (percent >= 60) {
            row.push(
                <td>🍟</td>
                );
          } else {
            row.push(
                <td>☕</td>
                );
          }

          
          row.push(
            <td>{percent}</td>
            );

          if(this.state.selectedFeature !== ""){
              var selectedPercent = parseInt(this.state.selectedFeature.percivedValue);
              selectedPercent = (selectedPercent/5) * 100;
              
              row.push(
                <td>{percent/selectedPercent}</td>
                );
          }else{
            row.push(
                <td>{percent}</td>
                );
          }

      } else {
        row.push(
            <td>{"No value entered"}</td>
            );
        row.push(
            <td>{"No value entered"}</td>
            );
        row.push(
            <td>{"No value entered"}</td>
            );
        
      }
    return (
        <tr >
          {row}
        </tr>
      );
  }


  renderSecondBodyChart = (feature, index) => {

    var row = [];

    row.push(
        <td>{feature.featureName}</td>
        );

    row.push(
        <td>{feature.servesTheBenefit}</td>
        );

    row.push(
        <td>Filler</td>
    )
    var percent = parseInt(feature.percivedValue);
      if (percent !== "NaN") {
          percent = (percent / 5) * 100;

     

          if(this.state.selectedFeature !== ""){
              var selectedPercent = parseInt(this.state.selectedFeature.percivedValue);
              selectedPercent = (selectedPercent/5) * 100;
              
              row.push(
                <td>{(percent/selectedPercent)/selectedPercent}</td>
                );
          }else{
            row.push(
                <td>{percent}</td>
                );
          }

      } else {
        row.push(
            <td>{"MISSING VALUE"}</td>
            );
       
        
      }
    return (
        <tr >
          {row}
        </tr>
      );
  }

  renderThirdBodyChart = (feature, index) => {

    var row = [];

    row.push(
        <td>{feature.featureName}</td>
        );

    row.push(
        <td>{feature.versionOne}</td>
        );

    row.push(
            <td><input
            type="text"
            name={"one"}
            defaultValue={"100"}
            
          /></td>
        
    );

    row.push(
        <td>{feature.versionTwo}</td>
        );

    row.push(
            <td><input
            type="text"
            name={"one"}
            defaultValue={""}
            
          /></td>
        
    );

    row.push(
        <td>{feature.versionThree}</td>
        );

    row.push(
            <td><input
            type="text"
            name={"one"}
            defaultValue={""}
            
          /></td>
        
    )

    var percent = parseInt(feature.percivedValue);
      if (percent !== "NaN") {
          percent = (percent / 5) * 100;

     

          if(this.state.selectedFeature !== ""){
              var selectedPercent = parseInt(this.state.selectedFeature.percivedValue);
              selectedPercent = (selectedPercent/5) * 100;
              
              row.push(
                <td>{(percent/selectedPercent)/selectedPercent}</td>
                );
          }else{
            row.push(
                <td>{percent}</td>
                );
          }

      } else {
        row.push(
            <td>{"MISSING VALUE"}</td>
            );
       
        
      }
    return (
        <tr >
          {row}
        </tr>
      );
  }

  render() {




    if (this.state.fetchDone === true) {
      return <div>Loading</div>;
    } else {
      return (
        <React.Fragment>
          <Container>

            <div className={'text-center'}>

              <div className='text-center mb-4'>
                <h1 className='h3 mb-3 font-weight-normal'>Value Based nDex</h1>
              </div>

              <div className='text-center mb-4'>
                <h2 className='h3 mb-3 font-weight-normal'>In the previous section, you described 
                        the features you offer to address the different needs
                </h2>
              </div>

              <div className='text-center mb-4'>
                <h2 className='h3 mb-3 font-weight-normal'>What feature would you like to 
                        select as your Base for nDexing purposes?
                </h2>
              </div>

              <Col>
                    <Tooltip title="Click for help" arrow>
                        <Button onClick={this.openHelp}>How to use me??</Button>
                    </Tooltip>
             </Col>

              {this.selectFeature()}

              <Table striped bordered hover >
                <thead>
                  <tr>

                    <th>
                        <hr />
                          Features
                        <hr />
                    </th>
                    <th >
                        <hr />
                            Role
                         <hr />
                    </th>
                    <th >
                        <hr />
                            The Features pain points at
                        <hr />
                    </th>
                    <th >
                      <hr />
                        nDex
                        <hr />
                    </th>
                  </tr>
                  <tr>
                    

                  </tr>

                </thead>
                <tbody>
                    {this.state.featureData.map(this.renderFirstBodyChart)}
                </tbody>
              </Table>


              <button className='btn btn-lg btn-primary btn-block' onClick={this.onSubmitHandler}>
                Continue
					</button>

            </div>

            {this.state.helpOneModal ? (
            <Modal
              show={this.state.helpOneModal}
              onHide={this.handleCloseModals}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <div className='text-center mb-4'>
                <h2 className='h3 mb-3 font-weight-normal'>It is best to select a 🍔 as your Base. 
                </h2>
                <hr/>
                <h2 className='h3 mb-3 font-weight-normal'>
                In the absence of one, select a 🍟; something that falls in between (i.e. no pictogram) 
                </h2>
                <hr/>
                <h2 className='h3 mb-3 font-weight-normal'>
                Avoid using a ☕ except if you plan to build new products or services where you expect the 
                ☕ to become a 🍔.
                </h2>
              </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModals}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          </Container>
        </React.Fragment>
      );
    }
  }


}

const mapStateToProps = (state, ownProps) => {
  console.log(state);
  // console.log(ownProps);
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    marketData: state.market.marketData,
    featureData: state.features.featureList,
    isAuthenticated: state.auth.token !== null,
    primaryMarketData: ownProps.primaryMarketData,
    primaryMarketId: ownProps.primaryMarketId,
    customerNeeds: state.customerNeeds.data,
    customerData: state.customerNeeds.customerData,
    unmetNeedData: state.unmetNeed.unmetData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchFeature: (data, token) => {
        dispatch(actions.fetchFeature(data, token));
      },
    onFetchData: (token, userId) => {
      dispatch(actions.fetchMarket(token, userId));
    },
    onFetchPrimaryMarket: (token, userId) => {
      dispatch(actions.fetchPrimaryMarket(token, userId));
    },
    onFetchCustomerNeeds: (token, userId) => {
      dispatch(actions.fetchNeed(token, userId));
    },
    onSubmitNeed: (data, token) => {
      dispatch(actions.submitUnmetNeed(data, token));
    },
    onFetchUnmetNeed: (token, userId) => {
      dispatch(actions.fetchUnmetNeed(token, userId));
    },
    onUpdateUnmetNeed: (unmetId, data, token) => {
      dispatch(actions.updateUnmetNeed(unmetId, data, token));
    }, onFetchCustomer: (token, userId) => dispatch(actions.fetchCustomer(token, userId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(valueBasednDex)
);
