// Created by: Quang Nguyen
import React, { Component } from "react";
import Container from "../../hoc/container";

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
        </Container>
      </React.Fragment>
    );
  }
}

export default Main;
