import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router-dom";
import RefMarket from "./refMarket";

class Introduction extends Component {
  state = {
    showIntro: true,
    showRefMarket: false,
  };

  handleClose = () => {
    this.props.history.push("/");
  };
  render() {
    if (this.state.showIntro) {
      return (
        <Modal show={this.state.showIntro} onHide={this.handleClose}>
          {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
          <Modal.Body>
            <div>
              You have defined your customer needs in each market you operate
              in. This can be further extended by specifying the specific needs
              of specific customer segments.
            </div>
            <div>
              The question is now to what degree these needs are currently
              addressed by each market. It may not be met at all by the market,
              if may be fully met by yourself, it may be met by your
              competitors, or it may also be met by alternative solutions that
              don’t fully fulfill customer needs but do fulfil a proportion of
              them.
            </div>
            <div>
              Example: Taxis were the only option for a long time to fulfil the
              need for immediate transportation. However, the same customers
              using Taxis had a need to get one without having to chase them
              around town / going to a busy street to hail them. 100% of the
              need was thus not met. This is what Uber successfully was able to
              target.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                this.setState({ showIntro: false, showRefMarket: true })
              }
            >
              Next
            </Button>
          </Modal.Footer>
        </Modal>
      );
    } else if (this.state.showRefMarket) {
      return <RefMarket />;
    }
  }
}

export default withRouter(Introduction);
