import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { } from 'react-bootstrap/Form';
import { withRouter } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import * as actions from '../../store/actions/index';
import Container from "../../hoc/container";

// utilities
// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file enables the user to enter baseUnit data, this lets the program speak the same language as
//	the user 
// Related files: store/actions/baseUnit.js, store/reducers/baseunit.js
// productServiceName and related files are called in index.js and App.js
// Note: this file assumes the user is logged in to be used.
class baseUnit extends Component {

	// Opens controls the modal for a new product/service

    state = {
		product: '',
        BaseUnit: '',
        consumable: '',
	}
	

	async componentDidMount() {
		// fetch data from database
	
		await this.props.onFetchListOfProducts(this.props.token, this.props.userId);
		const data = this.props.listOfProducts.map(elem => {
		  return { ...elem.psData, timestamp: elem.timestamp };
		});

		var elemment;
		if(data.length > 0){
			elemment =data[0]
		}
		// Since the data is stored and requested in a random order
		for(var index = 0; data.length > index; index++){
			if(elemment.timestamp < data[index].timestamp){
				elemment = data[index];
			}
		}
		
		this.setState({
			product: elemment.mainProductSeriveName
		});
		console.log(data);
		console.log(this.state.listOfProducts);
		console.log(elemment);

	  }

	//Updates the state to the user input

    onChangedHandler = (event) =>{
        this.setState({
            ...this.state,
		    [event.target.id]: event.target.value,
         });
    }
	
	
	// Saves the current state
    onSubmitHandler = (event) =>{
        event.preventDefault();
    
       // console.log(this.props.token);
        this.props.ADD_BASEUNIT_INFOMATION(
            this.state.mainProductSeriveName,
            this.state.description,
            this.state.primarily,
            this.state.newOrComing,
            this.state.newOrComingName,
            this.state.newPrimarily,
        );
		console.log("Saved data");
		const  newUnitData = {
			BaseUnit: this.state.BaseUnit,
			consumable: this.state.consumable,
		}
        const newData = {
            BaseUnitData:{
                ...newUnitData
            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
       this.props.onSubmitBaseUnit(newData, this.props.token);
	}
	
	
    
    render() {
        
		// the below prints a form like style for the user to enter the needed information.
		return (
			<React.Fragment>
				<Container>
			<div className={'text-center'}>
				<form className='form-signin' >
					<div className='text-center mb-4'>
						<h1 className='h3 mb-3 font-weight-normal'>Base Unit</h1>
					</div>

                    <div className='text-center mb-4' >
                    <h2 className='h3 mb-3 font-weight-normal'>
                        You will later be able to refine your Pricing Model and Metrics, 
                        but lets set a basis, to ensure we speak the same language.
                    </h2>
					</div>

                    
					<div className='form'>
                      
                        <Tooltip title=" How do we define Base unit?: it is an 
                        arbitrarily definition of the smallest unit of volume
                        " arrow>
                        <button className='btn  btn-primary '>Base Unit :</button>
                        </Tooltip>
						<input
							type='text'
							id='BaseUnit'
							className='form-control'
							placeholder="Ex: Subscription'"
							required
							autoFocus
							onChange={this.onChangedHandler}
						/>
						{/* <label htmlFor='mainProductSeriveName'>Main Product or Service name:</label> */}
					   
                        <h2>of {this.state.product} on a</h2>
						<input
							type='text'
							id='consumable'
							className='form-control'
							placeholder="Ex: monthly'"
							required
							autoFocus
							onChange={this.onChangedHandler}
						/>
                        <h2>basis</h2>
						{/* <label htmlFor='mainProductSeriveName'>Main Product or Service name:</label> */}
					   

                    </div>
                    
					<button className='btn btn-lg btn-primary btn-block' onClick={this.onSubmitHandler}>
						Continue
					</button>
				</form>
			</div>
			</Container>
			</React.Fragment>
		);
	}


}

const mapStateToProps = (state) => {
	return {
		userId: state.auth.userId,
		isAuthenticated: state.auth.token !== null,
		token: state.auth.token,
		finished: state.data.finished,
		listOfProducts: state.baseUnit.listOfProducts,
	};
};


const mapDispathToProps = (dispatch) => {
	return {
        ADD_BASEUNIT_INFOMATION: (BaseUnit, consumable) =>
        dispatch(
            actions.ADD_BASEUNIT_INFOMATION(
                BaseUnit,      
                consumable,
            )
        ),
		onSubmitBaseUnit: (data, token) => dispatch(actions.submitBaseUnit(data, token)),
		onFetchListOfProducts: (token, userId) => dispatch(actions.fetchListOfProducts(token, userId)),
    };
};


export default withRouter(
    connect( mapStateToProps, mapDispathToProps )(baseUnit)
);