import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import MarTable from './Table';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { withRouter } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import HelpIcon from '@material-ui/icons/Help';
import Spinner from 'react-bootstrap/Spinner';

class MarketInfo extends Component {
  state = {
    showModal1: true,
    showModal2: false,
    showModal3: false,
    showModal4: false,
    showTable: false,
    marketName: '',
    marketDescription: '',
    primaryMarketId: '',
    data: [],
    doneFetch: false,
  };

  async componentDidMount() {
    // fetch data from database
    await this.props.onFetchData(this.props.token, this.props.userId);
    // console.log(this.props);
    const data = this.props.marketData.map((elem) => {
      return { ...elem.marketInfo, marketId: elem.id };
    });
    this.setState({ data: data, doneFetch: true });
  }
  handleClose = () => {
    this.props.history.push('/');
  };
  handleShow2 = () => {
    this.setState({
      showModal1: false,
      showModal2: true,
      showModal3: false,
      showModal4: false,
    });
  };
  handleShow3 = () => {
    this.setState({
      showModal1: false,
      showModal2: false,
      showModal3: true,
      showModal4: false,
    });
  };
  handleShow4 = () => {
    this.setState({
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: true,
    });
  };
  showTable = async (e) => {
    e.preventDefault();
    const { marketName, marketDescription } = this.state;

    const newData = {
      // marketInfo: { marketName, marketDescription, isPrimary: true },
      marketInfo: { marketName, marketDescription },
      userId: this.props.userId,
    };
    await this.props.onSubmitData(newData, this.props.token);

    // in the first round, only one market was save in the db
    // => primary market is set to be the first and only element
    const primaryMarket = { ...newData, marketId: this.props.marketData[0].id };

    // submit primary market to db
    await this.props.submitPrimaryMarket(primaryMarket, this.props.token);
    this.setState({
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showTable: true,
    });
  };

  // handle any input change in the form
  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // pop over for more information about market
  popover = (
    <Popover id='popover-basic'>
      <Popover.Content>
        It is just a Group of Buyers and Sellers of a specfic Goods and/or
        Services addressing a relatively similar type of need.
      </Popover.Content>
    </Popover>
  );

  render() {
    // console.log("render modal");
    // console.log(this.state);
    if (!this.state.doneFetch) {
      return (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      );
    }
    if (this.state.doneFetch && this.state.data.length < 1) {
      return (
        <>
          <Modal show={this.state.showModal1} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Now, let's review your Market</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              How do we define Market?
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement='right'
                overlay={this.popover}
              >
                <HelpIcon />
              </OverlayTrigger>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' onClick={this.handleShow2}>
                Next
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showModal2}
            onHide={this.handleClose}
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>
                You may play in multiple Markets. For now, please name your
                primary one:
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <label>Name:</label>
                <input
                  type='text'
                  name='marketName'
                  onChange={this.handleInputChange}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' onClick={this.handleShow3}>
                Next
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showModal3}
            onHide={this.handleClose}
            backdrop='static'
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Please provide a short description for {this.state.marketName}
                (Optional)
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <label>Description:</label>
                <input
                  type='text'
                  name='marketDescription'
                  onChange={this.handleInputChange}
                />
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' onClick={this.handleShow4}>
                Next
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.showModal4}
            onHide={this.handleClose}
            backdrop='static'
          >
            <Modal.Body>
              In the following sections, we will focus on the offer for this
              specific market {this.state.marketName}. However, nDexio allows
              you to build your pricing for multiple markets. Do you have serve
              additional markets?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant='secondary'
                onClick={() => {
                  this.props.history.push('/');
                }}
              >
                No
              </Button>
              <Button variant='primary' onClick={this.showTable}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          {this.state.showTable ? (
            <React.Fragment>
              <MarTable />
            </React.Fragment>
          ) : null}
        </>
      );
    } else {
      return (
        <React.Fragment>
          <MarTable />
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    marketData: state.market.marketData,
    isAuthenticated: state.auth.token !== null,
    primaryMarketId: state.market.primaryMarket,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) =>
      dispatch(actions.fetchMarket(token, userId)),
    onSubmitData: (data, token) =>
      dispatch(actions.submitMarketInfo(data, token)),
    updatePrimaryMarket: (primaryMarketId) => {
      dispatch(actions.updatePrimaryMarket(primaryMarketId));
    },
    submitPrimaryMarket: (data, token) => {
      dispatch(actions.submitPrimaryMarket(data, token));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MarketInfo)
);
