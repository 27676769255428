import * as actionTypes from './actionTypes';
import axios from '../../axios-instance';

export const submitNeed = (newData, token) => {
	return async (dispatch) => {
		await axios.post('/needValue.json?auth=' + token, newData)
			.then(response => {
				dispatch(submitNeedSuccess(response.data.name, newData))
			})
			.catch(error => {
				dispatch(submitNeedFail(error))
			})
	};
};
export const updateNeed = (needId, newData, token) => {
	return async (dispatch) => {
		await axios.put(`/needValue/${needId}.json?auth=` + token, newData)
			.then(response => {
				dispatch(updateNeedSuccess(needId, newData))
			})
			.catch(error => {
				dispatch(updateNeedFail(error))
			})
	};
}

export const deleteNeed = (needId, token) => {
	return async (dispatch) => {
		await axios.delete(`/needValue/${needId}.json?auth=` + token)
			.then(response => {
			dispatch(deleteNeedSuccess(needId))
			})
			.catch(error => {
				dispatch(deleteNeedFail(error))
			})
	}
}

export const deleteNeedSuccess = (id) => {
	return {
		type: actionTypes.DELETE_DATA_SUCCESS,
		needId: id
	}
}

export const deleteNeedFail = (error) => {
	return {
		type: actionTypes.DELETE_DATA_FAIL,
		error: error
	}
}

export const updateNeedSuccess = (id, needData) => {
	return {
		type: actionTypes.UPDATE_DATA_SUCCESS,
		needId: id,
		data: needData
	}
}

export const updateNeedFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error
	}
}

export const submitNeedSuccess = (id, needData) => {
	return {
		type: actionTypes.SUBMIT_NEED_SUCCESS,
		needId: id,
		data: needData
	};
};

export const submitNeedFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error
	}
}

export const startSubmitNeed = () => {
	return {
		type: actionTypes.START_SUBMIT_DATA,
	};
};

export const fetchNeedSuccess = (data) => {
	return {
		type: actionTypes.FETCH_NEED_SUCCESS,
		data: data
	};
};



export const fetchNeed = (token, userId) => {
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/needValue.json' + queryParams);
		const fetchedData = [];
        // console.log(response);
		for (let key in response.data) {
			fetchedData.push({
				...response.data[key],
				id: key,
			});
		}

		dispatch(fetchNeedSuccess(fetchedData));
	};
};

export const fetchCustomerSuccess = (data) => {
	return {
		type: actionTypes.FETCH_CUSTOMER_SUCCESS,
		customerData: data
	};
};

export const fetchCustomer = (token, userId) => {
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/customerValue.json' + queryParams);
		const fetchedData = [];
		console.log("in fetch custoemr");
        console.log(response);
		for (let key in response.data) {
			fetchedData.push({
				...response.data[key],
				id: key,
			});
		}

		dispatch(fetchCustomerSuccess(fetchedData));
	};
};

export const fetchMarketSuccess = (data) => {
	return {
		type: actionTypes.FETCH_MARKET_SUCCESS,
		marketData: data
	};
};

export const fetchMarketForCustomerNeeds = (token, userId) => {
	return async (dispatch) => {
		const queryParams =
			'?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
		const response = await axios.get('/marketValue.json' + queryParams);
		const fetchedData = [];
		console.log("in fetch custoemr");
        console.log(response);
		for (let key in response.data) {
			fetchedData.push({
				...response.data[key],
				id: key,
			});
		}

		dispatch(fetchMarketSuccess(fetchedData));
	};
};