import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
// This serves as the default value of Geo-Values. If this is seen then act as if
// the sure skiped the Geo-Info page
// OnOffs are used to keep track of what sections the user choose to skip, in the
// order of continent, country, city, and location
// ChangeInPrices keeps track of any changes in price, in the same order as OnOffs
// 'na' means the user did not fill in this feild.


const initialState = {
        segmentedCustomers: "",
        primaryTargetCustomer: "",
        customerPersona: "",
        descriptionOfCustomer: "",
        secondaryTargetCustomer: "",
};

const ADD_CUSTOMER_INFOMATION = (state, action) => {
    // console.log("perfectly");

    return updateObject(state, {
        segmentedCustomers: action.segmentedCustomers,
        primaryTargetCustomer: action.primaryTargetCustomer,
        customerPersona: action.customerPersona,
		descriptionOfCustomer: action.descriptionOfCustomer,
        secondaryTargetCustomer: action.secondaryTargetCustomer,
    })
}

const reducer = (state = initialState, action) => {
    // console.log("redux works");
	switch (action.type) {
		case actionTypes.ADD_PS_INFOMATION:
			return ADD_CUSTOMER_INFOMATION(state, action);
		default:
			return state;
	}
};

export default reducer;