// Created by: Quang Nguyen
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import LeftPannel from '../components/LeftPannel/LeftPannel';
import RightPannel from '../components/RightPannel/RightPannel';
import CurrentSegment from '../components/UserCurrentSegment/UserCurrSeg';
import './Container.css';

class UIContainer extends Component {
	render() {
		return (
			<div>
				<Header />
				<div className='body'>
					<div className='left'>
						<LeftPannel />
					</div>
					<div className='middle'>
						<CurrentSegment />
						<Row> {this.props.children}</Row>
					</div>
					<div className='right'>
						<RightPannel />
					</div>
				</div>

				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state.auth.token !== null,
	};
};

export default connect(mapStateToProps)(UIContainer);
