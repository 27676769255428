import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';


const initialState = {
	data: [],
	finished: false,
};

const updateDataSuccess = (state, action) => {
	let index = -1;
	const data = [...state.data];
	const newData = updateObject(action.costData, { id: action.costId });
	for (let elem of data) {
		if (elem.id === action.costId) {
			index = data.indexOf(elem);
			break;
		}
	}
	if (index !== -1) {
		data[index] = newData;
	}
	return updateObject(state, {
		finished: true,
		data: data,
	});
};

const updateDataFail = (state, action) => {
	return updateObject(state, {
		error: action.error
	})
}

const deleteDataSuccess = (state, action) => {
	let index = -1;
	const data = [...state.data];
	for (let elem of data) {
		if (elem.id === action.costId) {
			index = data.indexOf(elem);
			break;
		}
	}
	if (index !== -1) {
		data.splice(index, 1);
	}

	return updateObject(state, {
		finished: true,
		data: data,
	});
};

const deleteDataFail = (state, action) => {
	return updateObject(state, {error: action.error})
}

const submitDataSuccess = (state, action) => {
	const newData = updateObject(action.costData, { id: action.costId });
	return updateObject(state, {
		finished: true,
		data: state.data.concat(newData),
	});
};

const submitDataFail = (state, action) => {
	return updateObject(state, {
		error: action.error
	})
}

const startSubmitData = (state, action) => {
	return updateObject(state, {
		finished: false,
	});
};

const fetchDataSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_DATA_SUCCESS:
			return fetchDataSuccess(state, action);
		case actionTypes.START_SUBMIT_DATA:
			return startSubmitData(state, action);
		case actionTypes.SUBMIT_DATA_SUCCESS:
			return submitDataSuccess(state, action);
		case actionTypes.SUBMIT_DATA_FAIL:
			return submitDataFail(state, action);
		case actionTypes.UPDATE_DATA_SUCCESS:
			return updateDataSuccess(state, action);
		case actionTypes.UPDATE_DATA_FAIL:
			return updateDataFail(state, action);
		case actionTypes.DELETE_DATA_SUCCESS:
			return deleteDataSuccess(state, action);
		case actionTypes.DELETE_DATA_FAIL:
			return deleteDataFail(state, action);
		default:
			return state;
	}
};

export default reducer;
