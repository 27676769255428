import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

// This serves as the default value of Geo-Values. If this is seen then act as if
// the sure skiped the Geo-Info page
// OnOffs are used to keep track of what sections the user choose to skip, in the
// order of continent, country, city, and location
// ChangeInPrices keeps track of any changes in price, in the same order as OnOffs
// 'na' means the user did not fill in this feild.


const initialState = {
		primaryContinent: '',
        extraContinents: '',
        primaryCountry: '',
		extraCountrys: '',
        primaryCity: '',
		extraCitys: '',
		primaryLoc: '',
        extraLocs: '',
        OnOffs: [true, true, true, true],
        ChangeInPrices: ['na', 'na', 'na', 'na']
};

const ADD_GEO_INFOMATION = (state, action) => {
    // console.log("perfectly");

    return updateObject(state, {
        primaryContinent: action.primaryContinent,
        extraContinents: action.extraContinents,
        primaryCountry: action.primaryCountry,
		extraCountrys: action.extraCountrys,
        primaryCity: action.primaryCity,
		extraCitys: action.extraCitys,
		primaryLoc: action.primaryLoc,
        extraLocs: action.extraLocs,
        ChangeInPrices: action.ChangeInPrices,
        OnOffs: action.OnOffs,
    })
}

const reducer = (state = initialState, action) => {
    // console.log("redux works");
	switch (action.type) {
		case actionTypes.ADD_GEO_INFOMATION:
			return ADD_GEO_INFOMATION(state, action);
		default:
			return state;
	}
};

export default reducer;