import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';


const initialState = {
    companyName: '',
    firstName: '',
    lastName: '',
    goal: '',
    role: '',
    companyType: ''
}

const nextStepSuccess = (state, action) => {
    console.log(action)
    return updateObject(state, {
        companyName: action.companyName,
        firstName: action.firstName,
        lastName: action.lastName,
        goal: action.goal,
        role: action.role,
        companyType: action.companyType
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NEXT_STEP_SUCCESS:
            return nextStepSuccess(state, action);
        default:
            return state;
            
    }
}

export default reducer;
