// Auth
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

// USER REDUCER TYPES
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

// UI REDUCERS TYPES

export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// Data
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';

export const START_SUBMIT_DATA = 'START_SUBMIT_DATA';
export const SUBMIT_DATA_SUCCESS = 'SUBMIT_DATA_SUCCESS';
export const SUBMIT_DATA_FAIL = 'SUBMIT_DATA_FAIL';

export const UPDATE_DATA_SUCCESS = 'UPDATE_DATA_SUCCESS';
export const UPDATE_DATA_FAIL = 'UPDATE_DATA_FAIL';

export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_FAIL = 'DELETE_DATA_FAIL';

// About me
export const NEXT_STEP_SUCCESS = 'NEXT_STEP_SUCCESS';

// company profile
export const SUBMIT_COMPANY_DATA_SUCCESS = 'SUBMIT_COMPANY_DATA_SUCCESS';

export const SUBMIT_COMPANY_DATA_FAIL = 'SUBMIT_COMPANY_DATA_FAIL';

// Geo Page
export const ADD_GEO_INFOMATION = 'ADD_GEO_INFORMATION';

// productServiceInfo Page
export const ADD_PS_INFOMATION = 'ADD_PS_INFORMATION';

// customer Page
export const ADD_CUSTOMER_INFOMATION = 'ADD_CUSTOMER_INFORMATION';

// market

export const SUBMIT_MARKET_FAIL = 'SUBMIT_MARKET_FAIL';
export const START_SUBMIT_MARKET = 'START_SUBMIT_MARKET';
export const SUBMIT_MARKET_SUCCESS = 'SUBMIT_MARKET_SUCCESS';
export const FETCH_MARKET_SUCCESS = 'FETCH_MARKET_SUCCESS';
export const DELETE_MARKET_SUCCESS = 'DELETE_MARKET_SUCCESS';
export const DELETE_MARKET_FAIL = 'DELETE_MARKET_FAIL';
export const UPDATE_MARKET_SUCCESS = 'UPDATE_MARKET_SUCCESS';
export const UPDATE_MARKET_FAIL = 'UPDATE_MARKET_FAIL';
export const ADD_MARKET_INFO = 'ADD_MARKET_INFO';
export const UPDATE_PRIMARY_MARKET = 'UPDATE_PRIMARY_MARKET';
export const UPDATE_PRIMARY_MARKET_SUCCESS = 'UPDATE_PRIMARY_MARKET_SUCCESS';
export const UPDATE_PRIMARY_MARKET_FAIL = 'UPDATE_PRIMARY_MARKET_FAIL';
export const FETCH_PRIMARY_MARKET = 'FETCH_PRIMARY_MARKET';
export const FETCH_PRIMARY_MARKET_SUCCESS = 'FETCH_PRIMARY_MARKET_SUCCESS';
export const FETCH_PRIMARY_MARKET_FAIL = 'FETCH_PRIMARY_MARKET_FAIL';
export const SUBMIT_PRIMARY_MARKET = 'SUBMIT_PRIMARY_MARKET';
export const SUBMIT_PRIMARY_MARKET_SUCCESS = 'SUBMIT_PRIMARY_MARKET_SUCCESS';
export const SUBMIT_PRIMARY_MARKET_FAIL = 'SUBMIT_PRIMARY_MARKET_FAIL';

export const ADD_BASEUNIT_INFOMATION = 'ADD_BASEUNIT_INFORMATION';
export const FETCH_listOfProducts_SUCCESS = 'FETCH_listOfProducts_SUCCESS';

//customerNeeds
export const FETCH_NEED_SUCCESS = 'FETCH_NEED_SUCCESS';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
// the below is used in customerNeeds
//export const FETCH_MARKET_SUCCESS = "FETCH_MARKET_SUCCESS";
export const START_SUBMIT_NEED = 'START_SUBMIT_NEED';
export const SUBMIT_NEED_FAIL = 'SUBMIT_NEED_FAIL';
export const SUBMIT_NEED_SUCCESS = 'SUBMIT_NEED_SUCCESS';

export const UPDATE_NEED_SUCCESS = 'UPDATE_NEED_SUCCESS';
export const UPDATE_NEED_FAIL = 'UPDATE_NEED_FAIL';

export const DELETE_NEED_SUCCESS = 'DELETE_NEED_SUCCESS';
export const DELETE_NEED_FAIL = 'DELETE_NEED_FAIL';

// competition
export const ADD_COMPETITOR = 'ADD_COMPETITOR';
export const SUBMIT_COMPETITOR_SUCCESS = 'SUBMIT_COMPETITOR_SUCCESS';
export const SUBMIT_COMPETITOR_FAIL = 'SUBMIT_COMPETITOR_FAIL';
export const START_SUBMIT_COMPETITOR = 'START_SUBMIT_COMPETITOR';
export const FETCH_COMPETITOR_SUCCESS = 'FETCH_COMPETITOR_SUCCESS';
export const DELETE_COMPETITOR_SUCCESS = 'DELETE_COMPETITOR_SUCCESS';
export const DELETE_COMPETITOR_FAIL = 'DELETE_COMPETITOR_FAIL';
export const UPDATE_COMPETITOR_SUCCESS = 'UPDATE_COMPETITOR_SUCCESS';
export const UPDATE_COMPETITOR_FAIL = 'UPDATE_COMPETITOR_FAIL';
export const UPDATE_PRIMARY_COMPETITOR = 'UPDATE_PRIMARY_COMPETITOR';
export const UPDATE_PRIMARY_COMPETITOR_SUCCESS =
  'UPDATE_PRIMARY_COMPETITOR_SUCCESS';
export const UPDATE_PRIMARY_COMPETITOR_FAIL = 'UPDATE_PRIMARY_COMPETITOR_FAIL';
export const SUBMIT_PRIMARY_COMPETITOR_SUCCESS =
  'SUBMIT_PRIMARY_COMPETITOR_SUCCESS';
export const SUBMIT_PRIMARY_COMPETITOR_FAIL = 'SUBMIT_PRIMARY_COMPETITOR_FAIL';
export const FETCH_PRIMARY_COMPETITOR_SUCCESS =
  'FETCH_PRIMARY_COMPETITOR_SUCCESS';
export const FETCH_PRIMARY_COMPETITOR_FAIL = 'FETCH_PRIMARY_COMPETITOR_FAIL';

// feature
export const ADD_FEATURE = 'ADD_FEATURE';
export const SUBMIT_FEATURE_SUCCESS = 'SUBMIT_FEATURE_SUCCESS';
export const SUBMIT_FEATURE_FAIL = 'SUBMIT_FEATURE_FAIL';
export const START_SUBMIT_FEATURE = 'START_SUBMIT_FEATURE';
export const FETCH_FEATURE_SUCCESS = 'FETCH_FEATURE_SUCCESS';
export const DELETE_FEATURE_SUCCESS = 'DELETE_FEATURE_SUCCESS';
export const DELETE_FEATURE_FAIL = 'DELETE_FEATURE_FAIL';
export const UPDATE_FEATURE_SUCCESS = 'UPDATE_FEATURE_SUCCESS';
export const UPDATE_FEATURE_FAIL = 'UPDATE_FEATURE_FAIL';
export const UPDATE_PRIMARY_FEATURE = 'UPDATE_PRIMARY_FEATURE';

// unmet
export const SUBMIT_UNMET = 'SUBMIT_UNMET';
export const SUBMIT_UNMET_SUCCESS = 'SUBMIT_UNMET_SUCCESS';
export const SUBMIT_UNMET_FAIL = 'SUBMIT_UNMET_FAIL';
export const FETCH_UNMETNEED = 'FETCH_UNMETNEED';
export const FETCH_UNMETNEED_SUCCESS = 'FETCH_UNMETNEED_SUCCESS';
export const UPDATE_UNMETNEED_SUCCESS = 'UPDATE_UNMETNEED_SUCCESS';
export const UPDATE_UNMETNEED_FAIL = 'UPDATE_UNMETNEED_FAIL';
