import * as actionTypes from './actionTypes';
import axios from './../../axios-instance'


export const submitCompanyData = (token, newData) => {
	return async (dispatch) => {
		await axios.post('/company.json?auth=' + token, newData)
			.then(response => {
				dispatch(submitCompanyDataSuccess(response.data.name, newData))
			})
			.catch(error => {
				dispatch(submitCompanyDataFail(error))
			})
	};
};



export const submitCompanyDataSuccess = (companyId, newData) => {
    return {
        type: actionTypes.SUBMIT_COMPANY_DATA_SUCCESS,
        companyId: companyId,
        companyType: newData.companyType,
        firstSaleDate: newData.firstSaleDate,
        yearStart: newData.yearStart,
        currency: newData.currency
    }
}

export const submitCompanyDataFail = (error) => {
	return {
		type: actionTypes.SUBMIT_COMPANY_DATA_FAIL,
		error: error
	}
}