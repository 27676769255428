import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import PrimaryMarketNeed from './primaryMarketNeed';
import { Container } from '@material-ui/core';

class RefMarket extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchPrimaryMarket(this.props.token, this.props.userId);
  }
  state = {
    show: true,
    primaryMarketData: null,
    primaryMarketId: '',
    markets: [],
    fetchDone: false,
    showNext: false,
    showError: false,
  };

  handleClose = () => {
    this.props.history.push('/');
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.primaryMarketData) {
      this.setState({
        primaryMarketData: nextProps.primaryMarketData,
        primaryMarketId: nextProps.primaryMarketId,
        fetchDone: nextProps.finished,
      });
    } else {
      this.setState({
        show: false,
        showError: true,
        fetchDone: nextProps.finished,
      });
    }
  }

  render() {
    // console.log(this.state);
    // while the app is fetching the data from database ==> show loading status
    if (!this.state.fetchDone) {
      return (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      );
    } else {
      {
        /* prompt the user to set up Market module before coming to this module if the users haven't done that
          this will prevent the app from crashing when no data is fetched from database*/
      }
      if (this.state.showError) {
        return (
          <Container>
            <Alert
              show={this.state.showError}
              variant='warning'
              onClose={() => this.props.history.push('/')}
              dismissible
            >
              <Alert.Heading>Please set up your market first!!</Alert.Heading>
              <hr />
              <div className='d-flex justify-content-end'>
                <Button
                  onClick={() => this.props.history.push('/market')}
                  variant='outline-light'
                >
                  Set up market
                </Button>
              </div>
            </Alert>
          </Container>
        );
      }
      // else show the next modal for input data
      if (this.state.show) {
        return (
          <Modal show={this.state.show} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Market of reference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              We will start with your primary market{' '}
              {this.state.primaryMarketData.marketName}. You will afterwards be
              able to provide this information for each market.
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button
                variant='primary'
                onClick={() => {
                  this.setState({ showNext: true, show: false });
                }}
              >
                Next
              </Button>
            </Modal.Footer>
          </Modal>
        );
      } else if (this.state.showNext) {
        // show the next modal for input data
        return <PrimaryMarketNeed />;
      }
    }
  }
}

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    marketData: state.market.marketData,
    isAuthenticated: state.auth.token !== null,
    primaryMarketId: state.market.primaryMarket,
    primaryMarketData: state.market.primaryMarketData,
    finished: state.market.finished,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchData: (token, userId) =>
      dispatch(actions.fetchMarket(token, userId)),
    onFetchPrimaryMarket: (token, userId) => {
      dispatch(actions.fetchPrimaryMarket(token, userId));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RefMarket)
);
