import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { } from 'react-bootstrap/Form';
import {  Button, ButtonGroup, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import Modal from "react-bootstrap/Modal";
import { Multiselect } from 'react-widgets'
import 'react-widgets/dist/css/react-widgets.css'; // Adds the styling for MultiSelect
import Container from "../../hoc/container";
// utilities
// This is made by Mario Lopez, for any questions message in Slack or email at mario@helloadvisr.com
// Overview: This file enables the user to enter Geo-info one step at a time from Continent to Loc
//  also lets the user skip this section of the MVP as a whole or any part of Geo-Info
// Related files: store/actions/geo.js, store/reducers/geo.js
// Geo and related files are called in index.js and App.js
// Note: this file assumes the user is logged in to be used.
class Geo extends Component {
    //opens is uses to keep track of the booleans needed to open and close the modals
    // opensIndex is used to keep track to of the currnet modals need to be opened and closed. 
    state = {
        primaryContinent: '',
        extraContinents: '',
        primaryCountry: '',
        extraCountrys: '',
        primaryCity: '',
        extraCitys: '',
        primaryLoc: '',
        extraLocs: '',
        OnOffs: [false, false, false, false],
        ChangeInPrices: ['na', 'na', 'na', 'na'],
        opens: [false, false, false, false],
        opensIndex: 0,
    };
    // For most cases this just updates the state values with the values entered by the user
    onChangedHandler = (event) => {

        this.setState({
            ...this.state,
            [event.target.id]: event.target.value,
        });

    };

    //TODO onChangeHandlerToggler needs to be refactored to be simpler and not to repeat so much
    //  code
    // This funciton updates the arrays in the states(OnOffs, ChangeInPrices) as needed to reflect
    //  what the user has entered.
    onChangedHandlerToggle = (num) => {
        // console.log("Got into toggle + ");
        // console.log(num);

        if (num === 1) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[0] = 'yes';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 2) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[0] = 'no';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 4) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[1] = 'yes';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 5) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[1] = 'no';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 7) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[2] = 'yes';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 8) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[2] = 'no';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 10) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[3] = 'yes';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        } else if (num === 11) {
            let ChangeInPrices = [...this.state.ChangeInPrices];
            ChangeInPrices[3] = 'no';
            this.setState({
                ...this.state,
                ChangeInPrices: ChangeInPrices,
            });
        }


    };

    // This saves the state to the redux for latter use of this data by the web-app if needed.
    //  it also saves the newData to the fireStore along with the user_id
    onNewData = (event) => {

        event.preventDefault();
        const opensNew = this.state.opens.slice();
        opensNew[3] = false;
        this.setState({
            ...this.state,
            opens: opensNew,
            opensIndex: 0,
        })
        console.log(this.props.token);
        this.props.ADD_GEO_INFOMATION(
            this.state.primaryContinent,
            this.state.extraContinents,
            this.state.primaryCountry,
            this.state.extraCountrys,
            this.state.primaryCity,
            this.state.extraCitys,
            this.state.primaryLoc,
            this.state.extraLocs,
            this.state.OnOffs,
            this.state.ChangeInPrices
        );
        const newData = {
            geoData: {
                primaryContinent: this.state.primaryContinent,
                extraContinents: this.state.extraContinents,
                primaryCountry: this.state.primaryCountry,
                extraCountrys: this.state.extraCountrys,
                primaryCity: this.state.primaryCity,
                extraCitys: this.state.extraCitys,
                primaryLoc: this.state.primaryLoc,
                extraLocs: this.state.extraLocs,
                OnOffs: this.state.OnOffs,
                ChangeInPrices: this.state.ChangeInPrices,

            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
        this.props.onSubmitGeo(newData, this.props.token);
        // TODO: the push/redirect to the next page
        // console.log(this.props.auth);
    };


    lastSkip = (event) => {

        event.preventDefault();
        const opensNew = this.state.opens.slice();
        opensNew[3] = false;
        const onOffsNew = [...this.state.OnOffs];
        onOffsNew[3] = true;
        this.setState({
            ...this.state,
            opens: opensNew,
            OnOffs: onOffsNew,
            opensIndex: 0,
        })
        // console.log(this.props.token);
        this.props.ADD_GEO_INFOMATION(
            this.state.primaryContinent,
            this.state.extraContinents,
            this.state.primaryCountry,
            this.state.extraCountrys,
            this.state.primaryCity,
            this.state.extraCitys,
            this.state.primaryLoc,
            this.state.extraLocs,
            this.state.OnOffs,
            this.state.ChangeInPrices
        );
        console.log("Saved data");
        const newData = {
            geoData: {
                ...this.state,
            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
        this.props.onSubmitGeo(newData, this.props.token);
        //TODO: the push/redirect to the next page
        // console.log(this.props.auth);
    };

    // This fuction handles the logic of what happens when the user wants to skip
    //  a given section of the Geo-Info page. It first makes copies of the relevant 
    //  states to be changed, then by looking at then target's id it lables which state is
    //  skipped by the user. Then saves the new info to the state.
    skipGeoPage = (event) => {
        /**Set geo info to default and skip to the next page */
        const onOffsNew = [...this.state.OnOffs];
        var opensTemp = this.state.opensIndex;
        console.log(opensTemp);
        onOffsNew[this.state.opensIndex] = true;
        console.log("one more false now at ");
        console.log(event.target.id);
        const opensNew = this.state.opens.slice();

        if (opensTemp === 0) {// this handles the case the user wants to enter Geo-Info again
            opensNew[4] = false;
        }

        if (opensTemp === 4) {
            opensTemp = 0
        } else {
            opensTemp = opensTemp + 1;
        }



        if (event.target.id === "continentModal") {
            onOffsNew[0] = true;
        }

        if (event.target.id === "countryModal") {
            onOffsNew[1] = true;
        }

        if (event.target.id === "cityModal") {
            onOffsNew[2] = true;
        }
        // Note the case for index 3 is handled in lastskip.
        // For testing
        // console.log(opensTemp);
        // console.log(onOffsNew);

        if (opensTemp === 0) {
            opensNew[opensTemp] = true;
            opensNew[4] = false;    // in case the user wants to enter the geo info again
        } else {
            opensNew[opensTemp] = true;
            opensNew[opensTemp - 1] = false;
        }

        //prevstate is to prevent some werid error where opens index sometimes skips a number.
        if (opensTemp === 4) {
            this.setState(prevstate => {
                return {
                    ...this.state,
                    opens: opensNew,
                    OnOffs: onOffsNew,
                    opensIndex: 0,
                }
            })
        } else {

            this.setState(prevstate => {
                return {
                    ...this.state,
                    opens: opensNew,
                    OnOffs: onOffsNew,
                    opensIndex: opensTemp,
                }
            })

        }

        // console.log(this.state.opensIndex);
        // console.log("index is ");
        // console.log(opensTemp);

    };
    // Prevents the current page form reloading to prevent all the modals from closing
    handleOpen = (event) => {
        event.preventDefault();
        // const opensN = this.state.opens.slice()
        this.handleOpenNum(0);
    };

    // Opens the model as needed by passing the needed number.
    handleOpenNum = (num) => {
        const opensN = this.state.opens.slice()
        opensN[num] = true;
        this.setState({

            opens: opensN,
        })
        console.log(this.state.opens);
        console.log(opensN);
        console.log("sdakjhbhasd");
    }

    // Handles logic from one modal closing to opening the next one.
    onNext = (event) => {
        //  Prevents the page from reloading since it is a form
        event.preventDefault();
        // makes copies of the relevant states 
        const opensNew = this.state.opens.slice();
        var opensTemp = this.state.opensIndex;



        if (opensTemp === 0) {// this handles the case the user wants to enter Geo-Info again
            opensNew[4] = false;
        }

        if (opensTemp === 4) {
            opensTemp = 0
        } else {
            opensTemp = opensTemp + 1;
        }

        opensNew[opensTemp] = true;
        opensNew[opensTemp - 1] = false;


        this.setState({
            ...this.state,
            opens: opensNew,
            opensIndex: opensTemp
        })
        //  console.log(this.state.opens);
        //  console.log(opensNew);
        //  console.log("sdakjhbhasd");

    };

    // Handles the logic of what happens if the user want to skip all of Geo-info
    //  sets all the relevant values to true then saves it.
    skipAll = (event) => {
        this.setState({
            ...this.setState,
            OnOffs: [true, true, true, true],
        })

        const newData = {
            geoData: {
                ...this.state,
            },
            userId: this.props.userId,
            timestamp: Date.now(),
        }
        this.props.onSubmitGeo(newData, this.props.token);

        // TODO: the push/redirect to the next page


    }

    onChangedMulti = (event) => {
        console.log(event.id);
        console.log("hello");

        if (this.state.opens[0] === true) {
            this.setState({
                ...this.state,
                extraContinents: event,
            })
        }
        if (this.state.opens[1] === true) {
            this.setState({
                ...this.state,
                extraCountrys: event,
            })
        }

    }

    render() {

        return (
            <React.Fragment>
                <Container>
            <div className={'text-center'}>
                <form className='form-signin'>

                    {/* The top button begins the process of entering Geo-info for the user, the
                    user also has the option to skip this seciton if they want to
            */}
                    <h1>Geographic Information</h1>
                    <h2>
                        Where does or will your company operate?
            </h2>
                    <h5>
                        You can skip ahead and return later.
            </h5>
                    <button className='btn btn-lg btn-primary btn-block' onClick={this.handleOpen}>Enter Geo-Info</button>
                    <button className='btn btn-lg btn-primary btn-block' onClick={this.skipAll}>Skip Geo-Info</button>


                    {/*  The bellow modal handles the continent information being entered.
                This modal lets the user enter data through a dropdown menu, 
                with a skip option if the user does not want to enter this info. 
        */}
 {this.state.opens[0]? (
            <Modal
              show={this.state.opens[0]}
              //onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Geo Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className='form-signin' onSubmit={this.onNext}>

                            <div className='text-center'>
                                <h1 className='h3 mb-3 font-weight-normal'>Your Geographies: Continent</h1>


                            </div>

                            <button className='btn btn-lg btn-primary btn-block' type='submit'>
                                Continue
					</button>
                            <button className='btn btn-lg btn-primary btn-block' id='continentModal' onClick={this.skipGeoPage}>
                                Skip
					</button>

                            <div className='form-label-group'>
                                <Row >

                                    <h2>
                                        Which continent do you primarily operate?
                        </h2>
                                    <select
                                        id='primaryContinent'
                                        className='form-control'
                                        onChange={this.onChangedHandler}
                                        defaultValue='default'>
                                        <option value='default' disabled>
                                            Primary continent
							</option>
                                        <option value="North America">North America</option>
                                        <option>Global (Online)</option>
                                        <option>Asia</option>
                                        <option>Europe</option>
                                        <option>Africa</option>
                                        <option>Oceania</option>
                                        <option>Antartica</option>
                                        <option>South America</option>
                                    </select>

                                    <p className="center">
                                        Would you price differently on continents?{' '}
                                        <ButtonGroup size="sm">
                                            <Button id="changeinPriceContinetT" onClick={() => { this.onChangedHandlerToggle(1) }}>Yes</Button>
                                            <Button id="changeInPriceContinentF" onClick={() => { this.onChangedHandlerToggle(2) }}>No</Button>
                                        </ButtonGroup>
                                    </p>
                                    <h3>
                                        Do you have another continents do you operate as well?
                        </h3>
                                    <Multiselect
                                        id='extraContinents'
                                        className='form-control'
                                        onChange={this.onChangedMulti}
                                        data={['North America', 'Global (Online)', 'Asia',
                                            'Europe', 'Africa', 'Oceania', 'Antartica', 'South America']}
                                    />


                                </Row>

                            </div>

                        </form>
              </Modal.Body>
              <Modal.Footer>
               
              </Modal.Footer>
            </Modal>
          ) : null}
                   
                   {this.state.opens[1]? (
            <Modal
              show={this.state.opens[1]}
              //onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Geo Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form className='form-signin' onSubmit={this.onNext}>
                            <div className='form-label-group'>


                                <div className='text-center'>
                                    <h1 className='h3 mb-3 font-weight-normal'>Your Geographies: Country</h1>


                                </div>
                                <Row>
                                    <button className='btn btn-lg btn-primary btn-block' type='submit'>
                                        Continue
					</button>
                                    <button className='btn btn-lg btn-primary btn-block' id="countryModal" onClick={this.skipGeoPage}>
                                        Skip
					</button>
                                </Row>

                                <h2>
                                    Which country do you primarily operate?
                        </h2>
                                <Row >

                                    <select
                                        id='primaryCountry'
                                        className='form-control'
                                        onChange={this.onChangedHandler}
                                        defaultValue='default'>
                                        <option value='default' disabled>
                                            Primary Country
							</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        {/*The below is just a list of counties*/}
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="American Samoa">American Samoa</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Anguilla">Anguilla</option>
                                        <option value="Antartica">Antarctica</option>
                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Aruba">Aruba</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bermuda">Bermuda</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Bouvet Island">Bouvet Island</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Cayman Islands">Cayman Islands</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Christmas Island">Christmas Island</option>
                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Congo">Congo, the Democratic Republic of the</option>
                                        <option value="Cook Islands">Cook Islands</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                        <option value="Faroe Islands">Faroe Islands</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="France Metropolitan">France, Metropolitan</option>
                                        <option value="French Guiana">French Guiana</option>
                                        <option value="French Polynesia">French Polynesia</option>
                                        <option value="French Southern Territories">French Southern Territories</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Gibraltar">Gibraltar</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Greenland">Greenland</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guadeloupe">Guadeloupe</option>
                                        <option value="Guam">Guam</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Democratic People's Republic of Korea">Korea, Democratic People's Republic of</option>
                                        <option value="Korea">Korea, Republic of</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Lao">Lao People's Democratic Republic</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon" selected>Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Macau">Macau</option>
                                        <option value="Macedonia">Macedonia, The Former Yugoslav Republic of</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Martinique">Martinique</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mayotte">Mayotte</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                        <option value="Moldova">Moldova, Republic of</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montserrat">Montserrat</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                        <option value="New Caledonia">New Caledonia</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="Niue">Niue</option>
                                        <option value="Norfolk Island">Norfolk Island</option>
                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Pitcairn">Pitcairn</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Puerto Rico">Puerto Rico</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Reunion">Reunion</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russian Federation</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Georgia">South Georgia and the South Sandwich Islands</option>
                                        <option value="Span">Spain</option>
                                        <option value="SriLanka">Sri Lanka</option>
                                        <option value="St. Helena">St. Helena</option>
                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                        <option value="Swaziland">Swaziland</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syrian Arab Republic</option>
                                        <option value="Taiwan">Taiwan, Province of China</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tokelau">Tokelau</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Viet Nam</option>
                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                        <option value="Western Sahara">Western Sahara</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>

                                    <p className="center">
                                        Would you price differently on countries?{' '}
                                        <ButtonGroup size="sm">
                                            <Button id="changeInPriceCountryT" onClick={() => { this.onChangedHandlerToggle(4) }}>Yes</Button>
                                            <Button id="changeInPriceCountryF" onClick={() => { this.onChangedHandlerToggle(5) }}>No</Button>
                                        </ButtonGroup>
                                    </p>

                                    <h3>
                                        Do you have another countries do you operate as well?
                        </h3>
                                    <Multiselect
                                        id='extraContinents'
                                        className='form-control'
                                        sele
                                        onChange={this.onChangedMulti}
                                        data={["Afghanistan",
                                            "Albania",
                                            "Algeria",
                                            "Andorra",
                                            "Angola",
                                            "Antigua and Barbuda",
                                            "Argentina",
                                            "Armenia",
                                            "Australia",
                                            "Austria",
                                            "Azerbaijan",
                                            "Bahamas",
                                            "Bahrain",
                                            "Bangladesh",
                                            "Barbados",
                                            "Belarus",
                                            "Belgium",
                                            "Belize",
                                            "Benin",
                                            "Bhutan",
                                            "Bolivia",
                                            "Bosnia and Herzegovina",
                                            "Botswana",
                                            "Brazil",
                                            "Brunei",
                                            "Bulgaria",
                                            "Burkina Faso",
                                            "Burundi",
                                            "Cambodia",
                                            "Cameroon",
                                            "Canada",
                                            "Cape Verde",
                                            "Central African Republic",
                                            "Chad",
                                            "Chile",
                                            "China",
                                            "Colombi",
                                            "Comoros",
                                            "Congo (Brazzaville)",
                                            "Congo",
                                            "Costa Rica",
                                            "Cote d'Ivoire",
                                            "Croatia",
                                            "Cuba",
                                            "Cyprus",
                                            "Czech Republic",
                                            "Denmark",
                                            "Djibouti",
                                            "Dominica",
                                            "Dominican Republic",
                                            "East Timor (Timor Timur)",
                                            "Ecuador",
                                            "Egypt",
                                            "El Salvador",
                                            "Equatorial Guinea",
                                            "Eritrea",
                                            "Estonia",
                                            "Ethiopia",
                                            "Fiji",
                                            "Finland",
                                            "France",
                                            "Gabon",
                                            "Gambia, The",
                                            "Georgia",
                                            "Germany",
                                            "Ghana",
                                            "Greece",
                                            "Grenada",
                                            "Guatemala",
                                            "Guinea",
                                            "Guinea-Bissau",
                                            "Guyana",
                                            "Haiti",
                                            "Honduras",
                                            "Hungary",
                                            "Iceland",
                                            "India",
                                            "Indonesia",
                                            "Iran",
                                            "Iraq",
                                            "Ireland",
                                            "Israel",
                                            "Italy",
                                            "Jamaica",
                                            "Japan",
                                            "Jordan",
                                            "Kazakhstan",
                                            "Kenya",
                                            "Kiribati",
                                            "Korea, North",
                                            "Korea, South",
                                            "Kuwait",
                                            "Kyrgyzstan",
                                            "Laos",
                                            "Latvia",
                                            "Lebanon",
                                            "Lesotho",
                                            "Liberia",
                                            "Libya",
                                            "Liechtenstein",
                                            "Lithuania",
                                            "Luxembourg",
                                            "Macedonia",
                                            "Madagascar",
                                            "Malawi",
                                            "Malaysia",
                                            "Maldives",
                                            "Mali",
                                            "Malta",
                                            "Marshall Islands",
                                            "Mauritania",
                                            "Mauritius",
                                            "Mexico",
                                            "Micronesia",
                                            "Moldova",
                                            "Monaco",
                                            "Mongolia",
                                            "Morocco",
                                            "Mozambique",
                                            "Myanmar",
                                            "Namibia",
                                            "Nauru",
                                            "Nepal",
                                            "Netherlands",
                                            "New Zealand",
                                            "Nicaragua",
                                            "Niger",
                                            "Nigeria",
                                            "Norway",
                                            "Oman",
                                            "Pakistan",
                                            "Palau",
                                            "Panama",
                                            "Papua New Guinea",
                                            "Paraguay",
                                            "Peru",
                                            "Philippines",
                                            "Poland",
                                            "Portugal",
                                            "Qatar",
                                            "Romania",
                                            "Russia",
                                            "Rwanda",
                                            "Saint Kitts and Nevis",
                                            "Saint Lucia",
                                            "Saint Vincent",
                                            "Samoa",
                                            "San Marino",
                                            "Sao Tome and Principe",
                                            "Saudi Arabia",
                                            "Senegal",
                                            "Serbia and Montenegro",
                                            "Seychelles",
                                            "Sierra Leone",
                                            "Singapore",
                                            "Slovakia",
                                            "Slovenia",
                                            "Solomon Islands",
                                            "Somalia",
                                            "South Africa",
                                            "Spain",
                                            "Sri Lanka",
                                            "Sudan",
                                            "Suriname",
                                            "Swaziland",
                                            "Sweden",
                                            "Switzerland",
                                            "Syria",
                                            "Taiwan",
                                            "Tajikistan",
                                            "Tanzania",
                                            "Thailand",
                                            "Togo",
                                            "Tonga",
                                            "Trinidad and Tobago",
                                            "Tunisia",
                                            "Turkey",
                                            "Turkmenistan",
                                            "Tuvalu",
                                            "Uganda",
                                            "Ukraine",
                                            "United Arab Emirates",
                                            "United Kingdom",
                                            "United States",
                                            "Uruguay",
                                            "Uzbekistan",
                                            "Vanuatu",
                                            "Vatican City",
                                            "Venezuela",
                                            "Vietnam",
                                            "Yemen",
                                            "Zambia",
                                            "Zimbabwe"]}
                                    />

                                </Row>


                            </div>


                        </form>
              </Modal.Body>
              <Modal.Footer>
               
              </Modal.Footer>
            </Modal>
          ) : null}

                    {/* The below modal handles the case of the user entering the Geo-info for 
                a given country, if the user wanted to skip this seciton they can move onto the
                next one
             */}
                    

                    {/* This modal handle the user entering the Geo-info for citys, again the user can 
                Skip this seciton if they want. */}
                {this.state.opens[2]? (
            <Modal
              show={this.state.opens[2]}
              //onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Geo Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form className='form-signin' onSubmit={this.onNext}>

<div className='text-center'>
    <h1 className='h3 mb-3 font-weight-normal'>Your Geographies: City</h1>
    <Row>
        <button className='btn btn-lg btn-primary btn-block' type='submit'>
            Continue
</button>
        <button className='btn btn-lg btn-primary btn-block' id='cityModal' onClick={this.skipGeoPage}>
            Skip
</button>
    </Row>
    <h2>
        Which city do you primarily operate?
</h2>
    <h3>
        Do you have another city do you operate as well?
</h3>
</div>
<div className='form-label-group'>
    <Row>
        <Col>
            <input
                type='text'
                id='primaryCity'
                className='form-control'
                placeholder='First name'
                onChange={this.onChangedHandler}


            />
            <label htmlFor='primaryCity'> Primary City </label>
        </Col>
        <Col>
            <input
                type='text'
                id='extraCity'
                className='form-control'
                placeholder='First name'
                onChange={this.onChangedHandler}

            />
            <label htmlFor='extraCity'>Extra City</label>
        </Col>
    </Row>
    <Row>
        <p className="center">
            Would you price differently on cities?{' '}
            <ButtonGroup size="sm">
                <Button id="changeInPriceCityT" onClick={() => { this.onChangedHandlerToggle(7) }}>Yes</Button>
                <Button id="changeInPriceCityF" onClick={() => { this.onChangedHandlerToggle(8) }}>No</Button>
            </ButtonGroup>
        </p>
    </Row>
</div>

</form>
              </Modal.Body>
              <Modal.Footer>
               
              </Modal.Footer>
            </Modal>
          ) : null}

{this.state.opens[3]? (
            <Modal
              show={this.state.opens[3]}
              //onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Geo Information</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <form className='form-signin' onSubmit={this.onNewData}>

<div className='text-center'>
    <h1 className='h3 mb-3 font-weight-normal'>Your Geographies: Location</h1>
    <Row>
        <button className='btn btn-lg btn-primary btn-block' type='submit'>
            Continue
</button>
        <button className='btn btn-lg btn-primary btn-block' onClick={this.lastSkip}>
            Skip
</button>
    </Row>
    <h2>
        Which Location do you primarily operate?
</h2>
    <h3>
        Do you have another location do you operate as well?
</h3>
</div>
<div className='form-label-group'>
    <Row>
        <Col>
            <input
                type='text'
                id='primaryLoc'
                className='form-control'
                placeholder='First name'
                onChange={this.onChangedHandler}

            />
            <label htmlFor='primaryLoc'> Primary Location </label>
        </Col>
        <Col>
            <input
                type='text'
                id='extraLoc'
                className='form-control'
                placeholder='First name'
                onChange={this.onChangedHandler}

            />
            <label htmlFor='extraLoc'>Extra Location</label>
        </Col>
    </Row>
    <Row>
        <p className="center">
            Would you price differently on locations?{' '}
            <ButtonGroup size="sm">
                <Button variant="light" id="changeInPriceLocT" onClick={() => { this.onChangedHandlerToggle(10) }}>Yes</Button>
                <Button variant="secondary" id="changeInPriceLocF" onClick={() => { this.onChangedHandlerToggle(11) }}>No</Button>
            </ButtonGroup>
        </p>
    </Row>
</div>

</form>
              </Modal.Body>
              <Modal.Footer>
               
              </Modal.Footer>
            </Modal>
          ) : null}

                  

                </form>
            </div>
            </Container>
            </React.Fragment>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        data: state.data.data,
        userId: state.auth.userId,
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        finished: state.data.finished,
        costId: state.data.costid,
        open: false,
    };
};


const mapDispathToProps = (dispatch) => {
    return {
        ADD_GEO_INFOMATION: (primaryContinent, extraContinents, primaryCountry, extraCountrys, primaryCity, extraCitys, primaryLoc, extraLocs, OnOffs, ChangeInPrices) =>
            dispatch(
                actions.ADD_GEO_INFOMATION(
                    primaryContinent,
                    extraContinents,
                    primaryCountry,
                    extraCountrys,
                    primaryCity,
                    extraCitys,
                    primaryLoc,
                    extraLocs,
                    OnOffs,
                    ChangeInPrices
                )
            ),
        onSubmitGeo: (data, token) => dispatch(actions.submitGeo(data, token)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispathToProps)(Geo)
);