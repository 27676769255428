import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Table from 'react-bootstrap/Table';
import Container from '../../hoc/container';
import Button from 'react-bootstrap/Button';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import './CompetitionTable.css';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

class CompetitionTable extends Component {
  constructor(props) {
    super(props);
    this.props.fetchPrimaryCompetitor(this.props.token, this.props.userId);
    this.props.onFetchCompetitor(this.props.token, this.props.userId);
  }
  state = {
    data: [],
    fetchDone: false,
    showAddModal: false,
    showDeleteMessage: false,
    showEditModal: false,
    competitorName: '',
    selectingCompetitor: null,
    selected: "",
    primaryId: "",
  };

  renderCompetitors = (competitor, index) => {
    return (
      <tr key={competitor.competitorId}>
        <td>{competitor.competitorName}</td>
        <td>
          <input
            type='radio'
            name='selected'
            value={competitor.competitorId}
            checked={this.state.selected === competitor.competitorId}
            onChange={this.handleOptionChange}
          />
        </td>
        <td>
          <Button
            variant='outline-dark'
            size='sm'
            onClick={() => this.onEdit(competitor)}
          >
            <Edit />
          </Button>
          <Button
            variant='outline-dark'
            size='sm'
            onClick={() => this.onDelete(competitor)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };

  componentWillReceiveProps(nextProps) {
    const data = nextProps.competitors.map((elem) => {
      return { ...elem.competitorInfo, competitorId: elem.id };
    });

    this.setState({
      data: data,
      fetchDone: true,
      selected: nextProps.primaryCompetitor,
      primaryId: nextProps.primaryId
    });
  }

  // handle closing the modal
  handleClose = () => {
    this.setState({
      showAddModal: false,
      showDeleteMessage: false,
      showEditModal: false,
    });
  };

  // handle clicking add buttion
  onAdd = () => {
    this.setState({ showAddModal: true });
  };

  // handle clicking edit button
  onEdit = (competitor) => {
    this.setState({ showEditModal: true, selectingCompetitor: competitor });
  };

  // handle clicking delete button
  onDelete = (competitor) => {
    this.setState({ showDeleteMessage: true, selectingCompetitor: competitor });
  };

  // handle user change primary competitor
  handleOptionChange = async (e) => {
    const newPrimaryId = e.target.value; // id of competitior in competitorValue (competitor data)
    const primaryId = this.state.primaryId; // id of primaryCompetitor used for updating primaryCompetitorValue in database

    // find the new primary competitor
    const newPrimaryCompetitor = this.state.data.filter(
      (competitor) => competitor.competitorId === newPrimaryId
    )[0];

    // data that will be store in database
    const toServer = {
      competitorId: newPrimaryCompetitor.competitorId,
      competitorInfo: { competitorName: newPrimaryCompetitor.competitorName },
      userId: this.props.userId,
    };

    // update the primaryCompetitorValue in database with new data
    await this.props.updatePrimaryCompetitor(
      primaryId,
      toServer,
      this.props.token
    );

    // select the new competitor in the table
    this.setState({ selected: newPrimaryId });
  };

  // handle deleting competitor
  handleDeleteData = async (id) => {
    await this.props.onDeleteCompetitor(id, this.props.token);

    // remaining data after delete the selected competitor
    const dataDelete = this.state.data.filter(
      (competitor) => competitor.competitorId !== id
    );

    // update state and state.data
    this.setState({
      data: [...dataDelete],
      showDeleteMessage: false,
      selectingCompetitor: null,
    });
  };

  // handle input change when user type in the input field
  handleInputChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // handle adding new competitor
  handleSubmitData = async (e) => {
    e.preventDefault();
    const { competitorName } = this.state;

    // new data that will be added to the database
    const newData = {
      competitorInfo: { competitorName },
      userId: this.props.userId,
    };

    // submit new competitor
    await this.props.onSubmitCompetitor(newData, this.props.token);

    // update state
    this.setState({
      data: this.props.competitors.map((elem) => {
        return { ...elem.competitorInfo, competitorId: elem.id };
      }),
      showAddModal: false,
    });
  };

  // handle editing current competitor
  handleEditData = async (oldData) => {
    const competitorId = oldData.competitorId;
    const newData = {
      ...oldData.competitorInfo,
      competitorName: this.state.competitorName,
    };
    let toServer = {
      competitorInfo: { ...newData },
      userId: this.props.userId,
    };
    await this.props.onUpdateCompetitor(
      competitorId,
      toServer,
      this.props.token
    );

    // if user edit the primary competitor
    // update primary competitor data in primaryCompetitorValue in database
    if (competitorId === this.state.selected) {
      const primaryCompetitior = { ...toServer, competitorId: competitorId };
      // console.log(primaryCompetitior);
      await this.props.updatePrimaryCompetitor(
        this.state.primaryId,
        primaryCompetitior,
        this.props.token
      );
    }
    this.setState({
      showEditModal: false,
      competitorName: '',
      selectingCompetitor: null,
    });
  };
  render() {
    // console.log(this.state);
    if (!this.state.fetchDone) {
      return (
        <Spinner animation='border' role='status'>
          <span className='sr-only'>Loading...</span>
        </Spinner>
      );
    } else {
      return (
        <Container>
          <Table striped bordered hover size='sm'>
            <thead>
              <tr>
                <th>Competitor Name</th>
                <th>Primary</th>
                <th id='action'>Action</th>
              </tr>
            </thead>
            <tbody>{this.state.data.map(this.renderCompetitors)}</tbody>
          </Table>
          <Button variant='primary' onClick={this.onAdd}>
            Add Competitor
          </Button>
          {this.state.showAddModal ? (
            <Modal
              show={this.state.showAddModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Competitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>Add competitor: </label>
                  <input
                    type='text'
                    placeholder='Enter your secondary competitor'
                    name='competitorName'
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant='primary' onClick={this.handleSubmitData}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          {/* handle edit button */}
          {this.state.showEditModal ? (
            <Modal
              show={this.state.showEditModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Competitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>Edit competitor name: </label>
                  <input
                    type='text'
                    placeholder='Enter your secondary competitor'
                    name='competitorName'
                    onChange={this.handleInputChange}
                    defaultValue={this.state.selectingCompetitor.competitorName}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant='primary'
                  onClick={() =>
                    this.handleEditData(this.state.selectingCompetitor)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          {/* handle delete button */}
          {this.state.showDeleteMessage ? (
            <Modal
              show={this.state.showDeleteMessage}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning!!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this competitor?
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant='danger'
                  onClick={() =>
                    this.handleDeleteData(
                      this.state.selectingCompetitor.competitorId
                    )
                  }
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    competitors: state.competitor.competitors,
    primaryCompetitor: state.competitor.primaryCompetitor,
    primaryId: state.competitor.primaryId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCompetitor: (data, token) => {
      dispatch(actions.fetchCompetitor(data, token));
    },
    onSubmitCompetitor: (data, token) => {
      dispatch(actions.submitCompetitorInfo(data, token));
    },
    onDeleteCompetitor: (competitorId, token) => {
      dispatch(actions.deleteCompetitor(competitorId, token));
    },
    onUpdateCompetitor: (competitorId, data, token) =>
      dispatch(actions.updateCompetitor(competitorId, data, token)),
    updatePrimaryCompetitor: (primaryCompetitorId, data, token) => {
      dispatch(
        actions.updatePrimaryCompetitor(primaryCompetitorId, data, token)
      );
    },
    submitPrimaryCompetitor: (data, token) => {
      dispatch(actions.submitPrimaryCompetitor(data, token));
    },
    fetchPrimaryCompetitor: (token, userId) => {
      dispatch(actions.fetchPrimaryCompetitor(token, userId));
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompetitionTable)
);
