import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  featureList: [],
  finished: false,
  //primaryCompetitor: localStorage.getItem("primaryCompetitor"),
};

const startSubmitFeature = (state, action) => {
  return updateObject(state, {
    finished: false,
  });
};

const submitFeatureSuccess = (state, action) => {
  const newData = updateObject(action.feature, {
    id: action.featureId,
  });
  const test = updateObject(state, {
    finished: true,
    featureList: state.featureList.concat(newData),
  });
  return test;
};

const submitFeatureFail = (state, action) => {
  console.log(action.error);
};

const fetchFeatureSuccess = (state, action) => {
//console.log(action.featureList);
  const newData = [...action.featureList];
  //console.log(newData);
  return updateObject(state, {
    finished: true,
    featureList: newData,
  });
};

const deleteFeatureSuccess = (state, action) => {
  let index = -1;
  //console.log(state);
  const data = [...state.featureList];
  //console.log(data);
  for (let elem of data) {
    if (elem.id === action.featureId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data.splice(index, 1);
  }
  // console.log(data);

  return updateObject(state, {
    finished: true,
    featureList: data,
  });
};

const deleteFeatureFail = (state, action) => {
  console.log(action.error);
  return state;
};

const updateFeatureSuccess = (state, action) => {
  let index = -1;
  const data = [...state.featureList];
  const newData = updateObject(action.feature, { id: action.featureId });
  for (let elem of data) {
    if (elem.id === action.featureId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data[index] = newData;
  }
  return updateObject(state, {
    finished: true,
    featureList: data,
  });
};

const updateFeatureFail = (state, action) => {
  console.log(action.error);
  return state;
};

// const updatePrimaryCompetitor = (state, action) => {
//   console.log(action);
//   return updateObject(state, {
//     finished: true,
//     primaryCompetitor: action.newPrimaryCompetitorId,
//   });
// };
const reducer = (state = initialState, action) => {
  //   console.log("reducer");
  switch (action.type) {
    case actionTypes.START_SUBMIT_FEATURE:
      return startSubmitFeature(state, action);
    case actionTypes.SUBMIT_FEATURE_SUCCESS:
      return submitFeatureSuccess(state, action);
    case actionTypes.SUBMIT_FEATURE_FAIL:
      return submitFeatureFail(state, action);
    case actionTypes.FETCH_FEATURE_SUCCESS:
      return fetchFeatureSuccess(state, action);
    case actionTypes.DELETE_FEATURE_SUCCESS:
      return deleteFeatureSuccess(state, action);
    case actionTypes.DELETE_FEATURE_FAIL:
      return deleteFeatureFail(state, action);
    case actionTypes.UPDATE_FEATURE_SUCCESS:
      return updateFeatureSuccess(state, action);
    case actionTypes.UPDATE_FEATURE_FAIL:
      return updateFeatureFail(state, action);
    // case actionTypes.UPDATE_PRIMARY_COMPETITOR:
    //   return updatePrimaryCompetitor(state, action);
    default:
      return state;
  }
};

export default reducer;
