import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Container from "../../hoc/container";
import "./PriceSensitivity.css";

// import Dropdown from "react-bootstrap/Dropdown";

class PriceSensitivity extends Component {
  state = {
    title: ["Independent School", "Activity Vendors"],
  };

  renderSensitiveSelection = (selection) => {
    return (
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Form.Label>{selection}</Form.Label>
        <Form.Control as="select">
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
        </Form.Control>
      </Form.Group>
    );
  };


  renderPriceIncrease = (selection) =>{
    return (
        <Form.Group controlId="exampleForm.ControlSelect1">
          <Form.Label>{selection}</Form.Label>
          <Form.Control as="select">
            <option>Still purchase my product</option>
            <option>Still purchase but explore alternatives</option>
            <option>Not purchase</option>
          </Form.Control>
        </Form.Group>
      );
  }
  render() {
    return (
      <React.Fragment>
        <Container>
        <div className="price-selection">
          <div>
            On a scale of 1 (not at all) to 6 (extremely), how sensitive to
            price do you believe your customers are
          </div>
          {this.state.title.map(this.renderSensitiveSelection)}
        </div>
        <div className="price-selection">
          <div>If you increased your price by 10%, your customer would:</div>
          {this.state.title.map(this.renderPriceIncrease)}
        </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(PriceSensitivity);
