// Created by: Quang Nguyen
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as actions from "../../store/actions/index";
import "./AboutMe.css";
import "./Auth.css";
// utilities

class AboutMe extends Component {
  state = {
    companyName: "",
    firstName: "",
    lastName: "",
    goal: "",
    role: "",
    companyType: ""
  };

  onChangedHandler = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  onSubmitHandler = event => {
    event.preventDefault();
    this.props.onNextStep(
      this.state.companyName,
      this.state.firstName,
      this.state.lastName,
      this.state.goal,
      this.state.role,
      this.state.companyType
    );
    this.props.history.push("/auth");
  };

  render() {
    return (
      <div className={"Auth text-center"}>
        <form className="form-signin" onSubmit={this.onSubmitHandler}>
          <div className="text-center mb-4">
            <h1 className="h3 mb-3 font-weight-normal">About you</h1>
          </div>

          <div className="form-label-group">
            <input
              type="text"
              id="companyName"
              className="form-control"
              placeholder="Company Name"
              required
              autoFocus
              onChange={this.onChangedHandler}
            />
            <label htmlFor="companyName">Company name</label>
          </div>

          <div className="form-label-group">
            <input
              type="text"
              id="firstName"
              className="form-control"
              placeholder="First name"
              required
              onChange={this.onChangedHandler}
            />
            <label htmlFor="firstName">First name</label>
          </div>

          <div className="form-label-group">
            <input
              type="text"
              id="lastName"
              className="form-control"
              placeholder="Last name"
              required
              onChange={this.onChangedHandler}
            />
            <label htmlFor="lastName">Last name</label>
          </div>

          <div className="form-label-group">
            <select
              id="goal"
              className="form-control"
              onChange={this.onChangedHandler}
              defaultValue="default"
            >
              <option value="default" disabled>
                What is your main goals?
              </option>
              <option>
                Build new offers / packages / bundles / propositions using
                existing product- / service-features
              </option>
              <option>Price my first product / service</option>
              <option>Price an additional product / service</option>
              <option>
                Optimise / review existing price(s) for product / services
                already launched
              </option>
              <option>Price my product in a new channel, country</option>
              <option>Assess any gaps in my product / service portfolio</option>
              <option>
                Better track my pricing decisions, to take better decisions
              </option>
              <option>
                Conduct customer / market research for a new product / market.
                As such, I need to understand how to test pricing / get
                customers to tell me what price they would pay
              </option>
              <option>Other</option>
            </select>
          </div>

          <div className="form-label-group">
            <input
              type="text"
              id="role"
              className="form-control"
              placeholder="What is your role"
              required
              onChange={this.onChangedHandler}
            />
            <label htmlFor="role">What is your role?</label>
          </div>

          <div className="form-label-group">
            <select
              id="companyType"
              className="form-control"
              onChange={this.onChangedHandler}
              defaultValue="default"
            >
              <option value="default" disabled>
                What kind of company are you?
              </option>
              <option>Software and Internet</option>
              <option>Agriculture and Mining</option>
              <option>Business Services</option>
              <option>Computer and Electronics</option>
              <option>Consumer Services</option>
              <option>Education</option>
              <option>Energy and Utilities</option>
              <option>Financial Services</option>
              <option>Government</option>
              <option>Health, Pharmaceuticals, and Biotech</option>
              <option>Manufacturing</option>
              <option>Media and Entertainment</option>
              <option>Real Estate and Construction</option>
              <option>Retail</option>
              <option>Telecommunications</option>
              <option>Transportation and Storage</option>
              <option>Travel Recreation and Leisure</option>
              <option>Wholesale and Distribution</option>
              <option>Other</option>
            </select>
          </div>
          <button className="btn btn-lg btn-primary btn-block" type="submit">
            Continue
          </button>
          <div className="loginPrompt">
            Already has an account? <Link to="/auth">LogIn</Link>
          </div>
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onNextStep: (companyName, firstName, lastName, goal, role, companyType) =>
      dispatch(
        actions.nextStepSuccess(
          companyName,
          firstName,
          lastName,
          goal,
          role,
          companyType
        )
      )
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AboutMe));
