import * as actionTypes from './actionTypes';
import axios from '../../axios-instance';

// export const ADD_GEO_INFOMATION = (GEO_INFO) => {
//     return {
//         type: 'ADD_GEO_INFORMATION',
//         id: GEO_INFO
//     }
// }


export const ADD_GEO_INFOMATION = (primaryContinent, extraContinents, primaryCountry, extraCountrys, primaryCity, extraCitys, primaryLoc, extraLocs, OnOffs, ChangesInPrices) => {

    return{
        type: 'ADD_GEO_INFORMATION',
        primaryContinent: primaryContinent,
        extraContinents: extraContinents,
        primaryCountry: primaryCountry,
        extraCountrys: extraCountrys,
        primaryCity: primaryCity,
        extraCitys: extraCitys,
        primaryLoc: primaryLoc,
        extraLocs: extraLocs,
        OnOffs: OnOffs,
        ChangesInPrices: ChangesInPrices

    };
};
        
export const submitGeo = (newData, token) => {
	return async (dispatch) => {
		await axios.post('/geoValue.json?auth=' + token, newData)
			.then(response => {
				dispatch(submitGeoSuccess(response.data.name, newData))
			})
			.catch(error => {
				dispatch(submitGeoFail(error))
			})
	};
};

export const submitGeoSuccess = (id, geoData) => {
	return {
		type: actionTypes.SUBMIT_DATA_SUCCESS,
		geoId: id,
		geoData: geoData
	};
};

export const submitGeoFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error
	}
}