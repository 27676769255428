import * as actionTypes from './actionTypes';
import axios from '../../axios-instance';
import { fetchData } from './data';

// ***********************************************
// Primary market
// CRUD operators for primary market
// ***********************************************

// fetch primary market
export const fetchPrimaryMarket = (token, userId) => {
  return async (dispatch) => {
    const queryParams =
      '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
    const response = await axios.get('/primaryMarketValue.json' + queryParams);
    // console.log(response);

    const fetchedData = [];
    for (let key in response.data) {
      fetchedData.push({ ...response.data[key], id: key });
    }
    if(fetchedData.length !== 0){
      dispatch(fetchPrimaryMarketSuccess(fetchedData));
    } else {
      const error = 'Empty primary market'
      dispatch(fetchPrimaryMarketFail(error))
    }
  };
};

// fetch primary market success
export const fetchPrimaryMarketSuccess = (marketData) => {
  console.log(marketData);
  const data = marketData[0];
  const marketInfo = { ...data.marketInfo };
  const marketId = data.marketId;
  return {
    type: actionTypes.FETCH_PRIMARY_MARKET_SUCCESS,
    marketData: { marketId, marketInfo },
    primaryId: data.id,
  };
};
// fetch primary market fail
export const fetchPrimaryMarketFail = (error) => {
  return {
    type: actionTypes.FETCH_PRIMARY_MARKET_FAIL,
    error: error,
  };
};



// update primary market
export const updatePrimaryMarket = (marketId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(`/primaryMarketValue/${marketId}.json?auth=` + token, newData)
      .then((response) => {
        dispatch(updatePrimaryMarketSuccess(marketId, newData));
      })
      .catch((error) => {
        dispatch(updatePrimaryMarketFail(error));
      });
  };
};

// update primary market success
export const updatePrimaryMarketSuccess = (id, marketData) => {
  return {
    type: actionTypes.UPDATE_PRIMARY_MARKET_SUCCESS,
    primaryId: id,
    marketData: marketData,
  };
};

// update primary market fail
export const updatePrimaryMarketFail = (error) => {
  return {
    type: actionTypes.UPDATE_PRIMARY_MARKET_FAIL,
    error: error,
  };
};

// submit primary market
export const submitPrimaryMarket = (newData, token) => {
  return async (dispatch) => {
    await axios
      .post('/primaryMarketValue.json?auth=' + token, newData)
      .then((response) => {
        console.log(response);
        dispatch(submitPrimaryMarketSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitPrimaryMarketFail(error));
      });
  };
};

// submit primary market success
export const submitPrimaryMarketSuccess = (id, marketData) => {
  return {
    type: actionTypes.SUBMIT_PRIMARY_MARKET_SUCCESS,
    primaryId: id,
    marketData: marketData,
  };
};

// submit primary market fail
export const submitPrimaryMarketFail = (error) => {
  return {
    type: actionTypes.SUBMIT_PRIMARY_MARKET_FAIL,
    error: error,
  };
};

// ***********************************************
// Markets
// CRUD operators for markets
// ***********************************************

// submit single market
export const submitMarketInfo = (newData, token) => {
  return async (dispatch) => {
    await axios
      .post('/marketValue.json?auth=' + token, newData)
      .then((response) => {
        console.log(response);
        dispatch(submitMarketSuccess(response.data.name, newData));
      })
      .catch((error) => {
        dispatch(submitMarketFail(error));
      });
  };
};

// submit single market success
export const submitMarketSuccess = (id, marketData) => {
  return {
    type: actionTypes.SUBMIT_MARKET_SUCCESS,
    marketId: id,
    marketData: marketData,
  };
};

// submit single market fail
export const submitMarketFail = (error) => {
  return {
    type: actionTypes.SUBMIT_MARKET_FAIL,
    error: error,
  };
};

// fetch all markets
export const fetchMarket = (token, userId) => {
  return async (dispatch) => {
    const queryParams =
      '?auth=' + token + '&orderBy="userId"&equalTo="' + userId + '"';
    const response = await axios.get('/marketValue.json' + queryParams);
    // console.log("FETCH MARKET");
    const fetchedData = [];
    for (let key in response.data) {
      fetchedData.push({ ...response.data[key], id: key });
    }
    console.log(fetchedData);
    dispatch(fetchMarketSuccess(fetchedData));
  };
};

// fetch all markets success
export const fetchMarketSuccess = (marketData) => {
  return {
    type: actionTypes.FETCH_MARKET_SUCCESS,
    marketData: marketData,
  };
};

// delete single market
export const deleteMarket = (marketId, token) => {
  return async (dispatch) => {
    await axios
      .delete(`/marketValue/${marketId}.json?auth=` + token)
      .then((response) => {
        dispatch(deleteMarketSuccess(marketId));
      })
      .catch((error) => {
        dispatch(deleteMarketFail(error));
      });
  };
};

// delete single market success
export const deleteMarketSuccess = (id) => {
  return {
    type: actionTypes.DELETE_MARKET_SUCCESS,
    marketId: id,
  };
};

// delete single market fail
export const deleteMarketFail = (error) => {
  return {
    type: actionTypes.DELETE_MARKET_FAIL,
    error: error,
  };
};

// update single market
export const updateMarket = (marketId, newData, token) => {
  return async (dispatch) => {
    await axios
      .put(`/marketValue/${marketId}.json?auth=` + token, newData)
      .then((response) => {
        dispatch(updateMarketSuccess(marketId, newData));
      })
      .catch((error) => {
        dispatch(updateMarketFail(error));
      });
  };
};

// update single market success
export const updateMarketSuccess = (id, marketData) => {
  return {
    type: actionTypes.UPDATE_MARKET_SUCCESS,
    marketId: id,
    marketData: marketData,
  };
};

// update single market fail
export const updateMarketFail = (error) => {
  return {
    type: actionTypes.UPDATE_MARKET_FAIL,
    error: error,
  };
};
