import React, { Component } from 'react';
import { Scatter } from 'react-chartjs-2'


class ScatterChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData
    }
  }


  render() {
    // const chartOptions =  {
    //   scales : {
    //       y : {
    //           min: 1,
    //           max: 5,
    //       },
    //       x : {
    //         min: 1,
    //         max: 5,
    //     }
    //   }
  //};

    return (
      <div className="scatterChart">
        <h2>Percived Value vs. Strenght of competitive Advantage Chart</h2>
          <Scatter data={this.state.chartData}
          />
      </div>
    )
  }
}

export default ScatterChart;