import React from 'react';
import './Footer.css';
import Navbar from 'react-bootstrap/Navbar';
const Footer = () => {
	return (
		<Navbar bg='light' variant='light' sticky='bottom' className='footer'>
			© 2020 nDexio - nDexio is the trading name for KX Strategy, a company
			registered in England and Wales under company number 12707884
		</Navbar>
	);
};

export default Footer;
