export const currency = {
	USD: '$',
	EUR: '€',
	GBP: '£',
	CAD: 'C$',
	AUD: 'A$',
	CHF: 'CHF',
	AED: 'د.إ',
	BDT: '৳',
	BGN: 'лв',
	BRL: 'R$',
	'BTC or XBT': '₿',
	CLPL: '$',
	CNY: '¥ /元',
	COP: '$',
	CZKL: 'Kč',
	DKKL: 'kr',
	ETHL: 'Ξ',
	GEL: '₾',
	HKD: '$ / HK$ / “元”',
	HRK: 'kn',
	HUF: 'ft',
	IDR: 'Rp',
	ILS: '₪',
	INR: '₹',
	JPY: '¥',
	KES: 'Ksh',
	KRW: '₩',
	LKR: 'Rs',
	LTC: 'Ł',
	MAD: '.د.م',
	MXN: '$',
	MYR: 'RM',
	NGN: '₦',
	NOK: 'kr',
	NZD: '$',
	PEN: 'S/.',
	PHP: '₱',
	PKR: 'Rs',
	PLN: 'zł',
	RON: 'lei',
	RUB: '₽',
	SEK: 'kr',
	SGD: '$',
	THB: '฿',
	TRY: '₺',
	UAH: '₴',
	VND: '₫',
	XMR: 'ɱ',
	XRP: 'XRP',
	ZAR: 'R',
};
