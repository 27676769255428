import React from 'react';
import './RightPannel.css';

const RightPannel = () => {
	return (
		<div className='right_pannel'>
			<div className='company_name'>Company Name</div>
			<div className='others'>Others</div>
		</div>
	);
};

export default RightPannel;
