import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";
import Table from "react-bootstrap/Table";
import Container from "../../../hoc/container";
import Button from "react-bootstrap/Button";
import Edit from "@material-ui/icons/Edit";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
//import "./CompetitionTable.css";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import ScatterChart from "../../../components/Charts/scatterChart";
import { Tooltip } from '@material-ui/core';
import Toggle from 'react-toggle';
import Select from 'react-select';
import { Form, FormControl,  ButtonGroup, Row, Col , Container as Container2} from 'react-bootstrap';
import 'react-widgets/dist/css/react-widgets.css'; 
import "./toggleCSS.css"
import { Scatter } from 'react-chartjs-2'
import store from '../../../store/store';



class features extends Component {
  constructor(props) {
    super(props);
    this.props.onFetchCompetitor(this.props.token, this.props.userId);
    
    this.props.onFetchFeature(this.props.token, this.props.userId);
    
    this.props.onFetchCustomer(this.props.token, this.props.userId);

    console.log(store.getState().features);
  }
  state = {
    data: [],
    fetchDone: false,
    showHelpModal: false,
    showAddModal: false,
    showDeleteMessage: false,
    showEditModal: false,
    showEditFirstModal: false,
    showEditTwoModal: false,
    showEditThreeModal: false,
    showExplanationModal: false,
    showVersions: true,
    FeaturesList: [],
    pointList: [],
    customerSegList: [],
    competitorName: "",
    selectingCompetitor: null,
    selectingFeature: null,
    selected: this.props.primaryCompetitor,
    newServesTheBenefit: "",
    newFeatureName: "",
    newShortDescription: "",
    showDeleteFeature: false,
    newPercivedValue: "",
    newUSP: "",
    newVersionOne: "",
    newVersionTwo: "",
    newVersionThree: "",
  };


  renderFeatures = (feature, index) => {
    return (
      <tr key={feature.featureId}>
        <td>{feature.servesTheBenefit}</td>
        <td>
          {feature.featureName}
        </td>
        <td>{feature.shortDescription}</td>
        <td>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onEditFrist(feature)}
          >
            <Edit />
          </Button>
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => this.onFeatureDelete(feature)}
          >
            <DeleteOutline />
          </Button>
        </td>
      </tr>
    );
  };
 
 

  componentWillReceiveProps(nextProps) {
    const data = nextProps.competitors.map((elem) => {
      return { ...elem.competitorInfo, competitorId: elem.id };
    });

    const features = nextProps.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
       });
// console.log("HELLLO");
// console.log(nextProps.featureData);
// console.log(nextProps.customerData);
const dataCustomer = nextProps.customerData.map(elem => {
    return { ...elem.customerData, timestamp: elem.timestamp };
  });

  const points = nextProps.featureData.map((elem) => {
    return {  x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp) };
  });
  // console.log("Points::::::::::::");
  // console.log(points);
  
  // console.log(dataCustomer);
  var customersHolder;
  // the below logic gets the current customr data;
  if (dataCustomer.length !== 0) {
    customersHolder = dataCustomer[0];
  }
  // console.log("Holder:");
  // console.log(customersHolder);
  var currentIndex = -1;
  for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
  //  console.log(index + " " + dataCustomer[index]);
    if (customersHolder.timestamp < dataCustomer[index].timestamp) {
      customersHolder = dataCustomer[index];
      currentIndex = index;
    }
  }
  //  console.log("customerHolder: ");
  //  console.log(customersHolder);
  var currentCustomers = [];
  if(currentIndex !== -1){
   currentCustomers.push(dataCustomer[currentIndex].primaryTargetCustomer);
   currentCustomers.push(dataCustomer[currentIndex].secondaryTargetCustomer);
  }
    this.setState({
      //customerSegList: currentCustomers,
      FeaturesList: features,
      pointList: points,
      data: data,
      fetchDone: true,
      selected: nextProps.primaryCompetitor,
      customerSegList: currentCustomers,
    });
  }

   async componentDidMount() {

   await this.props.onFetchCompetitor(this.props.token, this.props.userId);
    
   await this.props.onFetchFeature(this.props.token, this.props.userId);
    
  await this.props.onFetchCustomer(this.props.token, this.props.userId);
    // fetchs all data needed from database

    // await this.props.onFetchFeature(this.props.token, this.props.userId);
    // var data = this.props.featureData.map(elem => {
    //   return { ...elem.featureData, featureid: elem.id };
    // });

    // await this.props.onFetchCustomer(this.props.token, this.props.userId);
    // const dataCustomer = this.props.customerData.map(elem => {
    //   return { ...elem.customerData, timestamp: elem.timestamp };
    // });
    

    // var customersHolder;
    // // the below logic gets the current customr data;
    // if (dataCustomer.length !== 0) {
    //   customersHolder = dataCustomer[0];
    // }
    // // console.log("Holder:");
    // // console.log(customersHolder);
    // for (var index = 0; dataCustomer.length !== index && dataCustomer.length > 0; index++) {
    //   console.log(index + " " + dataCustomer[index]);
    //   if (customersHolder.timestamp < dataCustomer[index].timestamp) {
    //     customersHolder = dataCustomer[index];
    //   }
    // }
    // // console.log("customerHolder: ");
    // // console.log(customersHolder);
    // var currentCustomers = [];

    // currentCustomers.push(customersHolder.primaryTargetCustomer);
    // currentCustomers.push(customersHolder.secondaryTargetCustomer);

    // this.setState({
    //   ...this.state,
    //   customerSegList: currentCustomers,
    //   //FeaturesList: data,
    // });
    // //console.log(this.state.data);
  }


  handleClose = () => {
    this.setState({
      showAddModal: false,
      showAddFeatureModal: false,
      showDeleteMessage: false,
      showEditModal: false,
      showHelpModal:false,
      showExplanationModal: false,
      showEditFirstModal: false,
      showEditTwoModal: false,
      showEditThreeModal: false,
      showDeleteFeature: false,
    });
  };

  handleOptionChange = async (e) => {
    //console.log(e.target.value);
    await this.props.updatePrimaryCompetitor(e.target.value);
    //console.log(this.props);
    this.setState({ selected: this.props.primaryCompetitor });
  };

  onAdd = () => {
    this.setState({ showAddModal: true });
  };
  onAddFeature = () => {
    this.setState({ showAddFeatureModal: true });
  };

  openHelp = () => {
      this.setState({...this.state, showHelpModal: true});
  }

  onExplanation = () => {
    this.setState({...this.state, showExplanationModal: true});    
  }

 
  onEditFrist = (feature) => {
    this.setState({ showEditFirstModal: true, selectingFeature: feature });
  }
  onEditSecond = (feature) => {
    this.setState({ showEditTwoModal: true, selectingFeature: feature });
  }
  onEditThree = (feature) => {
    this.setState({ showEditThreeModal: true, selectingFeature: feature });
  }

  
  onFeatureDelete= (feature) => {
    this.setState({ showDeleteFeature: true, selectingFeature: feature });
  };

  handleDeleteFeature = async (id) => {
    //console.log("Delete id ", id);
    await this.props.onDeleteFeature(id, this.props.token);
    //console.log(this.props);
    //console.log(this.state.FeaturesList);
    const dataDelete = this.state.FeaturesList.filter(
      (feature) => feature.featureId !== id
    );
    this.setState({
      FeaturesList: [...dataDelete],
      showDeleteFeature: false,
      selectingCompetitor: null,
    });
  };

  handleVersionChange = (e) =>{
    //console.log(e.target);
    this.setState({ ...this.state, showVersions: !this.state.showVersions });
  }

  handleInputChange = (e) => {
    //e.preventDefault();
    //console.log(e.target.name);
    this.setState({ [e.target.name]: e.target.value });
  };

  handleServesChange = (e) => {
    //e.preventDefault();
    //console.log(e.value);
    this.setState({ newServesTheBenefit: e.value });
  };
  handleSubmitData = async (e) => {
    e.preventDefault();
    const { competitorName } = this.state;
    const newData = {
      competitorInfo: { competitorName },
      userId: this.props.userId,
    };
    await this.props.onSubmitCompetitor(newData, this.props.token);
    this.setState({
      data: this.props.competitors.map((elem) => {
        return { ...elem.competitorInfo, competitorId: elem.id };
      }),
      showAddModal: false,
    });
  };

  handleSubmitFeature = async (e) => {
    e.preventDefault();
    //const { competitorName } = this.state;
    //console.log(this.state);
    const newData = {
      featureData: { 
          servesTheBenefit: this.state.newServesTheBenefit,
          featureName: this.state.newFeatureName,
          shortDescription: this.state.newShortDescription,
          percivedValue: 0,
          usp: 0,
          versionOne: "",
          versionTwo: "",
          versionThree: "",
    },
      userId: this.props.userId,
    };
    await this.props.onSubmitFeature(newData, this.props.token);
    this.setState({
      FeaturesList: this.props.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
      }),
      pointList: this.props.featureData.map((elem) => {
        return {  x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp) };
      }),
      newFeatureName: "",
      newServesTheBenefit: "",
      newShortDescription: "",
      showAddFeatureModal: false,
    });
  };

  

  handleEditFirst = async (oldData) =>{
   // console.log(oldData);
    const featureId = oldData.featureId;
    delete oldData.featureId;
    //console.log(featureId);
    var featureN;
    if (this.state.newFeatureName === "") {
      featureN = oldData.featureName;
    } else {
      featureN = this.state.newFeatureName;
    }
    var servesTheBene;
    if (this.state.newServesTheBenefit === "") {
      servesTheBene = oldData.servesTheBenefit;
    } else {
      servesTheBene = this.state.newServesTheBenefit;
    }
    var short;
    if (this.state.newShortDescription === "") {
      short = oldData.shortDescription;
    } else {
      short = this.state.newShortDescription;
    }

    var newVal;
    if (this.state.newPercivedValue === "") {
      newVal = oldData.percivedValue;
    } else {
      newVal = this.state.newPercivedValue;
    }
    var newU;
    if (this.state.newUSP === "") {
      newU = oldData.usp;
    } else {
      newU = this.state.newUSP;
    }
    var newVOne;
    if (this.state.newVersionOne === "") {
      newVOne = oldData.versionOne;
    } else {
      newVOne = this.state.newVersionOne;
    }
    var newVTwo;
    if (this.state.newVersionTwo === "") {
      newVTwo = oldData.versionTwo;
    } else {
      newVTwo = this.state.newVersionTwo;
    }
    var newVThree;
    if (this.state.newVersionThree === "") {
      newVThree = oldData.versionThree;
    } else {
      newVThree = this.state.newVersionThree;
    }
    const newData = {
      ...oldData,
      servesTheBenefit: servesTheBene,
      featureName: featureN,
      shortDescription: short,
      percivedValue: newVal,
      usp: newU,
      versionOne: newVOne,
      versionTwo: newVTwo,
      versionThree: newVThree,
    }
    const toServer = {
      featureData: {...newData},
      userId: this.props.userId,
    }
    await this.props.onUpdateFeature(
      featureId,
      toServer,
      this.props.token
    );
    //console.log("FJFJFJ");
    //console.log(this.props.featureData);
    this.setState({
      FeaturesList: this.props.featureData.map((elem) => {
        return { ...elem.featureData, featureId: elem.id };
      }),
      pointList: this.props.featureData.map((elem) => {
        return { x: parseInt(elem.featureData.percivedValue) , y: parseInt(elem.featureData.usp)  };
      }),
      showEditFirstModal: false,
      showEditTwoModal: false,
      showEditThreeModal: false,
      newFeatureName: "",
      newServesTheBenefit: "",
      newShortDescription: "",
      newUSP: "",
      newVersionOne: "",
      newVersionTwo: "",
      newVersionThree: "",
      newPercivedValue: "",
      selectingCompetitor: null,
      selectingFeature: null,
    });

  }

  
  render() {
    //console.log(this.state);

  //   var pointList = [];
  //     // this.state.FeatureList.forEach(function(element) {
  //     //     selectList.push({ x:element.percivedValue, y: element.usp })
  //     // });
  //   if(this.state.FeaturesList !== []){
  //   for(var index = 0; this.state.FeaturesList !== index && this.state.fetchDone === true; index++){
  //     pointList.push({ x: this.state.FeaturesList[index].percivedValue, y: this.state.FeaturesList[index].usp })
  //   }
  // }
    //console.log(this.state.pointList);

    const dataC = {
        labels: ['Scatter'],
        datasets: [
          {
            label: 'My First dataset',
            fill: false,
            //showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 5,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 5,
            pointHitRadius: 10,
            data: this.state.pointList
          },
          {
            label: 'Vertical Line',
            fill: false,
            showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            data: [{x: 3, y: 0}, {x: 3, y: 5}]
          },
          {
            label: 'Horizontal Line',
            fill: false,
            showLine: true,  //!\\ Add this line
            backgroundColor: 'rgba(75,192,192,0.4)',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 0,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 0,
            data: [{x: 0, y: 3}, {x: 5, y: 3}]
          }
        ]
      };

       const chartOptions =   {
        lineAt: 3,
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 1,
                  max: 5,
                  stepSize: 1,
                  lineAt: 3,
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.06)",
                zeroLineColor: "rgba(0,0,0,0.1)",
                offsetGridLines: false
              }
            }],
            xAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 1,
                  max: 5,
                  stepSize: 1,
                  line: 3
              },
              gridLines: {
                color: "rgba(0, 0, 0, 0.06)",
                zeroLineColor: "rgba(0,0,0,0.1)",
                offsetGridLines: false
              },
              drawHorizontalLine: {
                lineY: [3, 3],
                lineColor: "rgba(50, 155, 255, 0.85)",
                text: 'Obj 67 & 68',
                textPosition: 720,
                textFont: '18px sans-serif',
                textColor: "rgba(50, 155, 255, 0.85)"
              },
            }],
            // drawHorizontalLine: {
            //   lineY: [3, 3],
            //   lineColor: "rgba(50, 155, 255, 0.85)",
            //   text: 'Obj 67 & 68',
            //   textPosition: 720,
            //   textFont: '18px sans-serif',
            //   textColor: "rgba(50, 155, 255, 0.85)"
            // },
            annotation: {
              annotations: [{
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-0',
                value: 3,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 4,
                label: {
                  enabled: false,
                  content: 'Test label'
                }
              }]}
         }
        //  annotation: {
        //   annotations: [{
        //     type: 'line',
        //     mode: 'horizontal',
        //     scaleID: 'y-axis-0',
        //     value: 3,
        //     borderColor: 'rgb(75, 192, 192)',
        //     borderWidth: 4,
        //     label: {
        //       enabled: false,
        //       content: 'Test label'
        //     }
        //   }]
        // },
        // drawHorizontalLine: {
        //   lineY: [3, 3],
        //   lineColor: "rgba(50, 155, 255, 0.85)",
        //   text: 'Obj 67 & 68',
        //   textPosition: 720,
        //   textFont: '18px sans-serif',
        //   textColor: "rgba(50, 155, 255, 0.85)"
        // }
      
  };


      var selectList = [];
      this.state.customerSegList.forEach(function(element) {
          selectList.push({ label:element, value: element })
      });

      if (!this.state.fetchDone) {
        return (
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        );
      } else {
      return (
        <React.Fragment>
        <Container>
              <Container2>
                  
            <div>
          <h1>Features</h1>
          </div>

       {/* <Container2> */}

       <Row>
           <h3>
           Now that you've defined the Benefits that you will offer, what 
           Features have you built, or do you need to build, to offer them? 
           </h3>
           <Col>
           <Tooltip title="Click for help" arrow>
  {/* <span> */}
    <Button onClick={this.openHelp}>Features??</Button>
  {/* </span> */}
</Tooltip>
           </Col>
       </Row>

       <hr/>
       
       <Row>
          {/* <Col xs>
          <ScatterChart chartData={dataC}/>
          </Col> */}
         

          <Col xs>
          <Table striped bordered hover size="sm" >
            <thead>
              <tr>
                <th>Serves the benefit</th>
                <th>Feature name(Unique)</th>
                <th>Short Description(Optional)</th>
                <th id="action">Action</th>
              </tr>
            </thead>
            <tbody>{this.state.FeaturesList.map(this.renderFeatures)}</tbody>
          </Table>
          <Button variant="primary" onClick={this.onAddFeature} >
            Add Feature
          </Button>
          </Col>
         
         
          </Row>
        
       
         
          

{this.state.showAddFeatureModal ? (
            <Modal
              show={this.state.showAddFeatureModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>What benefit does it serve: </label>
                  <Select
                    //type="text"
                    name="newServesTheBenefit"
                    options={selectList}
                    onChange={this.handleServesChange}
                  />
                  <hr/>
                <label>Add Feature Name: </label>
                  <input
                    type="text"
                    id="feature"
                    placeholder="Enter your secondary competitor"
                    name="newFeatureName"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                    <label>Add Short Description: </label>
                  <input
                    type="text"
                    id="description"
                    placeholder=""
                    name="newShortDescription"
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleSubmitFeature}>
                  Add
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

          {this.state.showEditModal ? (
            <Modal
              show={this.state.showEditModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Competitor</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <label>Edit competitor name: </label>
                  <input
                    type="text"
                    placeholder="Enter your secondary competitor"
                    name="competitorName"
                    onChange={this.handleInputChange}
                    defaultValue={this.state.selectingCompetitor.competitorName}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditData(this.state.selectingCompetitor)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}



{this.state.showEditFirstModal ? (
            <Modal
              show={this.state.showEditFirstModal}
              onHide={this.handleClose}
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Feature</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                <label>What benefit does it serve: </label>
                  <Select
                    //type="text"
                    name="newServesTheBenefit"
                    options={selectList}
                    onChange={this.handleServesChange}
                    placeholder={this.state.selectingFeature.servesTheBenefit}
                  />
                  <hr/>
                <label>Edit Feature Name: </label>
                  <input
                    type="text"
                    id="feature"
                    placeholder={this.state.selectingFeature.featureName}
                    name="newFeatureName"
                    onChange={this.handleInputChange}
                  />
                <hr/>
                    <label>Add Short Description: </label>
                  <input
                    type="text"
                    id="description"
                    placeholder={this.state.selectingFeature.shortDescription}
                    name="newShortDescription"
                    onChange={this.handleInputChange}
                  />
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    this.handleEditFirst(this.state.selectingFeature)
                  }
                >
                  Edit
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}


{this.state.showDeleteFeature ? (
            <Modal
              show={this.state.showDeleteFeature}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning!!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this feature?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button
                  variant="danger"
                  onClick={() =>
                    this.handleDeleteFeature(
                      this.state.selectingFeature.featureId
                    )
                  }
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          ) : null}

            {this.state.showHelpModal ? (
            <Modal
              show={this.state.showHelpModal}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              Features is the actual "What" a Product or Service does. 
              A Product or Service is the sum of the Features it contains.

              It is best to have a relatively detailed Feature list as it 
              is the Features will be the components of any differentiated 
              Pricing and Packaging offers to different Customers, Channels, etc.
              For example, a simple registeration form serves a benefit. The feature responsible for 
              this could be called the Parent Portal, A short description  could be a one click sign 
              in for parents.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          ) : null}
          {this.state.showExplanationModal ? (
            <Modal
              show={this.state.showExplanationModal}
              onHide={this.handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              This exercise results in 3 types
              of Feature types:
              {<hr/>}
              Leaders are key to land the sale; they open a customer's wallet, 
              and aim to catch a large proportion of a customer's WTP. They are the Burger 
              in a fast-food restaurant.
              {<hr/>}
              Fillers, Customers do not buy the product / service or package for it. 
              But they will allow you to increase it's price, capture extra WTP, as 
              long as it includes a Leader too. They are the Fries.
              {<hr/>}
              Killers kill the product/service for the user. Think of restaurant with bad food, 
              so you avoid them from now on.
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                
              </Modal.Footer>
            </Modal>
          ) : null}

           </Container2>
        </Container>
        </React.Fragment>
      );
          }
    }
}


const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    isAuthenticated: state.auth.token !== null,
    competitors: state.competitor.competitors,
    primaryCompetitor: state.competitor.primaryCompetitor,
    featureData: state.features.featureList,
    customerData: state.customerNeeds.customerData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCustomer: (token, userId) => dispatch(actions.fetchCustomer(token, userId)),
    onFetchCompetitor: (data, token) => {
      dispatch(actions.fetchCompetitor(data, token));
    },
    onSubmitCompetitor: (data, token) => {
      dispatch(actions.submitCompetitorInfo(data, token));
    },
    onDeleteCompetitor: (competitorId, token) => {
      dispatch(actions.deleteCompetitor(competitorId, token));
    },
    onUpdateCompetitor: (competitorId, data, token) =>
      dispatch(actions.updateCompetitor(competitorId, data, token)),
    updatePrimaryCompetitor: (primaryCompetitorId) => {
      dispatch(actions.updatePrimaryCompetitor(primaryCompetitorId));
    },
    onFetchFeature: (data, token) => {
        dispatch(actions.fetchFeature(data, token));
      },
      onSubmitFeature: (data, token) => {
        dispatch(actions.submitFeatureInfo(data, token));
      },
      onDeleteFeature: (featureId, token) => {
        dispatch(actions.deleteFeature(featureId, token));
      },
      onUpdateFeature: (featureId, data, token) =>
        dispatch(actions.updateFeature(featureId, data, token)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(features)
);
