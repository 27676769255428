
import * as actionTypes from '../actions/actionTypes';
import axios from '../../axios-instance';


// segmentedCustomers: "",
//         primaryTargetCustomer: "",
//         customerPersona: "",
//         descriptionOfCustomer: "",
//         secondaryTargetCustomer: "",

export const ADD_CUSTOMER_INFOMATION = (segmentedCustomers, primaryTargetCustomer, customerPersona, 
                                        descriptionOfCustomer, secondaryTargetCustomer ) => {

    return{
        type: 'ADD_CUSTOMER_INFORMATION',
		segmentedCustomers: segmentedCustomers,
        primaryTargetCustomer: primaryTargetCustomer,
        customerPersona: customerPersona,
		descriptionOfCustomer: descriptionOfCustomer,
        secondaryTargetCustomer: secondaryTargetCustomer,
    };
};
   


export const submitCustomer = (newData, token) => {
	return async (dispatch) => {
		await axios.post('/customerValue.json?auth=' + token, newData)
			.then(response => {
				dispatch(submitCustomerSuccess(response.data.name, newData))
			})
			.catch(error => {
				dispatch(submitCustomerFail(error))
			})
	};
};

export const submitCustomerSuccess = (id, customerData) => {
	return {
		type: actionTypes.SUBMIT_DATA_SUCCESS,
		customerId: id,
		customerData: customerData
	};
};

export const submitCustomerFail = (error) => {
	return {
		type: actionTypes.SUBMIT_DATA_FAIL,
		error: error
	}
}