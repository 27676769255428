import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { fetchUnmetNeedSuccess } from "../actions";

const initialState = {
  unmetData: [], // hold all unmet data
  finished: false,
};


// submit unmet successs
const submitUnmetSuccess = (state, action) => {
  const newData = updateObject(action.unmetData, { id: action.unmetId });
  return updateObject(state, {
    finished: true,
    unmetData: state.unmetData.concat(newData), // append new unmet data to unmetData array
  });
};


// submit unmet fail
const submitUnmetFail = (state, action) => {
  console.log(action.error);
};

// fetch unmet fail
const fetchUnmetSuccess = (state, action) => {
    const newData = [...action.unmetData];
    return updateObject(state, {
      finished: true,
      unmetData: newData,
    });
  };

  
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBMIT_UNMET_SUCCESS:
      return submitUnmetSuccess(state, action);
    case actionTypes.SUBMIT_UNMET_FAIL:
      return submitUnmetFail(state, action);
    case actionTypes.FETCH_UNMETNEED_SUCCESS:
        return fetchUnmetSuccess(state, action)
    default:
      return state;
  }
};

export default reducer;
