import React, { Component } from "react";
import MarketInfo from "./MarketInfo";

class Market extends Component {
  render() {
    return <MarketInfo />;
  }
}

export default Market;
