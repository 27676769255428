import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  competitors: [], // list of all competitors
  finished: false,
  primaryId: '',
  primaryCompetitor: '', // hold id of primary competitor
};

// ***********************************************
// Competitors
// CRUD operators for competitors
// ***********************************************

// start submiting competitor
const startSubmitCompetitor = (state, action) => {
  return updateObject(state, {
    finished: false,
  });
};

// submit competitor success
const submitCompetitorSuccess = (state, action) => {
  const newData = updateObject(action.competitors, {
    id: action.competitorId,
  });
  const test = updateObject(state, {
    finished: true,
    competitors: state.competitors.concat(newData),
  });
  return test;
};

// submit competitor fail
const submitCompetitorFail = (state, action) => {
  console.log(action.error);
  return state;
};

// fetch competitor success
const fetchCompetitorSuccess = (state, action) => {
  const newData = [...action.competitors];
  return updateObject(state, {
    finished: true,
    competitors: newData,
  });
};

// delete competitor success
const deleteCompetitorSuccess = (state, action) => {
  let index = -1;
  console.log(state);
  const data = [...state.competitors];
  console.log(data);
  for (let elem of data) {
    if (elem.id === action.competitorId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data.splice(index, 1);
  }
  // console.log(data);

  return updateObject(state, {
    finished: true,
    competitors: data,
  });
};

// delete competitor fail
const deleteCompetitorFail = (state, action) => {
  console.log(action.error);
  return state;
};

// update competitor success
const updateCompetitorSuccess = (state, action) => {
  let index = -1;
  const data = [...state.competitors];
  const newData = updateObject(action.competitors, { id: action.competitorId });
  for (let elem of data) {
    if (elem.id === action.competitorId) {
      index = data.indexOf(elem);
      break;
    }
  }
  if (index !== -1) {
    data[index] = newData;
  }
  return updateObject(state, {
    finished: true,
    competitors: data,
  });
};

// update competitor success
const updateCompetitorFail = (state, action) => {
  console.log(action.error);
  return state;
};

// ***********************************************
// Primary competitor
// CRUD operators for primary competitor
// ***********************************************
// const updatePrimaryCompetitor = (state, action) => {
//   console.log(action);
//   return updateObject(state, {
//     finished: true,
//     primaryCompetitor: action.newPrimaryCompetitorId,
//   });
// };

// updating primary competitor success
const updatePrimaryCompetitorSuccess = (state, action) => {
  return updateObject(state, {
    finished: true,
    primaryId: action.primaryId,
    primaryCompetitor: action.competitorData.competitorId,
  });
};

// updating primary competitor fail
const updatePrimaryCompetitorFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// submit primary competitor fail
const submitPrimaryCompetitorFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

// submit primary competitor success
const submitPrimaryCompetitorSuccess = (state, action) => {
  console.log(action);
  return updateObject(state, {
    finished: true,
    primaryCompetitor: action.competitorData.competitorId,
    primaryId: action.primaryId,
  });
};

// fetch primary competitor success
const fetchPrimaryCompetitorSuccess = (state, action) => {
  return updateObject(state, {
    primaryCompetitor: action.competitorData.competitorId,
    primaryId: action.primaryId,
    primaryCompetitorData: { ...action.competitorData.competitorInfo },
    finished: true,
  });
};

// fetch primary competitor fail
const fetchPrimaryCompetitorFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    finished: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_SUBMIT_COMPETITOR:
      return startSubmitCompetitor(state, action);
    case actionTypes.SUBMIT_COMPETITOR_SUCCESS:
      return submitCompetitorSuccess(state, action);
    case actionTypes.SUBMIT_COMPETITOR_FAIL:
      return submitCompetitorFail(state, action);
    case actionTypes.FETCH_COMPETITOR_SUCCESS:
      return fetchCompetitorSuccess(state, action);
    case actionTypes.DELETE_COMPETITOR_SUCCESS:
      return deleteCompetitorSuccess(state, action);
    case actionTypes.DELETE_COMPETITOR_FAIL:
      return deleteCompetitorFail(state, action);
    case actionTypes.UPDATE_COMPETITOR_SUCCESS:
      return updateCompetitorSuccess(state, action);
    case actionTypes.UPDATE_COMPETITOR_FAIL:
      return updateCompetitorFail(state, action);
    // case actionTypes.UPDATE_PRIMARY_COMPETITOR:
    //   return updatePrimaryCompetitor(state, action);
    case actionTypes.UPDATE_PRIMARY_COMPETITOR_SUCCESS:
      return updatePrimaryCompetitorSuccess(state, action);
    case actionTypes.UPDATE_PRIMARY_COMPETITOR_FAIL:
      return updatePrimaryCompetitorFail(state, action);
    case actionTypes.FETCH_PRIMARY_COMPETITOR_SUCCESS:
      return fetchPrimaryCompetitorSuccess(state, action);
    case actionTypes.FETCH_PRIMARY_COMPETITOR_FAIL:
      return fetchPrimaryCompetitorFail(state, action);
    case actionTypes.SUBMIT_PRIMARY_COMPETITOR_SUCCESS:
      return submitPrimaryCompetitorSuccess(state, action);
    case actionTypes.SUBMIT_PRIMARY_COMPETITOR_FAIL:
      return submitPrimaryCompetitorFail(state, action);
    default:
      return state;
  }
};

export default reducer;
