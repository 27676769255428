import React from 'react';
import { withRouter } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function IntroductionModal(props) {
	const { to, staticContext, ...rest } = props;

	return (
		<Modal
			{...rest}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			centered>
			<Modal.Header closeButton>
				<Modal.Title id='contained-modal-title-vcenter'>
					Cost Module
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				There are 2 main types of costs. F
				ixed costs, which stay mostly stable when volume sold increases and 
				Variable costs, which will usually change when volume increases
			</Modal.Body>
			<Modal.Footer>
				<Button variant='danger' onClick={() => props.history.push('/')}>
					I don't know
				</Button>
				<Button onClick={props.onHide}>Continue</Button>
			</Modal.Footer>
		</Modal>
	);
}
export default withRouter(IntroductionModal);
